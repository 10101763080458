import { Skeleton } from "@mui/material";
import React from "react";

const LoadingSkeletonBlog = () => {
  return (
    <div style={{ width: "100%" }}>
      <Skeleton sx={{ bgcolor: "grey.400" }} height={60} width={"100%"} />
    </div>
  );
};

export const LoadingSkeletonBlog1 = () => {
  return (
    <div style={{ width: "100%" }}>
      <Skeleton
        variant="rounded"
        sx={{ bgcolor: "grey.400", marginBottom: 1 }}
        height={40}
        width={"500px"}
      />
    </div>
  );
};

export const LoadingSkeletonBlogContent = () => {
  return (
    <div style={{ width: "100%" }}>
      <Skeleton
        variant="rounded"
        sx={{ bgcolor: "grey.400" }}
        height={800}
        width={"100%"}
      />
    </div>
  );
};

export default LoadingSkeletonBlog;

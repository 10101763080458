import React, { useEffect, useState } from "react";
import Banner4 from "../../../assets/tour/banner4.png";
import { Grid, Pagination } from "@mui/material";
import PopularTour from "../../PopularTour/PopularTour";
import { apiGetBlogType } from "../../../api";
import ItemTravelGuide from "../../TravelGuide/ItemTravelGuide";
import DealHot from "../../DealHot/DealHot";
import LoadingGuideLine from "../../Loading/LoadingGuideLine";
import { Image, Swiper } from "react-nivo-slider";
import B1Image from "./image1/b1.png";
import { setting2 } from "../home/Banner";
import { Helmet } from "react-helmet";

const Tour = (props) => {
  const [type, setType] = useState(1);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const itemsPerPage = 7;

  useEffect(() => {
    (async () => {
      try {
        setLoading(true);
        const result = await apiGetBlogType({ type });
        setLoading(false);
        if (result?.ok === true) {
          setData(result?.data?.reverse());
          setTotalPages(Math.ceil(result?.data.length / itemsPerPage));
        }
      } catch (error) {
        setLoading(false);
        console.log(error);
      } finally {
        setLoading(false);
      }
    })();
  }, [type]);

  const handleChangePage = (event, value) => {
    setPage(value);
  };

  const startIndex = (page - 1) * itemsPerPage;
  const paginatedData = data.slice(startIndex, startIndex + itemsPerPage);

  return (
    <div style={{ width: "100%" }}>
      <Helmet>
        <title>{props?.title}</title>
        <link rel="canonical" href={window.location.origin + window.location.pathname} />
        <meta
          data-react-helmet="true"
          property="og:url"
          content={window.location.origin + window.location.pathname}
        ></meta>
      </Helmet>
      <div
        className="banner-hotel"
        style={{ width: "100%", position: "relative" }}
      >
        <Swiper {...setting2}>
          <Image src={B1Image} alt="image1" />
        </Swiper>
        <div
          style={{
            position: "absolute",
            left: "50%",
            top: "48%",
            transform: "translate(-50%, -50%)",
          }}
        >
          {/* <p
            style={{
              fontSize: 64,
              fontWeight: 700,
              color: "#fff",
              textTransform: "uppercase",
            }}
            className="m-fz-32 tx-ct"
          >
            Cẩm nang du lịch
          </p> */}
        </div>
      </div>
      <div
        style={{ width: "100%", marginTop: 40 }}
        className="c-flex-center m-mt-0"
      >
        <div
          style={{
            width: "100%",
            maxWidth: 1248,
            display: "flex",
            marginBottom: 40,
          }}
          className="m-flex-column p-10"
        >
          <div
            style={{
              flex: 1,
              paddingRight: 20,
              maxWidth: "calc(100% - 300px)",
            }}
            className="m-w-100 m-p-0"
          >
            {loading === false && (
              <div style={{ width: "100%" }}>
                <Grid container spacing={2} mb={3}>
                  {paginatedData?.slice(0, 1)?.map((item, key) => (
                    <Grid key={key} item xs={12} mb={3}>
                      <ItemTravelGuide fs={24} h={40} ratio={2.25} {...item} />
                    </Grid>
                  ))}
                  {paginatedData?.slice(1)?.map((item, key) => (
                    <Grid key={key} item xs={6} sm={4}>
                      <ItemTravelGuide fs={18} h={30} ratio={4 / 3} {...item} />
                    </Grid>
                  ))}
                </Grid>
                {loading === false && data?.length <= 0 && (
                  <Grid item xs={12} mt={2}>
                    <div style={{ textAlign: "center", fontSize: 20 }}>
                      Không tìm thấy kết quả trùng khớp, Bạn hãy thử lại
                    </div>
                  </Grid>
                )}
              </div>
            )}
            {loading === true && <LoadingGuideLine />}
            <Pagination
              count={totalPages}
              page={page}
              onChange={handleChangePage}
              style={{ marginTop: 20 }}
              color="primary"
              sx={{
                "& .MuiPaginationItem-root": {
                  color: "#e27b07",
                },
                "& .MuiPaginationItem-root.Mui-selected": {
                  backgroundColor: "#e27b07",
                  color: "#fff",
                },
              }}
            />
          </div>
          <div className="wrap-dh-blog" style={{ width: 300, paddingLeft: 20 }}>
            <DealHot />
          </div>
        </div>
      </div>
      {/*  */}
      <div style={{ marginBottom: 60 }}></div>
      <PopularTour />
    </div>
  );
};

export default Tour;

import React, { useState } from "react";
import Logo from "./Logo";
import Navigation from "./Navigation";
import { IoMdMenu } from "react-icons/io";
import { Drawer } from "@mui/material";

const Header = () => {
  const [open, setOpen] = useState(false);
  const toggleDrawer = (newOpen) => () => {
    setOpen(newOpen);
  };
  const closeDrawer = () => {
    console.log(1)
    setOpen(false);
  };

  return (
    <>
      <header className="header">
        <nav className="main-header">
          <Logo />
          <div className="wrap-navigation-1">
            <Navigation />
          </div>
          <div className="menu-mobile" style={{display: "none", padding: 10}}>
            <IoMdMenu size={24} color={"#e27b07"} onClick={toggleDrawer(true)} />
          </div>
          <Drawer open={open} onClose={toggleDrawer(false)}>
            <Navigation onClose={closeDrawer} />
          </Drawer>
        </nav>
      </header>
      <div className="header-placeholder" style={{ height: 110 }}></div>
    </>
  );
};

export default Header;

import React, { useEffect, useState } from "react";
// import SelectBox from "./SelectBox";
// import { Button } from "@mui/material";
import DatePickerValue from "../../util/DatePicker/DatePicker";
import { apiGetCategory, apiGetProvince, apiGetWard, apiSearchResult, getListProvince } from "../../../api";
import SelectBox2 from "../../util/SelectBox/SelectBox";
import { listBudget, listEstate, listGuest, listRoom } from "../../../data/data";
import { createSearchParams, useNavigate } from "react-router-dom";
import MediaQuery from "react-responsive";

const BookingMenuMobile = () => {
  const navigate= useNavigate()
  const [typeBooking, setTypeBooking]= useState(1)
  const [listProvince, setListProvince]= useState([])
  const [listDistrict, setListDictrict]= useState([])
  const [listWard, setListWard]= useState([])
  const [province, setProvince]= useState(48)
  const [district, setDistrict]= useState("")
  const [ward, setWard]= useState("")
  const [realEstateType, setRealEstateType]= useState("")
  const [departurePoint, setDeparturePoint]= useState()
  const [destinationPoint, setDestinationPoint]= useState()
  const [departureDay, setDepartureDay]= useState()
  const [destinationDay, setDestinationDay]= useState()
  const [budget, setBudget]= useState()
  const [passenger, setPassenger]= useState()
  const [seat, setSeat]= useState()
  const [isDisabledButton, setDisabledButton] = useState(false);
  const [listRealEstate, setListRealEstate]= useState([])
  const [listPassenger, setListPassenger]= useState([
    {label: "1 người", value: 1},
    {label: "2 người", value: 2},
    {label: "3 người", value: 3},
    {label: "4 người", value: 4},
    {label: "5 người", value: 5},
    {label: "5+ người", value: 6},
  ])
  const handleClick = async () => {
    const data= {
      typeBooking: typeBooking ? typeBooking : "",
      province: province ? province : "",
      district: district ? district : "",
      ward: ward ? ward : "",
      departureDay: departureDay ? departureDay : "",
      departurePoint: departurePoint ? departurePoint : "",
      destinationDay: destinationDay ? destinationDay : "",
      destinationPoint: destinationPoint ? destinationPoint : "",
      budget: budget ? budget : "",
      passenger: passenger ? passenger : "",
      seat: seat ? seat : "",
      realEstateType: realEstateType ? realEstateType : ""
    } 
    navigate({pathname: "/search", search: `?${createSearchParams(data)}`})
    // const result= await apiSearchResult(data)
    // console.log(result)
    // Xử lý logic khi button được click
    console.log("Button clicked");
  };

  const getdistrict = async (code) => {
    const response = await apiGetProvince(code);
    setListDictrict(response?.results);
  };

  const getlistward= async (code)=> {
    const response1= await apiGetWard(code)
    setListWard(response1?.results)
  }

  useEffect(() => {
    if (province) getdistrict(province)
    if(district) getlistward(district)
  }, [province, district]);

  useEffect(()=> {
    (async ()=> {
      const result= await apiGetCategory({categoryId: 13})
      // console.log(result)
      setListRealEstate(result?.data?.map(item=> ({label: item?.sub_name, value: item?.id})))
    })()
  }, [])
  
  useEffect(()=> {
    (async ()=> {
      const result= await getListProvince()
      setListProvince(result)
    })()
  }, [])


  return (
    <div className="wrap-booking-menu" style={{position: "static"}}>
      <div className="container-booking-menu">
        <div>
          <div className="wrap-top-container-booking-menu" style={{cursor: "pointer", borderRadius: 0, position: "static"}}>
            <div className={`ele-booking-menu-mb ${typeBooking=== 1 && "ele-booking-menu-active"}`} onClick={()=> {
              setTypeBooking(1)
            }}>
              <p className="text-trad fz-10">Cho thuê căn hộ</p>
            </div>
            <div className={`ele-booking-menu-mb ${typeBooking=== 2 && "ele-booking-menu-active"}`} onClick={()=> {
              setTypeBooking(2)
            }}>
              <p className="text-trad fz-10">Khách sạn</p>
            </div>
            <div className={`ele-booking-menu-mb ${typeBooking=== 3 && "ele-booking-menu-active"}`} onClick={()=> {
              setTypeBooking(3)
            }}>
              <p className="text-trad fz-10">Tour du lịch</p>
            </div>
            <div className={`ele-booking-menu-mb ${typeBooking=== 4 && "ele-booking-menu-active"}`} onClick={()=> {
              setTypeBooking(4)
            }}>
              <p className="text-trad fz-10">Vé máy bay</p>
            </div>
          </div>
        </div>
        <div className="bottom-container-booking-menu" style={{position: "static", padding: 10}}>
          <div className="ele-bottom-container-booking-menu bottom-departure-point-booking">
            {
                typeBooking=== 1 && 
                <>
                  <SelectBox2 className={"res-select-1"} value={realEstateType} setValue={setRealEstateType} label={"Loại BĐS"} list={listRealEstate} />
                  <MediaQuery minWidth={625}>
                    <div style={{width: "100%", marginTop: 12}}>
                        <SelectBox2  className={"res-select-1"} value={budget} setValue={setBudget} label={"Giá"} list={listBudget} />
                    </div>
                  </MediaQuery>
                </>
                
            }
            { typeBooking === 2 &&
              <>
                <SelectBox2  className={"res-select-1"} value={district} setValue={setDistrict} label={"Khu vực"} list={listDistrict?.map((el) => ({
                    ...el,
                    value: el.district_id,
                    label: el.district_name,
                  }))} />
                <div style={{width: "100%", marginTop: 12}}>
                  <SelectBox2  className={"res-select-1"} value={passenger} setValue={setPassenger} label={"Số người"} list={listGuest} />
                </div>
              </>
             
            }
            {
              typeBooking=== 3 && <>
                <SelectBox2  className={"res-select-1"} value={departurePoint} setValue={setDeparturePoint} label={"Điểm đi"} list={listProvince?.map((item) => ({
                  ...item,
                  value: item.province_id,
                  label: item.province_name,
                }))} />
              </>
            }    
            {
              typeBooking=== 4 && <>
                <SelectBox2 className={"res-select-1"} value={destinationPoint} setValue={setDestinationPoint} label={"Điểm đi"} list={listProvince?.map((item) => ({
                  ...item,
                  value: item.province_id,
                  label: item.province_name,
                }))} />
              </>
            }    
            
          </div>
          <div className="ele-bottom-container-booking-menu bottom-destination-point-booking">
            {     
              typeBooking=== 1 && 
              <>
                <MediaQuery minWidth={625}>
                    <SelectBox2 className={"res-select-1"} value={district} setValue={setDistrict} label={"Quận"} 
                        list={listDistrict?.map((el) => ({
                            ...el,
                            value: el.district_id,
                            label: el.district_name,
                        }))} 
                    />
                </MediaQuery>
                <MediaQuery maxWidth={625}>
                    <div style={{marginTop: 12}}>
                        <SelectBox2 className={"res-select-1"} value={district} setValue={setDistrict} label={"Quận"} 
                            list={listDistrict?.map((el) => ({
                                ...el,
                                value: el.district_id,
                                label: el.district_name,
                            }))} 
                        />
                    </div>
                </MediaQuery>
              </>
            }
            {
              typeBooking=== 2 && 
              <>
                <MediaQuery maxWidth={625}>
                    <div style={{display: "flex", alignItems: "center", gap: 10, marginTop: 12}}>
                        <DatePickerValue  className={"res-select-1"} value={departureDay} setValue={setDepartureDay} label={"Nhận phòng"} />
                        <div style={{width: "100%"}}>
                            <SelectBox2 className={"res-select-1"} value={seat} setValue={setSeat} label={"Số phòng"} list={listRoom} />
                        </div>
                    </div>
                </MediaQuery>
                <MediaQuery minWidth={625}>
                        <DatePickerValue  className={"res-select-1"} value={departureDay} setValue={setDepartureDay} label={"Nhận phòng"} />
                        <div style={{width: "100%", marginTop: 12}}>
                            <SelectBox2 className={"res-select-1"} value={seat} setValue={setSeat} label={"Số phòng"} list={listRoom} />
                        </div>
                </MediaQuery>
              </>
             
            }        
            {
              typeBooking=== 3 && <>
                <MediaQuery minWidth={625}>
                  <SelectBox2 className={"res-select-1"} value={destinationPoint} setValue={setDestinationPoint} label={"Điểm đến"} list={listProvince?.map((item) => ({
                    ...item,
                    value: item.province_id,
                    label: item.province_name,
                  }))} />
                </MediaQuery>
                <MediaQuery maxWidth={625}>
                  <div style={{marginTop: 12}}>
                    <SelectBox2 className={"res-select-1"} value={destinationPoint} setValue={setDestinationPoint} label={"Điểm đến"} list={listProvince?.map((item) => ({
                      ...item,
                      value: item.province_id,
                      label: item.province_name,
                    }))} />
                  </div>
                </MediaQuery>
              </>
            }    
            {
              typeBooking=== 4 && <>
                <MediaQuery minWidth={625}>
                  <SelectBox2 className={"res-select-1"} value={destinationPoint} setValue={setDestinationPoint} label={"Điểm đến"} list={listProvince?.map((item) => ({
                    ...item,
                    value: item.province_id,
                    label: item.province_name,
                  }))} />
                </MediaQuery>
                <MediaQuery maxWidth={625}>
                  <div style={{marginTop: 12}}>
                    <SelectBox2 className={"res-select-1"} value={destinationPoint} setValue={setDestinationPoint} label={"Điểm đến"} list={listProvince?.map((item) => ({
                      ...item,
                      value: item.province_id,
                      label: item.province_name,
                    }))} />
                  </div>
                </MediaQuery>
              </>
            }   
          </div>
          <div className="ele-bottom-container-booking-menu bottom-destination-point-booking">
            {     
              typeBooking=== 1 && 
              <>
              <MediaQuery minWidth={625}>
                <SelectBox2 className={"res-select-1 m-mt-2"} value={passenger} setValue={setPassenger} label={"Phường"} 
                  list={listWard?.map((el) => ({
                    ...el,
                    value: el.ward_id,
                    label: el.ward_name,
                  }))} />
              </MediaQuery>
              <MediaQuery maxWidth={625}>
                  <div style={{marginTop: 12}}>
                    <SelectBox2 className={"res-select-1 m-mt-2"} value={passenger} setValue={setPassenger} label={"Phường"} 
                    list={listWard?.map((el) => ({
                        ...el,
                        value: el.ward_id,
                        label: el.ward_name,
                    }))} />

                  </div>
              </MediaQuery>
              </>
            }
            {     
              typeBooking=== 2 && 
              <>
                <MediaQuery minWidth={625}>
                  <DatePickerValue className={"res-select-1"} value={destinationDay} setValue={setDestinationDay} label={"Trả phòng"} />
                  <div style={{width: "100%", marginTop: 12}}>
                    <SelectBox2 className={"res-select-1 m-mt-2"}  value={budget} setValue={setBudget} label={"Ngân sách"} list={listBudget} />
                  </div>
                </MediaQuery>
                <MediaQuery maxWidth={625}>
                    <div style={{display: "flex", alignItems: "center", gap: 10, marginTop: 12}}>
                      <DatePickerValue className={"res-select-1"} value={destinationDay} setValue={setDestinationDay} label={"Trả phòng"} />
                      <div style={{width: "100%"}}>
                          <SelectBox2 className={"res-select-1"}  value={budget} setValue={setBudget} label={"Ngân sách"} list={listBudget} />
                      </div>
                  </div>
                </MediaQuery>
              </>
            }
            {
              typeBooking=== 3 && <>
                <MediaQuery minWidth={625}>
                  <DatePickerValue className={"res-select-1 m-mt-2"} value={departureDay} setValue={setDepartureDay} label={"Ngày đi"} />
                </MediaQuery>
                <MediaQuery maxWidth={625}>
                  <div style={{marginTop: 12}}>
                    <DatePickerValue className={"res-select-1 m-mt-2"} value={departureDay} setValue={setDepartureDay} label={"Ngày đi"} />
                  </div>
                </MediaQuery>
              </>
            }
            {
              typeBooking=== 4 && <>
                <MediaQuery minWidth={625}>
                  <DatePickerValue className={"res-select-1 m-mt-2"} value={departureDay} setValue={setDepartureDay} label={"Ngày đi"} />
                </MediaQuery>
                <MediaQuery maxWidth={625}>
                  <div style={{marginTop: 12}}>
                    <DatePickerValue className={"res-select-1 m-mt-2"} value={departureDay} setValue={setDepartureDay} label={"Ngày đi"} />
                  </div>
                </MediaQuery>
              </>
            }
            
          </div>
          <MediaQuery maxWidth={625}>
            <div className="ele-bottom-container-booking-menu bottom-destination-point-booking">
              {
                  typeBooking=== 1 && <>
                    <div style={{marginTop: 12}}>
                      <SelectBox2 className={"res-select-1"} value={budget} setValue={setBudget} label={"Giá"} list={listBudget} />
                    </div>
                  </>
              }
            </div>
          </MediaQuery>
          <MediaQuery minWidth={625}>
            <div className="ele-bottom-container-booking-menu">
                <div className="bottom-button-search-booking" style={{height: "100%"}}>
                <button   
                    className={`custom-button ${isDisabledButton ? "disabled" : ""}`}
                    onClick={handleClick}
                    disabled={isDisabledButton}
                    style={{width: "100%", height: 66}}
                >
                    Tìm kiếm
                </button>
                
                { (typeBooking === 1 || typeBooking === 2) && 
                <div style={{width: "100%", marginTop: 12, opacity: 0}}>
                    <SelectBox2 className={"res-select-1"} value={passenger} setValue={setPassenger} label={"Số người"} list={listPassenger} />
                </div>
                }
                </div>
            </div>
          </MediaQuery>
          <MediaQuery maxWidth={625}>
            <div className="ele-bottom-container-booking-menu" style={{marginTop: 12}}>
              <div className="bottom-button-search-booking c-flex-center" style={{height: "auto"}}>
                <button   
                  className={`custom-button ${isDisabledButton ? "disabled" : ""}`}
                  onClick={handleClick}
                  disabled={isDisabledButton}
                  style={{width: "max-content"}}
                >
                  Tìm kiếm
                </button>
              </div>
            </div>
          </MediaQuery>
        </div>
      </div>
    </div>
  );
};

export default BookingMenuMobile;

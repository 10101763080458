import React, { Fragment } from "react";
import MediaQuery from "react-responsive";

const Section2 = (props) => {
  const { Logo2, Img2, Img1, mw } = props;

  return (
    <Fragment>
      {/* <MediaQuery minWidth={625}> */}
        <div style={{ width: "100%", marginTop: 20, marginBottom: 20 }}>
          <img src={Logo2} style={{ width: "100%" }} alt="" />
        </div>
      {/* </MediaQuery> */}
      <div style={{ width: "100%" }} className="c-flex-center">
        <div style={{ width: "100%", maxWidth: mw }}>
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
            className="m-flex-column"
          >
            <div
              data-aos="fade-right"
              className="p-10 dsas-hssas-dfasdhasas"
              style={{ flex: 1, paddingRight: 100, paddingLeft: 0 }}
            >
              <MediaQuery minWidth={625}>
                <div
                  style={{
                    padding: "10px 50px 10px 10px",
                    borderBottom: "3px solid #E27B07",
                    width: "max-content",
                    marginBottom: 24,
                  }}
                >
                  <p
                    style={{
                      fontSize: 28,
                      fontWeight: 700,
                      color: "#E27B07",
                      textTransform: "uppercase",
                    }}
                  >
                    Tầm nhìn
                  </p>
                </div>
              </MediaQuery>
              <MediaQuery maxWidth={625}>
                <div
                  className="skalsjkjwskaa"
                  style={{
                    padding: "10px 8px 0 0",
                    borderBottom: "3px solid #E27B07",
                    width: "max-content",
                    marginBottom: 24,
                  }}
                >
                  <p
                    className="ajksajskajekaw"
                    style={{
                      fontSize: 18,
                      fontWeight: 700,
                      color: "#E27B07",
                      textTransform: "uppercase",
                    }}
                  >
                    Tầm nhìn
                  </p>
                </div>
              </MediaQuery>
              <div
                style={{ fontSize: 20, marginBottom: 24, textAlign: "justify" }}
              >
                <li className="tx-18 tx-jt tx-jt-non tx-it fw-600">
                    “Minh Khang Group định hướng phát triển thành tập đoàn thương mại dịch vụ và du lịch hàng đầu châu Á”
                </li>
                <li className="tx-18 tx-jt tx-jt-non" style={{textIndent: 40}}>
                    Bằng khát vọng phát triển bền vững, góp phần nâng cao chất lượng cuộc sống cũng như vị thế của thương hiệu Việt trên trường quốc tế. <strong>Minh Khang Group</strong> không ngừng đổi mới, sáng tạo nhằm đem đến cho khách hàng những trải nghiệm theo tiêu chuẩn quốc tế và phong cách sống hiện đại.
                </li>
                <li className="tx-18 tx-jt tx-jt-non" style={{textIndent: 40}}>
                    <strong>Minh Khang Group</strong> không ngừng nỗ lực để trở thành một trong những đơn vị tiên phong cung cấp các dịch vụ bất động sản cho thuê, vận hành tòa nhà - khách sạn và đại lý du lịch - lữ hành uy tín, chuyên nghiệp. Là người bạn đồng hành cùng quý khách hàng trên hành trình chinh phục, khám phá những miền đất mới.
                </li>
              </div>
            </div>
            <MediaQuery minWidth={625}>
              <div data-aos="fade-left" className="ajskahjawisasj hfjasdhjsdsa">
                <img
                  className="dajsaksjkswawa dhuksdhgakuws"
                  src={Img2}
                  style={{ width: 400, aspectRatio: 1, objectFit: "cover" }}
                  alt=""
                />
              </div>
            </MediaQuery>
            <MediaQuery maxWidth={625}>
              <div className="">
                <img
                  className="m-w-100 dajsaksjkswawa skasjakswqeaw"
                  src={Img1}
                  style={{ width: 400, aspectRatio: 1 }}
                  alt=""
                />
              </div>
            </MediaQuery>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default Section2;

import React, { useEffect, useState } from "react";
import Item from "../../Product/Item";
import { getProductSuggestListApartment } from "../../../api";
import { Grid } from "@mui/material";
import { useNavigate } from "react-router-dom";
import MediaQuery from "react-responsive";

const ListApartment = () => {
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [forceRefresh, setForceRefresh] = useState(false); // Thêm state để bật/tắt gọi lại API

  useEffect(() => {
    const handleBeforeUnload = (event) => {
      // Kiểm tra nếu người dùng đang thực hiện refresh trang
      if (event.currentTarget.performance.navigation.type === 1) {
        localStorage.removeItem("cachedApartmentData");
      }
    };

    // Thêm sự kiện beforeunload khi component mount
    window.addEventListener("beforeunload", handleBeforeUnload);

    // Clean up khi component unmount
    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, []);

  useEffect(() => {
    // Hàm để kiểm tra và gọi API để cập nhật dữ liệu mới
    const fetchDataAndUpdateData = async () => {
      try {
        setLoading(true);
        const result = await getProductSuggestListApartment();
        setLoading(false);
        if (result?.success === true) {
          setData(result?.data);
          // Lưu dữ liệu vào localStorage để cache
          localStorage.setItem("cachedApartmentData", JSON.stringify(result?.data));
        }
      } catch (error) {
        console.log(error);
        setLoading(false);
      }
    };

    // Lấy dữ liệu từ localStorage khi component mount
    const cachedData = localStorage.getItem("cachedApartmentData");
    if (cachedData) {
      setData(JSON.parse(cachedData));
    }

    // Kiểm tra nếu là lần đầu tiên truy cập hoặc từ một route khác, hoặc có forceRefresh
    if (!cachedData || navigate.action === "PUSH" || forceRefresh) {
      // Gọi API để cập nhật dữ liệu mới
      fetchDataAndUpdateData();
      // Tắt forceRefresh sau khi gọi API
      setForceRefresh(false);
    }
  }, [navigate, forceRefresh]); // Chỉ gọi lại khi navigate.action hoặc forceRefresh thay đổi

  useEffect(() => {
    // Thêm listener để gọi lại API khi reload trang
    const handleBeforeUnload = () => {
      setForceRefresh(true);
    };
    window.addEventListener("beforeunload", handleBeforeUnload);
    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, []);

  if (loading === false) {
    return (
      <div className="container-list bg-gry">
        <div className="wrap-list">
          <div className="title-list tt-uc m-fs-16">Cho thuê căn hộ</div>
          <MediaQuery minWidth={625}>
            <Grid container spacing={5} mb={3}>
              {data?.map((item, key) => (
                <Grid key={key} item sm={3} xs={6}>
                  <Item w={"w-100"} {...item} />
                </Grid>
              ))}
            </Grid>
          </MediaQuery>
          <MediaQuery maxWidth={625}>
            <Grid container spacing={2} mb={3}>
              {data?.map((item, key) => (
                <Grid key={key} item sm={3} xs={6}>
                  <Item w={"w-100"} {...item} />
                </Grid>
              ))}
            </Grid>
          </MediaQuery>
        </div>
      </div>
    );
  } else {
    return null; // Hiển thị gì đó khi đang loading dữ liệu
  }
};

export default ListApartment;

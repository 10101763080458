import React, { useEffect, useState } from 'react';
import { MenuItem, FormControl, Select, Box, ListItemIcon, ListItemText } from '@mui/material';

const LanguageSelector = () => {
    return (
        <div></div>
    )
};

export default LanguageSelector;

import { Skeleton } from "@mui/material";
import React from "react";

const LoadingSkeletonProduct = () => {
  return (
    <div className="grid grid-cols-4 h-[410px] relative grid-rows-2 gap-3">
      {
        <>
          <Skeleton
            className="col-span-2 w-full h-full row-span-2 object-cover cursor-pointer rounded"
            variant="rounded"
            height={"100%"}
          />
          {Array.from(Array(4).keys())?.map((item, key) => (
            <Skeleton
              key={key}
              className="col-span-1 w-full h-full row-span-1 object-cover cursor-pointer rounded"
              variant="rounded"
              height={"100%"}
            />
          ))}
        </>
      }
    </div>
  );
};

export const LoadingSkeletonProduct1 = () => {
  return (
    <div
      style={{
        width: "100%",
        padding: "10px 30px",
      }}
    >
      <Skeleton variant="rounded" width="100%" height={"200px"} />
    </div>
  );
};

export default LoadingSkeletonProduct;

import React, { useEffect } from "react";
import { Routes, Route, useLocation } from "react-router-dom";
import Header from "./components/Header/Header";
import Home from "./pages/Home";
import Apartment from "./components/page/Apartment/Apartment";
import Hotel from "./components/page/Hotel/Hotel";
import Tour from "./components/page/Tour/Tour";
import Footer from "./components/Footer/Footer";
import TravelTour from "./components/page/Tour/TravelTour";
import Intro from "./components/page/Intro/Intro";
import Intro2 from "./components/page/Intro/Intro2";
import Intro3 from "./components/page/Intro/Intro3";
import Intro4 from "./components/page/Intro/Intro4";
import ManageApartment from "./components/page/Mange/ManageApartment";
import ManageDepartment from "./components/page/Mange/ManageDepartment";
import ManageInvest from "./components/page/Mange/MangeInvest";
import ManageHotel from "./components/page/Mange/ManageHotel";
import DetailProduct from "./pages/DetailProduct";
import Search from "./pages/Search";
import DetailTour from "./pages/DetailTour";
import Tickets from "./pages/Tickets";
import DetailBlog from "./pages/DetailBlog";
import Booking from "./pages/Booking";
import Booking2 from "./pages/Booking2";
import NotFoundPage from "./components/page/NotFound/NotFound";
import Contact from "./components/Contact/Contact";
// import Ticket from "./pages/DetailTicket";
import DetailTicket from "./pages/DetailTicket";
import PaymentTicket from "./pages/PaymentTicket";
import TourTrongNuoc from "./pages/TourTrongNuoc";
import TourBienDao from "./pages/TourBienDao";
import TourTayNguyen from "./pages/TourTayNguyen";
import TourDaily from "./pages/TourDaily";
import PaymentTour from "./pages/PaymentTour";
import VeMienBac from "./pages/VeMienBac";
import VeMienTrung from "./pages/VeMienTrung";
import VeMienNam from "./pages/VeMienNam";
import NhanSu from "./pages/NhanSu";
import TourMienBac from "./pages/TourMienBac";
import TourMienTrung from "./pages/TourMienTrung";
import TourMienNam from "./pages/TourMienNam";
import NangLucMinhKhang from "./components/page/Intro/NangLucMinhKhang";
import TinTucNoiBat from "./components/page/Intro/TinTucNoiBat";
import ReactGA from "react-ga4";
import { Helmet } from "react-helmet";
const WrapApp = () => {
  const location = useLocation();

  useEffect(() => {
    ReactGA.send({
      hitType: "pageview",
      page: location.pathname + location.search,
    });
  }, [location]);

  // useEffect(()=> {

  // })

  return (
    <>
      <Helmet>
        <meta
          data-react-helmet="true"
          property="og:url"
          content={window.location.origin + location.pathname}
        ></meta>
      </Helmet>
      <Header />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/gioi-thieu" element={<Intro />} />
        <Route path="/ve-chung-toi" element={<Intro />} />
        <Route path="/linh-vuc-hoat-dong" element={<Intro2 />} />
        <Route path="/tuyen-dung" element={<Intro3 />} />
        <Route path="/nang-luc-minh-khang" element={<NangLucMinhKhang />} />
        <Route path="/hoat-dong-noi-bo" element={<Intro4 />} />
        <Route path="/tin-tuc-noi-bat" element={<TinTucNoiBat />} />
        <Route path="/dat-phong-khach-san/*" />
        <Route path="/quan-ly-van-hanh-can-ho" element={<ManageApartment />} />
        <Route path="/quan-ly-van-hanh-khach-san" element={<ManageHotel />} />
        <Route
          path="/quan-ly-van-hanh-van-phong"
          element={<ManageDepartment />}
        />
        <Route path="/nhan-su" element={<NhanSu />} />
        <Route path="/tu-van-dau-tu" element={<ManageInvest />} />
        <Route
          path="/dat-phong-villa"
          element={
            <Hotel
              id={17}
              type={2}
              book_type={1}
              t={1}
              title={"Minh Khang Villa"}
            />
          }
        />
        <Route
          path="/dat-phong-khach-san"
          element={
            <Hotel
              id={16}
              type={2}
              book_type={2}
              t={2}
              title={"Minh Khang Hotel"}
            />
          }
        />
        <Route
          path="/dat-phong-homestay"
          element={
            <Hotel
              id={18}
              type={2}
              book_type={3}
              t={3}
              title={"Minh Khang Homestay"}
            />
          }
        />
        <Route path="/hotel" element={<Hotel />} />
        <Route path="/apartment/*" element={<Apartment />} />
        {/*  */}
        {/* <Route path="/nha-nguyen-can" element={<Apartment id={19} title={"Nhà nguyên căn"} />} />
          <Route path="/phong-tro" element={<Apartment id={20} title={"Phòng trọ"} />} />
          <Route path="/chung-cu-cao-cap" element={<Apartment title={"Chung cư cao cấp"} id={21} />} />
          <Route path="/penhouse" element={<Apartment id={22} title={"Penhouse"} />} />
          <Route path="/studio" element={<Apartment id={24} title={"Studio"} />} /> */}
        <Route
          path="/nha-nguyen-can"
          element={<Apartment type={1} id={19} title={"Nhà nguyên căn"} />}
        />
        <Route
          path="/can-ho-phong-cho-thue"
          element={
            <Apartment type={4} id={20} title={"Căn hộ - phòng cho thuê"} />
          }
        />
        <Route
          path="/mat-bang"
          element={<Apartment type={3} title={"Mặt bằng"} id={21} />}
        />
        <Route
          path="/cho-thue-van-phong"
          element={<Apartment type={2} id={22} title={"Cho thuê văn phòng"} />}
        />
        {/*  */}
        <Route path="/tour" element={<Tour title="Tour" />} />
        <Route path="/cam-nang-du-lich" element={<Tour title="Cẩm nang du lịch" />} />
        <Route path="/tour-daily" element={<TourDaily type={1} />} />
        <Route path="/tour-du-lich" element={<TourTrongNuoc type={2} />} />
        <Route path="/tour-mien-bac" element={<TourMienBac type={2} />} />
        <Route path="/tour-mien-trung" element={<TourMienTrung type={2} />} />
        <Route path="/tour-mien-nam" element={<TourMienNam type={2} />} />
        <Route path="/tour-bien-dao" element={<TourBienDao type={2} />} />
        <Route path="/tour-tay-nguyen" element={<TourTayNguyen type={2} />} />
        <Route path="/tour/detail/:id" element={<DetailTour />} />
        <Route path="/product/:id" element={<DetailProduct />} />
        <Route path="/p/:id" element={<DetailProduct />} />
        <Route path="/search" element={<Search />} />
        <Route path="/ve-tham-quan" element={<Tickets />} />
        <Route path="/ve-tham-quan-mien-bac" element={<VeMienBac />} />
        <Route path="/ve-tham-quan-mien-trung" element={<VeMienTrung />} />
        <Route path="/ve-tham-quan-mien-nam" element={<VeMienNam />} />
        <Route path="/blog/detail/:id" element={<DetailBlog />} />
        <Route path="/ticket/detail/:id/*" element={<DetailTicket />} />
        <Route path="/ticket/payment/:id" element={<PaymentTicket />} />
        <Route path="/tour/payment/:id" element={<PaymentTour />} />
        <Route path="/ve-may-bay" element={<Booking />} />
        <Route path="/ve-tau-lua" element={<Booking2 />} />
        <Route path="/*" element={<NotFoundPage />} />
      </Routes>
      <Contact />
      <Footer />
    </>
  );
};

export default WrapApp;

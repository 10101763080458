import React, { useEffect } from "react";
import Banner from "../components/page/home/Banner";
import Mission from "../components/page/home/Mission";
import ListHotel from "../components/page/home/ListHotel";
import ListTour from "../components/page/home/ListTour";
import ListApartment from "../components/page/home/ListApartment";
import Banner2 from "../components/page/home/Banner2";
import Article from "../components/page/home/Article";
import Contact from "../components/page/home/Contact";
import ScrollToTop from "../utils/ScrollToTop";
import BookingMenuMobile from "../components/page/home/BookingMenuMobile";
import MissionImageMb from "../assets/home/missionmb.png";
import MediaQuery from "react-responsive";
import SectionTour from "../components/Tour/SectionTour";
import InternalBlog from "../components/page/home/InternalBlog";
import { Helmet, HelmetData } from "react-helmet-async";

const Home = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="wrap-home">
      <Helmet helmetData={new HelmetData({})}>
        <title>Minh Khang Group</title>
        <link rel="canonical" href={window.location.origin + window.location.pathname} />
        <meta
          data-react-helmet="true"
          property="og:url"
          content={window.location.origin + window.location.pathname}
        ></meta>
      </Helmet>
      <div className="home-container">
        <ScrollToTop />
        <Banner />
        <div style={{ marginBottom: 12 }}></div>
        <MediaQuery maxWidth={625}>
          <BookingMenuMobile />
        </MediaQuery>
        <Mission />
        <InternalBlog />
        <MediaQuery maxWidth={625}>
          <div className="askhsajskuiqwesa" style={{ width: "100%", marginTop: 40, marginBottom: 40 }}>
            <img
              src={MissionImageMb}
              style={{ width: "100%" }}
              alt="Can't open"
            />
          </div>
        </MediaQuery>
        <br />
        <ListApartment />
        <div className="divider-spacing"></div>
        <ListHotel />
        <div className="divider-spacing"></div>
        <ListTour />
        <div className="divider-spacing"></div>
        {/* <Banner2 /> */} 
        {/* <MediaQuery maxWidth={625}>
          <div className="divider-spacing"></div>
          <SectionTour />
        </MediaQuery> */}
        <div className="divider-spacing"></div>
        {/* <MediaQuery minWidth={625}>
          <Article />
        </MediaQuery> */}
        <div className="divider-spacing"></div>
        <Contact />
      </div>
    </div>
  );
};

export default Home;

import React, { useRef } from "react";
import Slider from "react-slick";
import TemplateImage from "../../assets/home/template_image.png";
import { MdArrowBackIosNew } from "react-icons/md";
import { MdOutlineArrowForwardIos } from "react-icons/md";
import numberWithCommas from "../../utils/numberWithComma";
import { Link, useNavigate } from "react-router-dom";
import { Rating } from "@mui/material";
import createSlug from "../../utils/createSlug";

const Item = (props) => {
  // const navigate = useNavigate();
  const sliderRef = useRef();
  const settings = {
    dots: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };
  const handleNextSlider = () => {
    sliderRef.current.slickNext();
  };
  const handlerPrevSlider = () => {
    sliderRef.current.slickPrev();
  };

  const renderEndow = (endow) => {
    switch (endow) {
      case 1:
        return "Hot";
      case 2:
        return "Ưu đãi";
      case 3:
        return "Bán chạy";
      default:
        break;
    }
  };

  return (
    <div
      className={`item-product ${props?.w}`}
      style={{ backgroundColor: "white" }}
    >
      <div className="container-item-product">
        <div className="wrap-item-product">
          <div
            className="container-image-item-product"
            style={{ position: "relative" }}
          >
            {parseInt(props?.endow) >= 1 && (
              <div
                style={{
                  padding: "10px 25px",
                  position: "absolute",
                  right: 0,
                  top: 0,
                  background: "#D03601",
                  borderRadius: "50%",
                  zIndex: 2,
                  color: "#fff",
                  fontSize: 14,
                  fontWeight: 600,
                  marginTop: 6,
                  marginRight: 6,
                  cursor: "pointer",
                }}
                className="c-flex-center tt-uc blink"
              >
                {renderEndow(props?.endow)}
              </div>
            )}
            <div
              onClick={handlerPrevSlider}
              className="button-arrow-slider button-prev-arrow-slider c-flex-center"
            >
              <MdArrowBackIosNew style={{ color: "#E27B07" }} />
            </div>
            <div
              onClick={handleNextSlider}
              className="button-arrow-slider button-next-arrow-slider c-flex-center"
            >
              <MdOutlineArrowForwardIos style={{ color: "#E27B07" }} />
            </div>
            <Slider
              className="m-slider-product"
              arrows={false}
              ref={sliderRef}
              {...settings}
            >
              {props?.photo?.length <= 0 ? (
                <div className="item-slider">
                  <img
                    src={TemplateImage}
                    style={{ objectFit: "cover" }}
                    alt=""
                    className="image-slider"
                  />
                </div>
              ) : (
                <div className="item-slider">
                  {" "}
                  <img
                    loading={"lazy"}
                    src={props?.photo}
                    style={{ objectFit: "cover" }}
                    alt=""
                    className="image-slider"
                  />
                </div>
              )}
              {props?.productphotos?.length > 0 &&
                props?.productphotos?.map((item, key) => (
                  <div key={key} className="item-slider">
                    <img
                      loading={"lazy"}
                      src={item?.imgUrl}
                      style={{ objectFit: "cover" }}
                      alt=""
                      className="image-slider"
                    />
                  </div>
                ))}

              {props?.productphotos?.length <= 0 && (
                <div className="item-slider">
                  <img
                    src={TemplateImage}
                    style={{ objectFit: "cover" }}
                    alt=""
                    className="image-slider"
                  />
                </div>
              )}
            </Slider>
          </div>
          <Link style={{color: "unset", textDecoration: "none"}} to={"/product/"+ createSlug(props?.name)?.split(" ")?.join("-") + "-" + props?.id}>
            <div
              // onClick={handleClick}
              style={{
                borderBottom: "1px solid #E27B07",
                cursor: "pointer",
                padding: 10,
              }}
            >
              <div className="container-price-item-product">
                <div
                  className="unit-price-item-product tr-2l h-48p"
                  style={{ fontWeight: 600 }}
                >
                  {props?.name}
                </div>
                {/* <span className="price-item-product">15,000,000.00</span> */}
              </div>
              <div
                title={`${props?.districtText?.replace(
                  "Quận",
                  ""
                )} ${props?.provinceText?.replace("Thành phố", "")}`}
                className={"wrap-a-a"}
                style={{
                  display: "flex",
                  alignItems: "center",
                  marginTop: 12,
                  marginBottom: 4,
                }}
              >
                {props?.hotel === true && parseInt(props?.rating) > 0 && (
                  <Rating
                    size={"small"}
                    name="read-only"
                    value={props?.rating}
                    readOnly
                    style={{ marginRight: 10 }}
                  />
                )}
                {props?.province && props?.district ? (
                  <div
                    className="container-address-item-product"
                    style={{ height: 18 }}
                  >
                    <span
                      style={{
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        maxWidth: "100%",
                      }}
                    >
                      {props?.districtText?.replace("Quận", "")},{" "}
                      {props?.provinceText?.replace("Thành phố", "")}
                    </span>
                  </div>
                ) : (
                  <div
                    className="container-address-item-product"
                    style={{ marginTop: 12, marginBottom: 4, height: 18 }}
                  >
                    <span style={{ opacity: 0 }}>..</span>
                  </div>
                )}
              </div>
            </div>
          </Link>
          <div
            style={{
              padding: "0px 10px",
              height: 30,
              justifyContent: "flex-start",
            }}
            className="c-flex-center"
          >
            {(props?.rent === false || props?.rent=== true) && (
              <div className="tr-1l" style={{ width: "100%" }}>
                {parseInt(props?.discountPer) > 0 && (
                  <>
                    <del
                      className="fw-600"
                      style={{ fontSize: 14, color: "#6B6664" }}
                    >
                      {numberWithCommas(props?.price)}
                    </del>{" "}
                    -
                  </>
                )}
                &nbsp;
                <span className="price-item-product">
                  {numberWithCommas(
                    Math.ceil(
                      (props?.price *
                        (100 - (parseInt(props?.discountPer) || 0))) /
                        100
                    )
                  )}
                  {props?.hotel === true ? "" : "/ tháng"}
                </span>
              </div>
            )}
            {/* {props?.rent === true && (
              <span className="price-item-product">Đã thuê</span>
            )} */}
          </div>
          {/* <div className="container-button-show-more-item-product c-flex-center">
            <div className="text-button-show-more-item-product">Show more...</div>
          </div> */}
        </div>
      </div>
    </div>
  );
};

export default Item;

import React, { useCallback, useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { apiGetBlogType, apiGetDetailBlogType } from "../../../api";
import { Swiper, SwiperSlide } from "swiper/react";
// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import { Pagination, Autoplay, Navigation } from "swiper/modules";
import { GrFormNext, GrFormPrevious } from "react-icons/gr";
import createSlug from "../../../utils/createSlug";


const InternalBlog = () => {
  const [data, setData] = useState();
  const navigate = useNavigate();
  const sliderRef = useRef(null);

  const handlePrev = useCallback(() => {
    if (!sliderRef.current) return;
    sliderRef.current.swiper.slidePrev();
  }, []);

  const handleNext = useCallback(() => {
    if (!sliderRef.current) return;
    sliderRef.current.swiper.slideNext();
  }, []);
  const handleClick = (item) => {
    navigate("/blog/detail/"+ createSlug(item?.name)?.split(" ")?.join("-") + "-"  + item?.id);
  };
  // const settings = {
  //   dots: true,
  //   speed: 500,
  //   slidesToShow: 3,
  //   slidesToScroll: 1,
  // };

  useEffect(() => {
    (async () => {
      const result = await apiGetDetailBlogType({ type: 15 });
      setData(result?.data);
    })();
  }, []);
  return (
    <div
      className="asasadjka-sad-sf-s-as-a"
      style={{
        width: "100%",
        background: "#F5F4F4",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        padding: "60px 0",
        paddingTop: "80px",
      }}
    >
      <div
        className="djadadsfd-ssd-sa-sfn"
        style={{ maxWidth: 1248, width: "100%", position: "relative" }}
      >
        <div
          style={{
            position: "absolute",
            left: "-5%",
            top: "-20%",
            fontSize: 28,
            fontWeight: 600,
            textTransform: "uppercase",
          }}
          className="text-gra shashaakw-dajsakskas"
        >
          Tin nổi bật
        </div>
        <Swiper
          loop={true}
          ref={sliderRef}
          slidesPerView={5}
          spaceBetween={50}
          centeredSlides={true}
          autoplay={{
            delay: 2500,
            disableOnInteraction: false,
          }}
          breakpoints={{
            300: {
              slidesPerView: 2,
              spaceBetween: 30,
              centeredSlides: false
            },
            625: {
              slidesPerView: 3,
              spaceBetween: 50,
              centeredSlides: false
            },
            1200: {
              slidesPerView: 3,
              spaceBetween: 50,
              centeredSlides: false
            },
            1400: {
              slidesPerView: 3,
              spaceBetween: 50,
              centeredSlides: false
            },
            1800: {
              slidesPerView: 3,
              spaceBetween: 50,
              centeredSlides: false
            }
          }}
          // pagination={{
          //   clickable: true,
          // }}
          modules={[Autoplay, Pagination, Navigation]}
          className="mySwiper1"
        >
          {data?.map((item, key) => (
            <SwiperSlide key={key}>
              <Link style={{color: "unset", textDecoration: "none"}} to={"/blog/detail/"+ createSlug(item?.name)?.split(" ")?.join("-") + "-"  + item?.id}>
                <div
                  className="item-slider asd-as-a-wasasa"
                  style={{ cursor: "pointer" }}
                  onClick={() => handleClick(item)}
                >
                  <img
                    src={item?.photo}
                    style={{
                      aspectRatio: 16 / 9,
                      width: "100%",
                      objectFit: "cover",
                      borderRadius: 10,
                    }}
                    alt=""
                  />
                  <div
                    className="tr-3l jarhjaslksass"
                    style={{ marginTop: 12, maxWidth: "100%" }}
                  >
                    {item?.name}
                  </div>
                </div>
              </Link>
            </SwiperSlide>
          ))}
        </Swiper>
        <div
          onClick={() => {
            handlePrev();
          }}
          className="prev-btn-nivo c-flex-center ajksahjksajks"
          style={{
            marginRight: 20,
            position: "absolute",
            left: "-5%",
            top: "50%",
            transform: "translate(0% , -50%)",
          }}
        >
          <GrFormPrevious color="#E27B07" size={24} />
        </div>
        <div
          onClick={() => handleNext()}
          className="next-btn-nivo c-flex-center ahjshjkasasa"
          style={{
            marginRight: 20,
            position: "absolute",
            right: "-5%",
            top: "50%",
            transform: "translate(0% , -50%)",
          }}
        >
          <GrFormNext color="#E27B07" size={24} />
        </div>
      </div>
    </div>
  );
};

export default InternalBlog;

import React, { useEffect, useState } from "react";
// import ItemImage from "../../../assets/apartment/item.png";
// import Banner from "../../../assets/apartment/banner.png";
// import Banner1 from "../../../assets/apartment/banner1.png";
// import Banner2 from "../../../assets/apartment/banner2.png";
// import Banner4 from "../../../assets/apartment/banner4.png";
import B1Image from "./image/b1.png";
import B2Image from "./image/b2.png";
import B3Image from "./image/b3.png";
import B1Image2 from "./image/b1_2.png";
import B2Image2 from "./image/b2_2.png";
import B3Image2 from "./image/b3_2.png";
import B1Image3 from "./image/b1_3.png";
import B2Image3 from "./image/b2_3.png";
import B3Image3 from "./image/b3_3.png";
import B2Image11 from "./image2/b1.png";
import B2Image21 from "./image2/b2.png";
import {
  apiGetCategory,
  apiGetProvince,
  apiGetWard,
  apiSearchFilter,
  apiSearchResult,
  getListProvince,
  getProductByCategory,
} from "../../../api";
// import SelectBox2 from "../../util/SelectBox/SelectBox";
import SelectBox3 from "../../util/SelectBox/SelectBox3";
import {
  FormControl,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  Slider,
} from "@mui/material";
import { valuetext } from "../Hotel/Hotel";
import { listBedRoom, listPrice, listRoom, listStar } from "../../../data/data";
import Item from "../../Product/Item";
import LoadingHotel from "../../Loading/LoadingHotel";
import ScrollToTop from "../../../utils/ScrollToTop";
import {
  MAX_PRICE_FILTER,
  MAX_PRICE_FILTER_APARTMENT,
  MIN_PRICE_FILTER,
  STEP_FILTER,
} from "../../../config/constant";
import numberWithDot from "../../../utils/numberWithDot";
import MediaQuery from "react-responsive";
import SelectBox4 from "../../util/SelectBox/SelectBox4";
import { Image, Swiper } from "react-nivo-slider";
import { setting2 } from "../home/Banner";
import Pagination from "@mui/material/Pagination";
import { Helmet } from "react-helmet";
import { debounce } from 'lodash';

const Apartment = (props) => {
  const categoryId = 13;
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 12;
  const { id } = props;
  const [loading, setLoading] = useState();
  const [data, setData] = useState([]);
  const [dataPagination, setDataPagination] = useState();
  const [rangePrice, setRangePrice] = useState([
    MIN_PRICE_FILTER,
    MAX_PRICE_FILTER_APARTMENT,
  ]);
  const [priceValue, setPriceValue] = useState();
  const [listProvince, setListProvince] = useState([]);
  const [listWard, setListWard] = useState([]);
  const [province, setProvince] = useState();
  const [district, setDistrict] = useState();
  const [typeRoom, setTypeRoom] = useState();
  const [ward, setWard] = useState();
  const [provinceDetail, setProvinceDetail] = useState();
  const [square, setSquare] = useState([]);
  const [resetFilter, setResetFilter]= useState(false)
  // const [star, setStar] = useState();
  // const [seat, setSeat] = useState();
  const [listCategory, setListCategory] = useState([]);

  const handleChangePrice = (event, newValue) => {
    setRangePrice(newValue);
  };
  
  const getprovince = async (code) => {
    const response = await apiGetProvince(code);
    setProvinceDetail(response?.results);
  };

  const getdistrict = async (code) => {
    const response = await apiGetWard(code);
    setListWard(response?.results);
  };

  useEffect(() => {
    if (province) getprovince(province);
    if (district) getdistrict(district);
  }, [province, district]);
  useEffect(() => {
    (async () => {
      const result = await getListProvince();
      setListProvince(result);
    })();
  }, []);

  useEffect(() => {
    (async () => {
      try {
        setLoading(true);
        const data = {
          typeBooking: 1,
          id: categoryId,
          subid: id,
          province: province,
          district: district,
          ward: ward,
          minSquare: square[0],
          maxSquare: square[1],
          minPrice: rangePrice[0],
          maxPrice: rangePrice[1],
          typeRoom: typeRoom,
          // minSquare: square[0],
          // maxSquare: square[1],
          // departureDay: departureDay ? departureDay : "",
          // passenger: passenger,
          // budget: budget,
          subCategoryId: id,
          minBudget: rangePrice[0],
          maxBudget: rangePrice[1],
          page: currentPage,
          isFrom: "client",
          pageSize: parseInt(9),
        };
        const result = await apiSearchFilter(data);
        setData(result?.data);
        if (result?.success === true) {
          setData(result?.data);
          setDataPagination(result?.pagination);
        }
      } catch (error) {
        setLoading(false);
      } finally {
        setLoading(false);
      }
    })();
  }, [id, currentPage, district, province, rangePrice, typeRoom, ward, square]);

  useEffect(() => {
    (async () => {
      const result = await apiGetCategory({ categoryId });
      // console.log(result)
      setListCategory(
        result?.data?.map((item) => ({
          label: item?.sub_name,
          value: item?.id,
        }))
      );
    })();
  }, []);

  const handleClick = async () => {
    window.scrollTo(0, 0);
    try {
      const data = {
        typeBooking: 1,
        id: categoryId,
        subid: id,
        province: province,
        district: district,
        ward: ward,
        minSquare: square[0],
        maxSquare: square[1],
        minPrice: rangePrice[0],
        maxPrice: rangePrice[1],
        typeRoom: typeRoom,
        // departureDay: departureDay ? departureDay : "",
        // passenger: passenger,
        // budget: budget,
        subCategoryId: id,
        minBudget: rangePrice[0],
        maxBudget: rangePrice[1],
        page: currentPage,
        isFrom: "client",
        pageSize: 9

      };
      setResetFilter(true)
      setLoading(true);
      const result = await apiSearchFilter(data);
      setData(result?.data);
      setCurrentPage(1);
      setLoading(false);
      // console.log(result)
      // Xử lý logic khi button được click
    } catch (error) {
      setLoading(false);
    } finally {
      setLoading(false);
    }
  };

  const handleResetClick = async () => {
    window.scrollTo(0, 0);
    try {
      const data = {
        typeBooking: 1,
        id: categoryId,
        subid: id,
        province: undefined,
        district: undefined,
        ward: undefined,
        minSquare: undefined,
        maxSquare: undefined,
        minPrice: undefined,
        maxPrice: undefined,
        typeRoom: undefined,
        // departureDay: departureDay ? departureDay : "",
        // passenger: passenger,
        // budget: budget,
        subCategoryId: id,
        minBudget: undefined,
        maxBudget: undefined,
        page: 1,
        isFrom: "client",
        pageSize: 9

      };
      setResetFilter(true)
      setLoading(true);
      
      const result = await apiSearchFilter(data);
      setData(result?.data);
      setCurrentPage(1);
      setProvince(-1)
      
      setLoading(false);
      // console.log(result)
      // Xử lý logic khi button được click
    } catch (error) {
      setLoading(false);
    } finally {
      setLoading(false);
      setResetFilter(false)
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [id]);

  const handlePageChange = (event, value) => {
    setCurrentPage(value);
  };

  return (
    <div
      className="container-apartment"
      style={{
        width: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Helmet>
        <title>{props?.title}</title>
        <link rel="canonical" href={window.location.origin + window.location.pathname} />
        <meta
          data-react-helmet="true"
          property="og:url"
          content={window.location.origin + window.location.pathname}
        ></meta>
      </Helmet>
      <ScrollToTop />
      <div className="wrap-apartment" style={{ width: "100%" }}>
        <div
          className="banner-apartment m-mb-0"
          style={{ width: "100%", marginBottom: 30, position: "relative" }}
        >
          {props?.type === 1 && (
            <Swiper {...setting2}>
              <Image src={B1Image3} alt="image1" />
              <Image src={B2Image3} alt="image2" />
              <Image src={B3Image3} alt="image3" />
            </Swiper>
          )}
          {props?.type === 2 && (
            <Swiper {...setting2}>
              <Image src={B1Image2} alt="image1" />
              <Image src={B2Image2} alt="image2" />
              <Image src={B3Image2} alt="image3" />
            </Swiper>
          )}
          {props?.type === 3 && (
            <Swiper {...setting2}>
              <Image src={B2Image11} alt="image1" />
              <Image src={B2Image21} alt="image2" />
            </Swiper>
          )}
          {props?.type === 4 && (
            <Swiper {...setting2}>
              <Image src={B1Image} alt="image1" />
              <Image src={B2Image} alt="image2" />
              <Image src={B3Image} alt="image3" />
            </Swiper>
          )}
          <div style={{ position: "absolute", top: "50%", left: "10%" }}>
            {/* <p
              style={{
                color: "#fff",
                fontSize: 64,
                fontWeight: 700,
                textTransform: "uppercase",
              }}
              className="m-fz-32"
            >
              {props?.title}
            </p> */}
          </div>
        </div>
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div
            className="m-flex-column p-10"
            style={{ width: "100%", maxWidth: 1248, display: "flex" }}
          >
            <div className="apartment-section-1 m-w-100" style={{ width: 300 }}>
              <MediaQuery minWidth={625}>
                <div
                  style={{
                    fontSize: 20,
                    fontWeight: 700,
                    color: "#E27B07",
                    textDecoration: "none",
                    marginBottom: 12,
                    textTransform: "uppercase",
                  }}
                >
                  Bộ lọc tìm kiếm
                </div>
              </MediaQuery>
              <div
                style={{
                  width: "100%",
                  borderRadius: 10,
                  overflow: "hidden",
                  marginBottom: 12,
                }}
              >
                <div
                  style={{
                    width: "100%",
                    background:
                      "linear-gradient(180deg, rgba(208, 54, 1, 0.74) 0%, rgba(226, 123, 7, 0.67) 100%)",
                    padding: "10px 20px",
                  }}
                >
                  <p
                    style={{
                      fontWeight: 700,
                      fontSize: 14,
                      color: "#fff",
                      textTransform: "uppercase",
                    }}
                  >
                    Địa điểm, vị trí
                  </p>
                </div>
                <div
                  style={{
                    width: "100%",
                    backgroundColor: "#F0F0F0",
                    padding: 10,
                  }}
                >
                  <div
                    style={{
                      width: "100%",
                      borderRadius: 10,
                      border: "1px solid #e7e7e7",
                      background: "#fff",
                      padding: 10,
                      marginBottom: 12,
                    }}
                  >
                    {/* {console.log(province)} */}
                    <SelectBox3
                      size={"small"}
                      value={province}
                      setValue={setProvince}
                      label={"Tỉnh / Thành phố"}
                      list={[{value: -1, label: "Tất cả"}].concat(listProvince?.map((item) => ({
                        ...item,
                        value: item.province_id,
                        label: item.province_name,
                      })))}
                    />
                  </div>
                  <div
                    style={{
                      width: "100%",
                      borderRadius: 10,
                      border: "1px solid #e7e7e7",
                      background: "#fff",
                      padding: 10,
                      marginBottom: 12,
                    }}
                  >
                    <p style={{ fontSize: 12, color: "#989898" }}>
                      <SelectBox3
                        size={"small"}
                        value={district}
                        setValue={setDistrict}
                        label={"Quận / Huyện"}
                        list={provinceDetail?.map((el) => ({
                          ...el,
                          value: el.district_id,
                          label: el.district_name,
                        }))}
                      />
                    </p>
                  </div>
                  <div
                    style={{
                      width: "100%",
                      borderRadius: 10,
                      border: "1px solid #e7e7e7",
                      background: "#fff",
                      padding: 10,
                      marginBottom: 12,
                    }}
                  >
                    <SelectBox3
                      size={"small"}
                      value={ward}
                      setValue={setWard}
                      label={"Xã / Phường"}
                      list={listWard?.map((el) => ({
                        ...el,
                        value: el.ward_id,
                        label: el.ward_name,
                      }))}
                    />
                  </div>
                  {/*  */}
                  <MediaQuery maxWidth={625}>
                    <div
                      style={{
                        width: "100%",
                        borderRadius: 10,
                        border: "1px solid #e7e7e7",
                        background: "#fff",
                        padding: 10,
                        marginBottom: 12,
                      }}
                    >
                      <SelectBox4
                        size={"small"}
                        value={priceValue}
                        setValue={setPriceValue}
                        handleRange={setRangePrice}
                        label={"Giá"}
                        list={listPrice}
                      />
                    </div>
                  </MediaQuery>
                </div>
              </div>
              {/*  */}
              <MediaQuery minWidth={625}>
                <div
                  style={{
                    width: "100%",
                    borderRadius: 10,
                    overflow: "hidden",
                    marginBottom: 12,
                  }}
                >
                  <div
                    style={{
                      width: "100%",
                      background:
                        "linear-gradient(180deg, rgba(208, 54, 1, 0.74) 0%, rgba(226, 123, 7, 0.67) 100%)",
                      padding: "10px 20px",
                    }}
                  >
                    <p
                      style={{
                        fontWeight: 700,
                        fontSize: 14,
                        color: "#fff",
                        textTransform: "uppercase",
                      }}
                    >
                      Loại phòng
                    </p>
                  </div>
                  <div
                    style={{
                      width: "100%",
                      backgroundColor: "#F0F0F0",
                      padding: 10,
                    }}
                  >
                    <div
                      style={{
                        width: "100%",
                        borderRadius: 10,
                        border: "1px solid #e7e7e7",
                        background: "#fff",
                        padding: 10,
                        marginBottom: 12,
                      }}
                    >
                      <SelectBox3
                        size={"small"}
                        value={typeRoom}
                        setValue={setTypeRoom}
                        label={"Loại phòng"}
                        list={listBedRoom
                          ?.filter((item) => item.value <= 11)
                          ?.filter((item) => item.value != 6)}
                      />
                    </div>
                    {/* <div
                      style={{
                        width: "100%",
                        borderRadius: 10,
                        border: "1px solid #e7e7e7",
                        background: "#fff",
                        padding: 10,
                        marginBottom: 12,
                      }}
                    >
                      <SelectBox3
                        size={"small"}
                        value={star}
                        setValue={setStar}
                        label={"Số sao"}
                        list={listStar}
                      />
                    </div> */}
                    {/* <div
                      style={{
                        width: "100%",
                        borderRadius: 10,
                        border: "1px solid #e7e7e7",
                        background: "#fff",
                        padding: 10,
                        marginBottom: 12,
                      }}
                    >
                      <SelectBox3
                        size={"small"}
                        value={seat}
                        setValue={setSeat}
                        label={"Số phòng"}
                        list={listRoom}
                      />
                    </div> */}
                  </div>
                </div>
                <div
                  className="container-qjsa-1"
                  style={{
                    width: "100%",
                    borderRadius: 10,
                    marginBottom: 12,
                  }}
                >
                  <div
                    style={{
                      width: "100%",
                      background:
                        "linear-gradient(180deg, rgba(208, 54, 1, 0.74) 0%, rgba(226, 123, 7, 0.67) 100%)",
                      padding: "10px 20px",
                    }}
                  >
                    <p
                      style={{
                        fontWeight: 700,
                        fontSize: 14,
                        color: "#fff",
                        textTransform: "uppercase",
                      }}
                    >
                      Ngân sách ( VND )
                    </p>
                  </div>
                  <div
                    style={{
                      width: "100%",
                      backgroundColor: "#F0F0F0",
                      padding: 10,
                    }}
                  >
                    <Slider
                      getAriaLabel={() => "Ngân sách"}
                      value={rangePrice}
                      onChange={handleChangePrice}
                      valueLabelDisplay="auto"
                      getAriaValueText={valuetext}
                      valueLabelFormat={valuetext}
                      min={MIN_PRICE_FILTER}
                      step={STEP_FILTER}
                      max={MAX_PRICE_FILTER_APARTMENT}
                      color="warning"
                    />
                    <div
                      style={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        marginTop: 10,
                      }}
                    >
                      <div
                        style={{
                          background: "#E5E5E5",
                          borderRadius: 80,
                          fontSize: 14,
                          padding: 5,
                          minWidth: 20,
                        }}
                        className="c-flex-center"
                      >
                        <p>{MIN_PRICE_FILTER}</p>
                      </div>
                      <div
                        style={{
                          background: "#E5E5E5",
                          borderRadius: 80,
                          fontSize: 14,
                          padding: 5,
                          minWidth: 20,
                        }}
                        className="c-flex-center"
                      >
                        <p>{numberWithDot(MAX_PRICE_FILTER_APARTMENT)}</p>
                      </div>
                    </div>
                  </div>
                </div>
                {/*  */}
                <div
                  style={{
                    width: "100%",
                    borderRadius: 10,
                    overflow: "hidden",
                    marginBottom: 12,
                  }}
                >
                  <div
                    style={{
                      width: "100%",
                      background:
                        "linear-gradient(180deg, rgba(208, 54, 1, 0.74) 0%, rgba(226, 123, 7, 0.67) 100%)",
                      padding: "10px 20px",
                    }}
                  >
                    <p
                      style={{
                        fontWeight: 700,
                        fontSize: 14,
                        color: "#fff",
                        textTransform: "uppercase",
                      }}
                    >
                      Diện tích
                    </p>
                  </div>
                  <div
                    style={{
                      width: "100%",
                      backgroundColor: "#F0F0F0",
                      padding: 10,
                    }}
                  >
                    <FormControl fullWidth>
                      <RadioGroup
                        aria-labelledby="demo-controlled-radio-buttons-group"
                        name="controlled-radio-buttons-group"
                        // value={value}
                        onChange={(e) => {
                          setSquare([
                            parseInt(e.target.value.split(",")[0]),
                            parseInt(e.target.value.split(",")[1]),
                          ]);
                        }}
                        // onChange={handleChange}
                      >
                        <Grid container spacing={2}>
                          <Grid item xs={12}>
                            <FormControlLabel
                              value={[0, 50]}
                              control={
                                <Radio
                                  checked={square[0] === 0 && square[1] === 50}
                                />
                              }
                              label={
                                <span
                                  style={{ fontSize: 15, whiteSpace: "nowrap" }}
                                >
                                  Dưới 50m<sup>2</sup>
                                </span>
                              }
                            />
                          </Grid>
                          <Grid item xs={12}>
                            <FormControlLabel
                              value={[50, 70]}
                              control={
                                <Radio
                                  checked={square[0] === 50 && square[1] === 70}
                                />
                              }
                              label=<span
                                style={{ fontSize: 15, whiteSpace: "nowrap" }}
                              >
                                Từ 50m<sup>2</sup> - 70m<sup>2</sup>
                              </span>
                            />
                          </Grid>
                          <Grid item xs={12}>
                            <FormControlLabel
                              value={[70, 100]}
                              control={
                                <Radio
                                  checked={
                                    square[0] === 70 && square[1] === 100
                                  }
                                />
                              }
                              label=<span
                                style={{ fontSize: 15, whiteSpace: "nowrap" }}
                              >
                                Từ 70m<sup>2</sup> - 100m<sup>2</sup>
                              </span>
                            />
                          </Grid>
                          <Grid item xs={12}>
                            <FormControlLabel
                              value={[100, 200]}
                              control={
                                <Radio
                                  checked={
                                    square[0] === 100 && square[1] === 200
                                  }
                                />
                              }
                              label=<span
                                style={{ fontSize: 15, whiteSpace: "nowrap" }}
                              >
                                Trên 100m<sup>2</sup>
                              </span>
                            />
                          </Grid>
                        </Grid>
                      </RadioGroup>
                    </FormControl>
                  </div>
                </div>
              </MediaQuery>
              <div
                style={{
                  width: "100%",
                  borderRadius: 10,
                  overflow: "hidden",
                  marginBottom: 12,
                }}
              >
                <div
                  onClick={handleClick}
                  style={{
                    width: "100%",
                    background:
                      "linear-gradient(180deg, rgba(208, 54, 1, 0.74) 0%, rgba(226, 123, 7, 0.67) 100%)",
                    padding: "10px 20px",
                  }}
                >
                  <p
                    style={{
                      fontWeight: 700,
                      fontSize: 14,
                      color: "#fff",
                      textTransform: "uppercase",
                      textAlign: "center",
                      cursor: "pointer",
                    }}
                  >
                    Tìm kiếm
                  </p>
                </div>
              </div>
              {/*  */}
              {resetFilter && 
                <div
                  style={{
                    width: "100%",
                    borderRadius: 10,
                    overflow: "hidden",
                    marginBottom: 12,
                  }}
                >
                  <div
                    onClick={handleResetClick}
                    style={{
                      width: "100%",
                      background:
                        "linear-gradient(180deg, rgba(208, 54, 1, 0.74) 0%, rgba(226, 123, 7, 0.67) 100%)",
                      padding: "10px 20px",
                    }}
                  >
                    <p
                      style={{
                        fontWeight: 700,
                        fontSize: 14,
                        color: "#fff",
                        textTransform: "uppercase",
                        textAlign: "center",
                        cursor: "pointer",
                      }}
                    >
                      Đặt lại bộ lọc
                    </p>
                  </div>
                </div>
              }
            </div>
            {/*  */}
            <div
              style={{
                flex: 1,
                marginLeft: 20,
                maxWidth: "calc(100% - 320px)",
              }}
              className="m-w-100 m-ml-0"
            >
              <Grid container spacing={2} mb={3}>
                {loading === false &&
                  data?.length > 0 &&
                  data?.map((item, key) => (
                    <Grid key={key} item xs={6} sm={4}>
                      <Item w={"w-100"} {...item} />
                    </Grid>
                  ))}
                {loading === false && data?.length <= 0 && (
                  <Grid item xs={12} mt={2}>
                    <div style={{ textAlign: "center", fontSize: 20 }}>
                      Không tìm thấy kết quả trùng khớp, Bạn hãy thử lại
                    </div>
                  </Grid>
                )}
                <div style={{ width: "100%", padding: "0 10px" }}>
                  {loading === true && <LoadingHotel />}
                </div>
              </Grid>
              {data?.length > 0 && (
                <div className="d-flex w-100 flex-row-reverse">
                  <Pagination
                    count={dataPagination?.totalPages}
                    page={currentPage}
                    onChange={handlePageChange}
                    color="primary"
                  />
                </div>
              )}
              <br />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Apartment;

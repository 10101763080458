import { Grid, Skeleton } from "@mui/material";
import React from "react";

const LoadingGuideLine = () => {
  return (
    <div style={{ width: "100%" }}>
      <Grid container spacing={2} mb={3}>
        {
          <Grid item xs={12} mb={3}>
            <Skeleton variant={"rounded"} width={"100%"} height={500} />
          </Grid>
        }
        {Array.from(Array(9).keys())?.map((item, key) => (
          <Grid key={key} item xs={4}>
            <Skeleton variant={"rounded"}  width={"100%"} height={350} />
          </Grid>
        ))}
      </Grid>
    </div>
  );
};

export default LoadingGuideLine;

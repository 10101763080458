import React from "react";

const Contact = () => {
  return (
    <div id="button-contact-vr" className>
      <div id="gom-all-in-one">
        <div id="zalo-vr" className="button-contact">
          <div className="phone-vr">
            <div className="phone-vr-circle-fill" />
            <div className="phone-vr-img-circle">
              <a
                className="zlLink"
                target="_blank"
                href="https://zalo.me/0905490266"
              >
                <img
                  referrerPolicy="no-referrer"
                  alt="Zalo"
                  src="https://auto43.com.vn/upload/icon/zalo-icon.png"
                />
              </a>
            </div>
          </div>
        </div>
        <div id="phone-vr" className="button-contact">
          <div className="phone-vr">
            <div className="phone-vr-circle-fill" />
            <div className="phone-vr-img-circle">
              <a href="tel:0968068138">
                <img
                  referrerPolicy="no-referrer"
                  alt="Phone"
                  src="https://auto43.com.vn/upload/icon/phone-icon.png"
                />
              </a>
            </div>
          </div>
        </div>
        <div id="map-vr" className="button-contact">
          <div className="phone-vr">
            <div className="phone-vr-circle-fill" />
            <div className="phone-vr-img-circle">
              <a target="_blank" href="https://m.me/203131802878350">
                <img
                  alt="google map"
                  src="https://upload.wikimedia.org/wikipedia/commons/thumb/b/be/Facebook_Messenger_logo_2020.svg/2048px-Facebook_Messenger_logo_2020.svg.png"
                />
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contact;

import React, { useEffect, useState } from "react";
import Banner3 from "../../../assets/manage/banner3.png";
import { Grid } from "@mui/material";
import Img1 from "../../../assets/apartment/img1.png";
import MediaQuery from "react-responsive";
import DealHot from "../../DealHot/DealHot";
import { apiGetBlogType, apiGetDetailBlogType } from "../../../api";
import { FaCalendarAlt } from "react-icons/fa";
import moment from "moment";
import { FaPen } from "react-icons/fa6";
import B1Image from "./image3/b1.png"
import B2Image from "./image3/b2.png"
import B3Image from "./image3/b3.png"
import { Image, Swiper } from "react-nivo-slider";
import { setting2 } from "../home/Banner";
import RenderDangerHtml from "../../RenderDangerHtml/RenderDangerHtml";
import { Helmet } from "react-helmet";

const ManageDepartment = () => {
  const type = 9;
  const [data, setData] = useState();
  useEffect(() => {
    (async () => {
      const result = await apiGetDetailBlogType({ type });
      setData(result?.data?.[0]);
    })();
  }, []);
  return (
    <div style={{ width: "100%" }}>
      <Helmet>
        <title>Quản lý vận hành văn phòng</title>
        <link rel="canonical" href={window.location.origin + window.location.pathname} />
        <meta
          data-react-helmet="true"
          property="og:url"
          content={window.location.origin + window.location.pathname}
        ></meta>
      </Helmet>
      <div
        style={{ width: "100%", position: "relative" }}
        className="banner-intro c-flex-center"
      >
        <Swiper {...setting2}>
          <Image src={B1Image} alt="image1" />
          <Image src={B2Image} alt="image2" />
          <Image src={B3Image} alt="image3" />
        </Swiper>
      </div>
      <div
        style={{ width: "100%", margin: "24px 0" }}
        className="c-flex-center"
      >
        <div style={{ width: "100%", maxWidth: 1248 }}>
          <div style={{ textAlign: "center", margin: "24px 0" }}></div>

          {/* {loading === true && <LoadingSkeletonBlog1 />} */}
          {/*  */}
          <div
            style={{ width: "100%", display: "flex" }}
            className="m-flex-column"
          >
            <div style={{ flex: 1 }}>
              <div
                style={{
                  borderTopLeftRadius: 10,
                  borderTopRightRadius: 10,
                  boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
                  padding: 10,
                }}
              >
                <div
                  style={{
                    background:
                      "linear-gradient(180deg, #D03601 0%, #E27B07 100%)",
                    backgroundClip: "text",
                    color: "transparent",
                    fontSize: 24,
                    fontWeight: 600,
                    textTransform: "uppercase",
                  }}
                >
                  {data?.name}
                </div>
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    gap: 20,
                  }}
                >
                  <div
                    className="c-flex-center"
                    style={{ width: "max-content", gap: 10 }}
                  >
                    <FaCalendarAlt />{" "}
                    {moment(data?.time_created).format("DD/MM/YYYY")}
                  </div>
                  <div>-</div>
                  <div
                    className="c-flex-center"
                    style={{ width: "max-content", gap: 10 }}
                  >
                    <FaPen /> {data?.author ? data?.author?.replace("null", "") : "Unknown"}
                  </div>
                </div>
              </div>
              <div
                style={{
                  width: "100%",
                  background: "#F8F8F8",
                  padding: 30,
                  boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
                  borderBottomLeftRadius: 10,
                  borderBottomRightRadius: 10,
                }}
              >
                <RenderDangerHtml data={data?.content} />
                {/* <div dangerouslySetInnerHTML={{ __html: data?.content }}></div> */}
              </div>
            </div>
            <div
              className="wrap-dh-blog"
              style={{ width: 300, paddingLeft: 20 }}
            >
              <DealHot />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ManageDepartment;

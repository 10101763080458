import React, { useEffect, useState } from "react";
import Banner from "../assets/tour/banner.png";
import { apiGetBlogType, apiGetProvince, apiGetTicket, apiGetWard, getListProvince } from "../api";
import { Grid, Pagination, Slider } from "@mui/material";
import ItemTicket from "../components/Tickets/ItemTickets";
import TicketImage1 from "../assets/ticket/ticket1.png";
import TicketImage2 from "../assets/ticket/ticket2.png";
import LoadingTicket from "../components/Loading/LoadingTicket";
import ScrollToTop from "../utils/ScrollToTop";
import MediaQuery from "react-responsive";
import LoadingTour from "../components/Loading/LoadingTour";
import ItemTour from "../components/page/Tour/ItemTour";
import { FaArrowDown } from "react-icons/fa6";
import { MAX_PRICE_FILTER, MAX_PRICE_FILTER_HOTEL, MIN_PRICE_FILTER, MIN_PRICE_FILTER_HOTEL, STEP_FILTER, STEP_FILTER_HOTEL } from "../config/constant";
import SelectBox3 from "../components/util/SelectBox/SelectBox3";
import { valuetext } from "../components/page/Hotel/Hotel";
import numberWithDot from "../utils/numberWithDot";
import SelectBox4 from "../components/util/SelectBox/SelectBox4";

import { Helmet, HelmetData } from "react-helmet-async";
const helmetData = new HelmetData({});
const VeMienBac = () => {
  const [type, setType] = useState(1);
  const [data, setData] = useState([]);
  const [rangePrice, setRangePrice] = useState([
    MIN_PRICE_FILTER_HOTEL,
    MAX_PRICE_FILTER_HOTEL,
  ]);
  const [listProvince, setListProvince] = useState([]);
  const [listWard, setListWard] = useState([]);
  const [province, setProvince] = useState();
  const [district, setDistrict] = useState();
  const [typeRoom, setTypeRoom] = useState();
  const [ward, setWard] = useState();
  const [provinceDetail, setProvinceDetail] = useState();
  const [itemPage, setItemPage] = useState(12);
  const [currentPage, setCurrentPage] = useState(1);
  const handlePageChange = (event, value) => {
    setCurrentPage(value);
  };
  const getprovince = async (code) => {
    const response = await apiGetProvince(code);
    setProvinceDetail(response?.results);
  };

  const getdistrict = async (code) => {
    const response = await apiGetWard(code);
    setListWard(response?.results);
  };

  useEffect(() => {
    if (province) getprovince(province);
    if (district) getdistrict(district);
  }, [province, district]);
  useEffect(() => {
    (async () => {
      const result = await getListProvince();
      setListProvince(result);
    })();
  }, []);
  const handleChangePrice = (event, newValue) => {
    setRangePrice(newValue);
  };
  const [loading, setLoading] = useState();
  const handleClick = async () => {
    window.scrollTo(0, 0);
    const data = {
      minBudget: rangePrice[0],
      maxBudget: rangePrice[1],
      type,
    };
    try {
      setLoading(true);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    (async () => {
      try {
        setLoading(true);
        const result = await apiGetTicket({isFrom: "client"});
        setLoading(false);
        if (result?.ok === true) {
          setData(result?.data);
        }
      } catch (error) {
        setLoading(false);
        console.log(error);
      } finally {
        setLoading(false);
      }
    })();
  }, [type]);

  return (
    <div
      style={{
        width: "100%",
      }}
    >
      <Helmet>
        <title>Vé miền bắc</title>
        <link rel="canonical" href={window.location.origin + window.location.pathname} />
        <meta
          data-react-helmet="true"
          property="og:url"
          content={window.location.origin + window.location.pathname}
        ></meta>
      </Helmet>
      <ScrollToTop />
      <div
        className="banner-hotel"
        style={{ width: "100%", position: "relative" }}
      >
        <img
          src={Banner}
          alt=""
          style={{ width: "100%", aspectRatio: 5 / 1.5 }}
        />
        <div
          style={{
            position: "absolute",
            left: "50%",
            top: "48%",
            transform: "translate(-50%, -50%)",
          }}
        >
        </div>
      </div>
      <div style={{ width: "100%" }} className="p-10">
        <div style={{ width: "100%", marginTop: 50 }} className="c-flex-center">
          <div style={{ width: 1248 }}>
            <div style={{ width: "100%", display: "flex" }} className="sahjkhdjksaksas">
              <div className="m-w-100" style={{ width: 300, marginTop: 12 }}>
                <div
                  style={{
                    fontSize: 20,
                    fontWeight: 700,
                    color: "#E27B07",
                    textDecoration: "none",
                    marginBottom: 12,
                  }}
                >
                  Bộ lọc tìm kiếm
                </div>
                <div
                  style={{
                    width: "100%",
                    borderRadius: 10,
                    overflow: "hidden",
                    marginBottom: 12,
                  }}
                >
                  <div
                    style={{
                      width: "100%",
                      background:
                        "linear-gradient(180deg, rgba(208, 54, 1, 0.74) 0%, rgba(226, 123, 7, 0.67) 100%)",
                      padding: "10px 20px",
                    }}
                  >
                    <p
                      style={{
                        fontWeight: 700,
                        fontSize: 14,
                        color: "#fff",
                        textTransform: "uppercase",
                        textAlign: "center"
                      }}
                    >
                      Địa điểm
                    </p>
                  </div>
                  <div
                  style={{
                    width: "100%",
                    backgroundColor: "#F0F0F0",
                    padding: 10,
                  }}
                >
                  <div
                    style={{
                      width: "100%",
                      borderRadius: 10,
                      border: "1px solid #e7e7e7",
                      background: "#fff",
                      padding: 10,
                      marginBottom: 12,
                    }}
                  >
                    <SelectBox3
                      size={"small"}
                      value={province}
                      setValue={setProvince}
                      label={"Tỉnh / Thành phố"}
                      list={listProvince?.map((item) => ({
                        ...item,
                        value: item.province_id,
                        label: item.province_name,
                      }))}
                    />
                  </div>
                  <div
                    style={{
                      width: "100%",
                      borderRadius: 10,
                      border: "1px solid #e7e7e7",
                      background: "#fff",
                      padding: 10,
                      marginBottom: 12,
                    }}
                  >
                    <p style={{ fontSize: 12, color: "#989898" }}>
                      <SelectBox3
                        size={"small"}
                        value={district}
                        setValue={setDistrict}
                        label={"Quận / Huyện"}
                        list={provinceDetail?.map((el) => ({
                          ...el,
                          value: el.district_id,
                          label: el.district_name,
                        }))}
                      />
                    </p>
                  </div>
                  <div
                    style={{
                      width: "100%",
                      borderRadius: 10,
                      border: "1px solid #e7e7e7",
                      background: "#fff",
                      padding: 10,
                      marginBottom: 12,
                    }}
                  >
                    <SelectBox3
                      size={"small"}
                      value={ward}
                      setValue={setWard}
                      label={"Xã / Phường"}
                      list={listWard?.map((el) => ({
                        ...el,
                        value: el.ward_id,
                        label: el.ward_name,
                      }))}
                    />
                  </div>
                  {/*  */}
                </div>
                </div>
                {/*  */}
                {/*  */}
                <div
                  style={{
                    width: "100%",
                    borderRadius: 10,
                    marginBottom: 12,
                  }}
                >
                  <div
                    style={{
                      width: "100%",
                      background:
                        "linear-gradient(180deg, rgba(208, 54, 1, 0.74) 0%, rgba(226, 123, 7, 0.67) 100%)",
                      padding: "10px 20px",borderTopLeftRadius: 10,borderTopRightRadius: 10
                    }}
                  >
                    <p
                      style={{
                        fontWeight: 700,
                        fontSize: 14,
                        color: "#fff",
                        textTransform: "uppercase",
                        textAlign: "center"
                      }}
                    >
                      Ngân sách ( VND )
                    </p>
                  </div>
                  <div
                    style={{
                      width: "100%",
                      backgroundColor: "#F0F0F0",
                      padding: 10,borderBottomLeftRadius: 10, borderBottomRightRadius: 10
                    }}
                  >
                    <div style={{ width: "100%", marginBottom: 24 }}>
                      <Slider
                        getAriaLabel={() => "Ngân sách"}
                        value={rangePrice}
                        onChange={handleChangePrice}
                        valueLabelDisplay="auto"
                        getAriaValueText={valuetext}
                        valueLabelFormat={valuetext}
                        min={MIN_PRICE_FILTER_HOTEL}
                        max={MAX_PRICE_FILTER_HOTEL}
                        step={STEP_FILTER_HOTEL}
                        color="warning"
                      />
                      <div
                        style={{
                          width: "100%",
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                          marginTop: 10,
                        }}
                      >
                        <div
                          style={{
                            background: "#E5E5E5",
                            borderRadius: 80,
                            fontSize: 14,
                            padding: 5,
                            minWidth: 20,
                          }}
                          className="c-flex-center"
                        >
                          <p>{numberWithDot(MIN_PRICE_FILTER_HOTEL)}</p>
                        </div>
                        <div
                          style={{
                            background: "#E5E5E5",
                            borderRadius: 80,
                            fontSize: 14,
                            padding: 5,
                            minWidth: 20,
                          }}
                          className="c-flex-center"
                        >
                          <p>{numberWithDot(MAX_PRICE_FILTER_HOTEL)}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  style={{
                    width: "100%",
                    borderRadius: 10,
                    overflow: "hidden",
                    marginBottom: 12,
                  }}
                >
                  <div
                    onClick={handleClick}
                    style={{
                      width: "100%",
                      background:
                        "linear-gradient(180deg, rgba(208, 54, 1, 0.74) 0%, rgba(226, 123, 7, 0.67) 100%)",
                      padding: "10px 20px",
                    }}
                  >
                    <p
                      style={{
                        fontWeight: 700,
                        fontSize: 14,
                        color: "#fff",
                        textTransform: "uppercase",
                        textAlign: "center",
                        cursor: "pointer",
                      }}
                    >
                      Tìm kiếm
                    </p>
                  </div>
                </div>
              </div>
              <div
                style={{
                  flex: 1,
                  paddingLeft: 20,
                  position: "relative",
                  maxWidth: "calc(100% - 300px)",
                }}
                className="wrap-tour-travel-list m-w-100 m-pl-0"
              >
                <MediaQuery minWidth={625}>
                  <div
                    style={{
                      width: "100%",
                      position: "relative",
                      height: 60,
                    }}
                  >
                    <div
                      style={{
                        position: "absolute",
                        width: "100%",
                        top: "-100%",
                      }}
                    >
                    </div>
                  </div>
                </MediaQuery>
                <div
                  className="m-t-0 m-pd-0"
                  style={{
                    width: "100%",
                    position: "relative",
                    top: "-56px",
                    marginTop: 24,
                    padding: "0 20px 20px 20px",
                    borderBottomRightRadius: 10,
                    borderBottomLeftRadius: 10,
                  }}
                >
                  <div style={{ width: "100%" }}>
                    {loading === false && (
                      <>
                        <div className="title-list tt-uc tx-20">
                          Vé tham quan miền Bắc
                        </div>
                        <Grid container spacing={2}>
                          {loading === false &&
                            data
                              ?.filter((item) => item?.type === 1)?.slice(
                            (currentPage - 1) * itemPage,
                            (currentPage - 1) * itemPage + itemPage
                          )
                              ?.map((item, key) => (
                                <Grid key={key} item xs={6} sm={4} mt={3}>
                                  <ItemTicket {...item} />
                                </Grid>
                              ))}
                          {data?.filter((item) => item?.type === 1)?.length <=
                            0 && (
                            <Grid item xs={12} mt={2}>
                              <div
                                style={{ textAlign: "center", fontSize: 20 }}
                              >
                                Không tìm thấy kết quả trùng khớp, Bạn hãy thử
                                lại
                              </div>
                            </Grid>
                          )}
                        </Grid>
                      </>
                    )}
                    {loading === true && <LoadingTour />}
                    {loading === false && data?.length <= 0 && (
                      <Grid item xs={12} mt={2}>
                        <div style={{ textAlign: "center", fontSize: 20 }}>
                          Không tìm thấy kết quả trùng khớp, Bạn hãy thử lại
                        </div>
                      </Grid>
                    )}
                    {data?.filter((item) => item?.type === 1)?.length > 0 && (
                      <div className="d-flex w-100 flex-row-reverse">
                        <Pagination
                          count={Math.ceil(data?.filter((item) => item?.type === 1).length / itemPage)}
                          page={currentPage === 0 ? 1 : currentPage}
                          onChange={handlePageChange}
                          color="primary"
                        />
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div></div>
        </div>
      </div>
    </div>
  );
};

export default VeMienBac;

import {
  BrowserRouter as Router,
} from "react-router-dom";

import { useEffect } from "react";
import Aos from "aos";
import WrapApp from "./WrapApp";
import TranslationSelector from "./components/Translation/Translation";
import { ErrorBoundary } from "./components/page/Tour/ItemTour";
const App = () => {
  
  useEffect(() => {
    Aos.init({
      duration: 2000,
      delay: 0,
    });
  }, []);
  return (
    <ErrorBoundary>
      <Router>
        <WrapApp />
      </Router>
      <TranslationSelector />
    </ErrorBoundary>
  );
};

export default App;

import React, { useEffect, useState } from "react";
import { useLocation, useSearchParams } from "react-router-dom";
import {
  apiGetProvince,
  apiGetWard,
  apiSearchResult,
  getListProvince,
} from "../api";
import SelectBox3 from "../components/util/SelectBox/SelectBox3";
import { Grid, Slider } from "@mui/material";
import { valuetext } from "../components/page/Hotel/Hotel";
import Item from "../components/Product/Item";
import ItemTour from "../components/page/Tour/ItemTour";
import AdvisePopupTrigger from "../components/Advise/AdviseTrigger";
import LoadingSkeletonSearch from "../components/Loading/LoadingSkeletonSearch";
import ScrollToTop from "../utils/ScrollToTop";
import PaginationControlled from "../utils/Pagination";
import { MAX_PRICE_FILTER, MIN_PRICE_FILTER, OFF_SET, STEP_FILTER } from "../config/constant";
import numberWithDot from "../utils/numberWithDot";

const Search = () => {
  const [page, setPage] = useState();
  const location = useLocation();
  const [open, setOpen] = useState(false);
  const [data, setData] = useState({ count: 0, data: [] });
  const [error, setError] = useState();
  const [loading, setLoading] = useState();
  const [rangePrice, setRangePrice] = useState([MIN_PRICE_FILTER, MAX_PRICE_FILTER]);
  const [listProvince, setListProvince] = useState([]);
  const [listWard, setListWard] = useState([]);
  const [province, setProvince] = useState();
  const [district, setDistrict] = useState();
  const [ward, setWard] = useState();
  const [star, setStar] = useState();
  const [seat, setSeat] = useState();
  const [provinceDetail, setProvinceDetail] = useState();
  const [searchParams, setSearchParams] = useSearchParams();
  const handleChangePrice = (event, newValue) => {
    setRangePrice(newValue);
  };
  const getprovince = async (code) => {
    const response = await apiGetProvince(code);
    setProvinceDetail(response?.results);
  };

  const getdistrict = async (code) => {
    const response = await apiGetWard(code);
    setListWard(response?.results);
  };

  useEffect(() => {
    if (province) getprovince(province);
    if (district) getdistrict(district);
  }, [province, district]);
  useEffect(() => {
    (async () => {
      const result = await getListProvince();
      setListProvince(result);
    })();
  }, []);
  useEffect(() => {
    (async () => {
      try {
        setLoading(true);
        const searchParams = new URLSearchParams(location.search);
        const params = {};
        for (const [key, value] of searchParams.entries()) {
          params[key] = value;
        }
        const result = await apiSearchResult({ ...params });
        setLoading(false);
        setData(result);
      } catch (error) {
        setError(true);
      }
    })();
  }, [location]);

  const searchButton = async () => {
    try {
      setLoading(true);
      const searchParams = new URLSearchParams(location.search);
      const params = {};

      for (const [key, value] of searchParams.entries()) {
        params[key] = value;
      }
      const result = await apiSearchResult({
        ...params,
        minBudget: rangePrice[0],
        maxBudget: rangePrice[1],
      });
      setLoading(false);
      setData(result);
    } catch (error) {
      setError(true);
    }
  };

  useEffect(() => {
    if (parseInt(searchParams.get("typeBooking")) === 4) {
      setOpen(true);
    }
  }, [searchParams]);

  React.useEffect(() => {
    if (parseInt(searchParams.get("page")) > 1) {
      setPage(parseInt(searchParams.get("page")));
    } else {
      setPage(0);
    }
  }, [searchParams]);

  return (
    <div
      className="container-search"
      style={{
        width: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <ScrollToTop />
      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div className="main-com-search p-10" style={{ width: "100%", maxWidth: 1248, display: "flex" }}>
          <div className="search-section-1" style={{ width: 300 }}>
            <div
              style={{
                fontSize: 20,
                fontWeight: 700,
                color: "#E27B07",
                textDecoration: "none",
                marginBottom: 12,
              }}
            >
              Bộ lọc tìm kiếm
            </div>
            <div
              style={{
                width: "100%",
                borderRadius: 10,
                overflow: "hidden",
                marginBottom: 12,
              }}
            >
              <div
                style={{
                  width: "100%",
                  background:
                    "linear-gradient(180deg, rgba(208, 54, 1, 0.74) 0%, rgba(226, 123, 7, 0.67) 100%)",
                  padding: "10px 20px",
                }}
              >
                <p
                  style={{
                    fontWeight: 700,
                    fontSize: 14,
                    color: "#fff",
                    textTransform: "uppercase",
                  }}
                >
                  Địa điểm, vị trí
                </p>
              </div>
              <div
                style={{
                  width: "100%",
                  backgroundColor: "#F0F0F0",
                  padding: 10,
                }}
              >
                <div
                  style={{
                    width: "100%",
                    borderRadius: 10,
                    border: "1px solid #e7e7e7",
                    background: "#fff",
                    padding: 10,
                    marginBottom: 12,
                  }}
                >
                  <SelectBox3
                    size={"small"}
                    value={province}
                    setValue={setProvince}
                    label={"Tỉnh / Thành phố"}
                    list={listProvince?.map((item) => ({
                      ...item,
                      value: item.province_id,
                      label: item.province_name,
                    }))}
                  />
                </div>
                <div
                  style={{
                    width: "100%",
                    borderRadius: 10,
                    border: "1px solid #e7e7e7",
                    background: "#fff",
                    padding: 10,
                    marginBottom: 12,
                  }}
                >
                  <p style={{ fontSize: 12, color: "#989898" }}>
                    <SelectBox3
                      size={"small"}
                      value={district}
                      setValue={setDistrict}
                      label={"Quận / Huyện"}
                      list={provinceDetail?.map((el) => ({
                        ...el,
                        value: el.district_id,
                        label: el.district_name,
                      }))}
                    />
                  </p>
                </div>
                <div
                  style={{
                    width: "100%",
                    borderRadius: 10,
                    border: "1px solid #e7e7e7",
                    background: "#fff",
                    padding: 10,
                    marginBottom: 12,
                  }}
                >
                  <SelectBox3
                    size={"small"}
                    value={ward}
                    setValue={setWard}
                    label={"Xã / Phường"}
                    list={listWard?.map((el) => ({
                      ...el,
                      value: el.ward_id,
                      label: el.ward_name,
                    }))}
                  />
                </div>
              </div>
            </div>
            {/*  */}
            <div
              style={{
                width: "100%",
                borderRadius: 10,
                overflow: "hidden",
                marginBottom: 12,
              }}
            >
              <div
                style={{
                  width: "100%",
                  background:
                    "linear-gradient(180deg, rgba(208, 54, 1, 0.74) 0%, rgba(226, 123, 7, 0.67) 100%)",
                  padding: "10px 20px",
                }}
              >
                <p
                  style={{
                    fontWeight: 700,
                    fontSize: 14,
                    color: "#fff",
                    textTransform: "uppercase",
                  }}
                >
                  Ngân sách ( VND )
                </p>
              </div>
              <div
                style={{
                  width: "100%",
                  backgroundColor: "#F0F0F0",
                  padding: 10,
                }}
              >
                <Slider
                  getAriaLabel={() => "Ngân sách"}
                  value={rangePrice}
                  onChange={handleChangePrice}
                  valueLabelDisplay="auto"
                  getAriaValueText={valuetext}
                  valueLabelFormat={valuetext}
                  min={MIN_PRICE_FILTER}
                  step={STEP_FILTER}
                  max={MAX_PRICE_FILTER}
                  color="warning"
                />
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    marginTop: 10,
                  }}
                >
                  <div
                    style={{
                      background: "#E5E5E5",
                      borderRadius: 80,
                      fontSize: 14,
                      padding: 5,
                      minWidth: 20,
                    }}
                    className="c-flex-center"
                  >
                    <p>{MIN_PRICE_FILTER}</p>
                  </div>
                  <div
                    style={{
                      background: "#E5E5E5",
                      borderRadius: 80,
                      fontSize: 14,
                      padding: 5,
                      minWidth: 20,
                    }}
                    className="c-flex-center"
                  >
                    <p>{numberWithDot(MAX_PRICE_FILTER)}</p>
                  </div>
                </div>
              </div>
            </div>
            <div
              style={{
                width: "100%",
                borderRadius: 10,
                overflow: "hidden",
                marginBottom: 12,
              }}
            >
              <div
                style={{
                  width: "100%",
                  background:
                    "linear-gradient(180deg, rgba(208, 54, 1, 0.74) 0%, rgba(226, 123, 7, 0.67) 100%)",
                  padding: "10px 20px",
                }}
                onClick={searchButton}
              >
                <p
                  style={{
                    fontWeight: 700,
                    fontSize: 14,
                    color: "#fff",
                    textTransform: "uppercase",
                    textAlign: "center",
                    cursor: "pointer",
                  }}
                >
                  Tìm kiếm
                </p>
              </div>
            </div>
          </div>
          <div
            className="search-com-2"
            style={{ flex: 1, marginLeft: 20, maxWidth: "calc(100% - 320px)" }}
          >
            <Grid container spacing={2} mb={3}>
              {loading === false &&
                data?.data?.length > 0 &&
                data?.data?.slice(page, page + OFF_SET)?.map((item, key) => (
                  <Grid key={key} item xs={6} sm={4} mt={2}>
                    {(parseInt(searchParams.get("typeBooking")) === 1 ||
                      parseInt(searchParams.get("typeBooking")) === 2) && (
                      <Item hotel={parseInt(searchParams.get("typeBooking"))=== 2 ? true : false} w={"w-100"} {...item} />
                    )}
                    {parseInt(searchParams.get("typeBooking")) === 3 && (
                      <ItemTour w={"w-100"} {...item} />
                    )}
                  </Grid>
                ))}
              {loading === false && data?.data?.length <= 0 && (
                <Grid item xs={12} mt={2}>
                  <div style={{ textAlign: "center", fontSize: 20 }}>
                    Không tìm thấy kết quả trùng khớp, Bạn hãy thử lại
                  </div>
                </Grid>
              )}
            </Grid>
            {loading === true && <LoadingSkeletonSearch />}
            {<div style={{ width: "100%" }}></div>}
            {Math.ceil(data?.count / OFF_SET) > 1 && (
              <PaginationControlled count={Math.ceil(data?.count / OFF_SET)} />
            )}
          </div>
        </div>
      </div>
      <AdvisePopupTrigger open={open} setOpen={setOpen} />
    </div>
  );
};

export default Search;

import * as React from 'react';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

export default function SelectBox4(props) {

  const handleChange = (event) => {
    props?.setValue(event.target.value);
    if(props?.type) {
      props?.setType()
    }
  };
  const handleType= (minBudget, maxBudget)=> {
    props?.handleRange([minBudget, maxBudget])
  }

  return (
    <Box sx={{ minWidth: 120 }}>
      <FormControl size={props?.size ? props?.size : "small"} fullWidth>
        <InputLabel id="demo-simple-select-label" style={{background: "#fff"}}>{props?.label}</InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={props?.value}
          label={props?.value}
          onChange={handleChange}
          MenuProps={{disableScrollLock: true}}
          sx={{
              '& .MuiOutlinedInput-notchedOutline': {
                  borderColor: '#e27b07'
              },
              '& .MuiSvgIcon-root': {
                  color: '#e27b07'
              }
          }}
        >
          {props?.list?.map((item, key)=> 
            <MenuItem onClick={()=> handleType(item?.minBudget, item?.maxBudget)} key={key} value={item.value}>{item.label}</MenuItem>
          )}
        </Select>
      </FormControl>
    </Box>
  );
}
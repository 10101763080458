import React, { useEffect, useState } from "react";
import Banner12 from "../assets/tour/banner12.png";
import Banner13 from "../assets/tour/banner13.png";
import SortAsc from "../assets/tour/sort_asc.png";
import SortDes from "../assets/tour/sort_des.png";
import { Grid, Pagination, Slider } from "@mui/material";
// import ItemImage from "../../../assets/apartment/item.png";
import {
  apiGetListTour,
  apiGetProvince,
  apiGetTourCategory,
  apiGetWard,
  apiSearchResult,
  getListProvince,
} from "../api";
import _ from "lodash";
// import useScrollToTop from "../../hooks/useScrollToTop";
import {
  MAX_PRICE_FILTER,
  MIN_PRICE_FILTER,
  STEP_FILTER,
} from "../config/constant";
import { FaArrowUp } from "react-icons/fa6";
import { FaArrowDown } from "react-icons/fa6";
import numberWithDot from "../utils/numberWithDot";
import MediaQuery from "react-responsive";
import ItemTour from "../components/page/Tour/ItemTour";
import LoadingTour from "../components/Loading/LoadingTour";
import SelectBox3 from "../components/util/SelectBox/SelectBox3";
import { valuetext } from "../components/page/Hotel/Hotel";
import PopularTour from "../components/PopularTour/PopularTour";
import { Image, Swiper } from "react-nivo-slider";
import { setting2 } from "../components/page/home/Banner";
import B1Image from "./image4/b1.png";
import B2Image from "./image4/b2.png";
import B3Image from "./image4/b3.png";
import { Link, useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";

const TourMienTrung = (props) => {
  const navigate= useNavigate()
  const { type } = props;
  const [typeBooking, setTypeBooking] = useState(3);
  const [rangePrice, setRangePrice] = useState([
    MIN_PRICE_FILTER,
    MAX_PRICE_FILTER,
  ]);
  const [originData, setOriginData]= useState([])
  const [data, setData] = useState([]);
  const [listProvince, setListProvince] = useState([]);
  const [listWard, setListWard] = useState([]);
  const [province, setProvince] = useState();
  const [district, setDistrict] = useState();
  const [departure, setDeparture] = useState();
  const [destination, setDestination] = useState();
  const [provinceDetail, setProvinceDetail] = useState();
  const [selectedDay, setSelectedDay] = useState();
  const [loading, setLoading] = useState();
  const [itemPage, setItemPage] = useState(12);
  const [currentPage, setCurrentPage] = useState(1);
  const handlePageChange = (event, value) => {
    setCurrentPage(value);
  };
  const getprovince = async (code) => {
    const response = await apiGetProvince(code);
    setProvinceDetail(response?.results);
  };

  const getdistrict = async (code) => {
    const response = await apiGetWard(code);
    setListWard(response?.results);
  };

  const sortData = (type) => {
    // sort asc
    if (type === 1) {
      setData(
        _.orderBy(data, function (e) {
          return parseInt(e.price);
        })
      );
    } else if (type === 2) {
      setData(
        _.orderBy(
          data,
          function (e) {
            return parseInt(e.price);
          },
          ["desc"]
        )
      );
    }
  };

  const handleClick = async () => {
    window.scrollTo(0, 0);
    const data = {
      typeBooking,
      departurePoint: departure,
      destinationPoint: destination,
      minBudget: rangePrice[0],
      maxBudget: rangePrice[1],
      type,
    };
    try {
      setLoading(true);
      const result = originData?.filter(item=> Math.ceil((parseInt(item.price) * (100 - (item.discountPer || 0)) / 100)) >= rangePrice[0] && Math.ceil((parseInt(item.price) * (100 - (item.discountPer || 0)) / 100)) <= rangePrice[1]);
      setLoading(false);
      setData(result);
    } catch (error) {
      setLoading(false);
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (province) getprovince(province);
    if (district) getdistrict(district);
  }, [province, district]);
  useEffect(() => {
    (async () => {
      const result = await getListProvince();
      setListProvince(result);
    })();
  }, []);

  useEffect(() => {
    (async () => {
      try {
        setLoading(true);
        const result = await apiGetListTour();
        setLoading(false);
        if (result?.ok === true) {
          setData(result?.data);
          setOriginData(result?.data)
        }
      } catch (error) {
        setLoading(false);
        console.log(error);
      } finally {
        setLoading(false);
      }
    })();
  }, [type]);

  const handleChangePrice = (event, newValue) => {
    setRangePrice(newValue);
  };
  return (
    <div
      style={{
        width: "100%",
      }}
    >
      <Helmet>
        <title>Tour miền trung</title>
        <link rel="canonical" href={window.location.origin + window.location.pathname} />
        <meta
          data-react-helmet="true"
          property="og:url"
          content={window.location.origin + window.location.pathname}
        ></meta>
      </Helmet>
      <div
        className="banner-hotel"
        style={{ width: "100%", position: "relative" }}
      >
        {props?.type === 1 && (
          <Swiper {...setting2}>
            <Image src={B1Image} alt="image1" />
            <Image src={B2Image} alt="image2" />
            <Image src={B3Image} alt="image3" />
          </Swiper>
        )}
        {props?.type === 2 && (
          <Swiper {...setting2}>
            <Image src={B1Image} alt="image1" />
            <Image src={B2Image} alt="image2" />
            <Image src={B3Image} alt="image3" />
          </Swiper>
        )}
        <div
          style={{
            position: "absolute",
            left: "50%",
            top: "48%",
            transform: "translate(-50%, -50%)",
          }}
        >
          {/* <p
            style={{
              fontSize: 64,
              fontWeight: 700,
              color: "#fff",
              textTransform: "uppercase",
              whiteSpace: "nowrap",
            }}
            className="tour-title-font-w m-fz-32 m-ws"
          >
            {props?.type === 1 && "Tour daily"}
            {props?.type === 2 && "Tour trong nước ngoài nước"}
          </p> */}
        </div>
      </div>
      <div style={{ width: "100%" }} className="c-flex-center">
        <div
          style={{
            width: "100%",
            maxWidth: 1248,
            display: "flex",
            marginBottom: 40,
          }}
          className="m-flex-column p-10"
        >
          <div className="m-w-100" style={{ width: 300, marginTop: 12 }}>
            <div
              style={{
                fontSize: 20,
                fontWeight: 700,
                color: "#E27B07",
                textDecoration: "none",
                marginBottom: 12,
              }}
            >
              Bộ lọc tìm kiếm
            </div>
            <div
              style={{
                width: "100%",
                borderRadius: 10,
                overflow: "hidden",
                marginBottom: 12,
              }}
            >
              <div
                style={{
                  width: "100%",
                  background:
                    "linear-gradient(180deg, rgba(208, 54, 1, 0.74) 0%, rgba(226, 123, 7, 0.67) 100%)",
                  padding: "10px 20px",
                }}
              >
                <p
                  style={{
                    fontWeight: 700,
                    fontSize: 14,
                    color: "#fff",
                    textTransform: "uppercase",
                  }}
                >
                  Địa điểm, vị trí
                </p>
              </div>
              <div
                style={{
                  width: "100%",
                  backgroundColor: "#F0F0F0",
                  padding: 10,
                }}
              >
                <div
                  style={{
                    width: "100%",
                    borderRadius: 10,
                    border: "1px solid #e7e7e7",
                    background: "#fff",
                    padding: 10,
                    marginBottom: 12,
                  }}
                >
                  <SelectBox3
                    size={"small"}
                    value={departure}
                    setValue={setDeparture}
                    label={"Điểm đi"}
                    list={listProvince?.map((item) => ({
                      ...item,
                      value: item.province_id,
                      label: item.province_name,
                    }))}
                  />
                </div>
                <div
                  style={{
                    width: "100%",
                    borderRadius: 10,
                    border: "1px solid #e7e7e7",
                    background: "#fff",
                    padding: 10,
                    marginBottom: 12,
                  }}
                >
                  <SelectBox3
                    size={"small"}
                    value={destination}
                    setValue={setDestination}
                    label={"Điểm đến"}
                    list={listProvince?.map((item) => ({
                      ...item,
                      value: item.province_id,
                      label: item.province_name,
                    }))}
                  />
                </div>
              </div>
            </div>
            {/*  */}
            <div
              style={{
                width: "100%",
                borderRadius: 10,
                overflow: "hidden",
                marginBottom: 12,
              }}
            >
              <div
                style={{
                  width: "100%",
                  background:
                    "linear-gradient(180deg, rgba(208, 54, 1, 0.74) 0%, rgba(226, 123, 7, 0.67) 100%)",
                  padding: "10px 20px",
                }}
              >
                <p
                  style={{
                    fontWeight: 700,
                    fontSize: 14,
                    color: "#fff",
                    textTransform: "uppercase",
                  }}
                >
                  Số ngày
                </p>
              </div>
              <div
                style={{
                  width: "100%",
                  backgroundColor: "#F0F0F0",
                  padding: 10,
                }}
              >
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    flexWrap: "wrap",
                  }}
                >
                  <div
                    onClick={() => setSelectedDay(1)}
                    style={{
                      width: "45%",
                      height: 40,
                      borderRadius: 10,
                      boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
                      marginBottom: 12,
                      background:
                        selectedDay === 1
                          ? "linear-gradient(rgba(208, 54, 1, 0.74) 0%, rgba(226, 123, 7, 0.67) 100%)"
                          : "#fff",
                      cursor: "pointer",
                    }}
                    className="c-flex-center"
                  >
                    <p
                      style={{
                        fontWeight: 700,
                        fontSize: 13,
                        color: selectedDay === 1 ? "#fff" : "#000",
                      }}
                    >
                      1-3 ngày
                    </p>
                  </div>
                  <div
                    onClick={() => setSelectedDay(2)}
                    style={{
                      width: "45%",
                      height: 40,
                      borderRadius: 10,
                      boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
                      background:
                        selectedDay === 2
                          ? "linear-gradient(rgba(208, 54, 1, 0.74) 0%, rgba(226, 123, 7, 0.67) 100%)"
                          : "#fff",
                      marginBottom: 12,
                      cursor: "pointer",
                    }}
                    className="c-flex-center"
                  >
                    <p
                      style={{
                        fontWeight: 700,
                        fontSize: 13,
                        color: selectedDay === 2 ? "#fff" : "#000",
                      }}
                    >
                      4-7 ngày
                    </p>
                  </div>
                  <div
                    onClick={() => setSelectedDay(3)}
                    style={{
                      width: "45%",
                      height: 40,
                      borderRadius: 10,
                      boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
                      marginBottom: 12,
                      background:
                        selectedDay === 3
                          ? "linear-gradient(rgba(208, 54, 1, 0.74) 0%, rgba(226, 123, 7, 0.67) 100%)"
                          : "#fff",
                      cursor: "pointer",
                    }}
                    className="c-flex-center"
                  >
                    <p
                      style={{
                        fontWeight: 700,
                        fontSize: 13,
                        color: selectedDay === 3 ? "#fff" : "#000",
                      }}
                    >
                      8-14 ngày
                    </p>
                  </div>
                  <div
                    onClick={() => setSelectedDay(4)}
                    style={{
                      width: "45%",
                      height: 40,
                      borderRadius: 10,
                      boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
                      marginBottom: 12,
                      background:
                        selectedDay === 4
                          ? "linear-gradient(rgba(208, 54, 1, 0.74) 0%, rgba(226, 123, 7, 0.67) 100%)"
                          : "#fff",
                      cursor: "pointer",
                    }}
                    className="c-flex-center"
                  >
                    <p
                      style={{
                        fontWeight: 700,
                        fontSize: 13,
                        color: selectedDay === 4 ? "#fff" : "#000",
                      }}
                    >
                      trên 14 ngày
                    </p>
                  </div>
                </div>
              </div>
            </div>
            {/*  */}
            <div
              style={{
                width: "100%",
                borderRadius: 10,
               
                marginBottom: 12,
              }}
            >
              <div
                style={{
                  width: "100%",
                  background:
                    "linear-gradient(180deg, rgba(208, 54, 1, 0.74) 0%, rgba(226, 123, 7, 0.67) 100%)",
                  padding: "10px 20px",borderTopLeftRadius: 10,borderTopRightRadius: 10
                }}
              >
                <p
                  style={{
                    fontWeight: 700,
                    fontSize: 14,
                    color: "#fff",
                    textTransform: "uppercase",
                  }}
                >
                  Ngân sách ( VND )
                </p>
              </div>
              <div
                style={{
                  width: "100%",
                  backgroundColor: "#F0F0F0",
                  padding: 10,borderBottomLeftRadius: 10, borderBottomRightRadius: 10
                }}
              >
                <div style={{ width: "100%", marginBottom: 24 }}>
                  <Slider
                    getAriaLabel={() => "Ngân sách"}
                    value={rangePrice}
                    onChange={handleChangePrice}
                    valueLabelDisplay="auto"
                    getAriaValueText={valuetext}
                    valueLabelFormat={valuetext}
                    min={MIN_PRICE_FILTER}
                    max={MAX_PRICE_FILTER}
                    step={STEP_FILTER}
                    color="warning"
                  />
                  <div
                    style={{
                      width: "100%",
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      marginTop: 10,
                    }}
                  >
                    <div
                      style={{
                        background: "#E5E5E5",
                        borderRadius: 80,
                        fontSize: 14,
                        padding: 5,
                        minWidth: 20,
                      }}
                      className="c-flex-center"
                    >
                      <p>{MIN_PRICE_FILTER}</p>
                    </div>
                    <div
                      style={{
                        background: "#E5E5E5",
                        borderRadius: 80,
                        fontSize: 14,
                        padding: 5,
                        minWidth: 20,
                      }}
                      className="c-flex-center"
                    >
                      <p>{numberWithDot(MAX_PRICE_FILTER)}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              style={{
                width: "100%",
                borderRadius: 10,
                overflow: "hidden",
                marginBottom: 12,
              }}
            >
              <div
                onClick={handleClick}
                style={{
                  width: "100%",
                  background:
                    "linear-gradient(180deg, rgba(208, 54, 1, 0.74) 0%, rgba(226, 123, 7, 0.67) 100%)",
                  padding: "10px 20px",
                }}
              >
                <p
                  style={{
                    fontWeight: 700,
                    fontSize: 14,
                    color: "#fff",
                    textTransform: "uppercase",
                    textAlign: "center",
                    cursor: "pointer",
                  }}
                >
                  Tìm kiếm
                </p>
              </div>
            </div>
          </div>
          <div
            style={{
              flex: 1,
              paddingLeft: 20,
              position: "relative",
              maxWidth: "calc(100% - 300px)",
            }}
            className="wrap-tour-travel-list m-w-100 m-pl-0"
          >
            <MediaQuery minWidth={625}>
              <div
                style={{
                  width: "100%",
                  position: "relative",
                  height: 60,
                  background: "#F8F8F8",
                  zIndex: 998
                }}
              >
                <div
                  style={{ position: "absolute", width: "100%", top: "-100%" }}
                >
                  <div
                    style={{
                      width: "100%",
                      background: "#EBEBEB",
                      borderTopLeftRadius: 10,
                      borderTopRightRadius: 10,
                      padding: "15px 20px",
                    }}
                  >
                    <div
                      style={{
                        width: "100%",
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <div
                        onClick={() => sortData(1)}
                        className="c-flex-center"
                        style={{ cursor: "pointer" }}
                      >
                        <div className="c-flex-center">
                          <FaArrowUp size={24} />
                        </div>
                        <div
                          style={{
                            marginLeft: 12,
                            fontSize: 20,
                            fontWeight: 700,
                            textTransform: "capitalize",
                          }}
                        >
                          Giá thấp đến cao
                        </div>
                      </div>
                      <div
                        onClick={() => sortData(2)}
                        className="c-flex-center"
                        style={{ marginLeft: 50, cursor: "pointer" }}
                      >
                        <div className="c-flex-center">
                          <FaArrowDown size={24} />
                        </div>
                        <div
                          style={{
                            marginLeft: 12,
                            fontSize: 20,
                            fontWeight: 700,
                            textTransform: "capitalize",
                          }}
                        >
                          Giá cao đến thấp
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </MediaQuery>
            <div
              className="m-t-0 m-pd-0"
              style={{
                width: "100%",
                position: "relative",
                top: "-56px",
                marginTop: 24,
                background: "#F8F8F8",
                padding: "0 20px 20px 20px",
                borderBottomRightRadius: 10,
                borderBottomLeftRadius: 10,
                boxShadow: "rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px",
              }}
            >
              <div style={{ width: "100%" }}>
                {loading === false && (
                  <>
                    <Link style={{color: "unset", textDecoration: "none", }} to={"/tour-mien-trung"}>
                      <div className="title-list tt-uc tx-20" style={{cursor: "pointer"}} onClick={()=> {
                        navigate("/tour-mien-trung")
                      }}>Tour miền trung</div>

                    </Link>
                    <Grid container spacing={2}>
                      {loading === false &&
                        data
                          ?.filter((item) => item?.type === 3)?.slice(
                            (currentPage - 1) * itemPage,
                            (currentPage - 1) * itemPage + itemPage
                          )
                          ?.map((item, key) => (
                            <Grid key={key} item xs={6} sm={4} mt={3}>
                              <ItemTour {...item} />
                            </Grid>
                          ))}
                      {data?.filter((item) => item?.type === 3)?.length <=
                        0 && (
                        <Grid item xs={12} mt={2}>
                          <div style={{ textAlign: "center", fontSize: 20 }}>
                            Không tìm thấy kết quả trùng khớp, Bạn hãy thử lại
                          </div>
                        </Grid>
                      )}
                    </Grid>
                    <br />
                    {data?.filter((item) => item?.type === 3)?.length > 0 && (
                      <div className="d-flex w-100 flex-row-reverse">
                        <Pagination
                          count={Math.ceil(data?.filter((item) => item?.type === 3).length / itemPage)}
                          page={currentPage === 0 ? 1 : currentPage}
                          onChange={handlePageChange}
                          color="primary"
                        />
                      </div>
                    )}
                  </>
                )}
                {loading === true && <LoadingTour />}
                {loading === false && data?.length <= 0 && (
                  <Grid item xs={12} mt={2}>
                    <div style={{ textAlign: "center", fontSize: 20 }}>
                      Không tìm thấy kết quả trùng khớp, Bạn hãy thử lại
                    </div>
                  </Grid>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      {/*  */}
      <PopularTour />
    </div>
  );
};

export default TourMienTrung;

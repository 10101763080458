import MediaQuery from "react-responsive";

const Section1 = (props) => {
  const { Img4, IntroVideo, handleClick, mw } = props;

  return (
    <div style={{ width: "100%" }} className={"c-flex-center"}>
      <div
        className="m-mt-0"
        style={{ maxWidth: mw, width: "100%", marginTop: 50 }}
      >
        <div className="m-mt-0" style={{ marginTop: 50 }}>
          <div className="container-mission">
            <div className="content-mission">
              <div
                className="left-content-mission m-p-0 m-mb-2"
                style={{ paddingRight: 60, flex: 1 }}
              >
                <MediaQuery minWidth={625}>
                  <div
                    className="left-content-title-mission tt-uc"
                    style={{ fontSize: 28, fontWeight: 700 }}
                  >
                    Chúng tôi cung cấp dịch vụ
                  </div>
                </MediaQuery>
                <MediaQuery maxWidth={625}>
                  <div className="text-trad tt-uc fw-800 fs-20">
                    Chúng tôi cung cấp dịch vụ
                  </div>
                </MediaQuery>

                <div className="divider-margin"></div>
                <p className="p-left-content-mission" style={{textIndent: 20}}>
                  <span className="tt fw-600">Đơn vị Minh Khang Home</span> -
                  Là đơn vị tiên phong cung cấp các dịch vụ bất động sản cho
                  thuê, Vận hành Quản lý các chung cư, toà căn hộ, khách sạn tại
                  khu vực miền Trung.
                </p>
                <p className="p-left-content-mission" style={{textIndent: 20}}>
                  <span className="tt fw-600">Đơn vị Minh Khang Travel</span>{" "}
                  - Là đơn vị lữ hành uy tín hàng đầu Châu Á. Trở thành “Gương
                  mặt thân quen” đối với khách hàng trên hành trình chinh phục
                  và khám phá những miền đất mới, “Mang lại cảm xúc thăng hoa”
                  cho du khách trong mỗi hành trình.
                </p>
                {/* <MediaQuery minWidth={625}> */}
                  <p>
                    <div
                      onClick={handleClick}
                      className="button-more-left-content-mission"
                    >
                      Tìm hiểu
                    </div>
                  </p>
                {/* </MediaQuery> */}
              </div>
              <MediaQuery maxWidth={625}>
                <div style={{ width: "100%" }}>
                  {/* <img src={Img4} style={{ width: "100%" }} alt="" /> */}
                </div>
              </MediaQuery>
              <MediaQuery minWidth={625}>
                <div className="m-w-100" style={{ width: 550 }}>
                  <video
                    src={IntroVideo}
                    autoPlay
                    muted
                    loop
                    style={{ width: "100%" }}
                    alt="Can't open"
                  />
                </div>
              </MediaQuery>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Section1;

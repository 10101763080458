import React, { useEffect, useState } from "react";
// import Banner from "../../../assets/hotel/banner.png";
// import Banner1 from "../../../assets/hotel/banner1.png";
// import Banner2 from "../../../assets/hotel/banner2.png";
// import Template1 from "../../../assets/hotel/template1.jpg";
// import Star from "../../../assets/hotel/star.png";
import Slider from "@mui/material/Slider";
// import { Box, FormControl, InputLabel, MenuItem, Select } from "@mui/material";
// import { IoIosSearch } from "react-icons/io";
import SelectBox3 from "../../util/SelectBox/SelectBox3";
import {
  apiGetProvince,
  apiGetWard,
  apiSearchResult,
  getListProvince,
  getProductByCategory,
} from "../../../api";
import SelectBox2 from "../../util/SelectBox/SelectBox";
import DatePickerValue from "../../util/DatePicker/DatePicker";
import {
  listBedRoom,
  // listBudget,
  // listGuest,
  // listPassenger,
  listRoom,
  listStar,
} from "../../../data/data";
import Item from "../../Product/Item";
import { Grid } from "@mui/material";
import ScrollToTop from "../../../utils/ScrollToTop";
import LoadingHotel from "../../Loading/LoadingHotel";
import {
  MAX_PRICE_FILTER,
  MAX_PRICE_FILTER_HOTEL,
  MIN_PRICE_FILTER,
  MIN_PRICE_FILTER_HOTEL,
  // STEP_FILTER,
  STEP_FILTER_HOTEL,
} from "../../../config/constant";
import numberWithDot from "../../../utils/numberWithDot";
import MediaQuery from "react-responsive";
import { setting2 } from "../home/Banner";
import { Image, Swiper } from "react-nivo-slider";
import B1Image from "./image/b1.png";
import B2Image from "./image/b2.png";
import B3Image from "./image/b3.png";
import B1Image2 from "./image/b1_2.png";
import B2Image2 from "./image/b2_2.png";
import B3Image2 from "./image/b3_2.png";
import B1Image3 from "./image/b1_3.png";
import B2Image3 from "./image/b2_3.png";
import B3Image3 from "./image/b3_3.png";
import { Helmet } from "react-helmet";

export function valuetext(value) {
  return `${numberWithDot(value)}`;
}

const Hotel = (props) => {
  const categoryId = 12;
  const { id, t } = props;
  const [typeRoom, setTypeRoom] = useState();
  const [data, setData] = useState([]);
  const [typeBooking, setTypeBooking] = useState(0);
  const [passenger, setPassenger] = useState();
  const [departureDay, setDepartureDay] = useState();
  const [destinationDay, setDestinationDay] = useState();
  const [listProvince, setListProvince] = useState([]);
  const [listDistrict, setListDictrict] = useState([]);
  const [star, setStar] = useState();
  const [room, setRoom] = useState();
  const [listWard, setListWard] = useState([]);
  // province id da nang
  const [province, setProvince] = useState(48);
  const [district, setDistrict] = useState();
  const [ward, setWard] = useState();
  const [provinceDetail, setProvinceDetail] = useState();
  // const [isDisabledButton, setDisabledButton] = useState(false);
  const [seat, setSeat] = useState();
  const [budget, setBudget] = useState();
  const [loading, setLoading] = useState();
  const [resetFilter, setResetFilter]= useState(false)
  const handleClick = async () => {
    try {
      const data = {
        typeBooking: 2,
        province: province,
        district: district,
        ward: ward,
        departureDay: departureDay ? departureDay : "",
        destinationDay: destinationDay ? destinationDay : "",
        passenger: passenger,
        budget: budget,
        subCategoryId: id,
        minBudget: rangePrice[0],
        maxBudget: rangePrice[1],
        rating: star,
        typeRoom: typeRoom,
      };
      setLoading(true);
      setResetFilter(true)
      const result = await apiSearchResult(data);
      if (typeRoom) {
        const resultf = result?.data?.filter(
          (item) => parseInt(item?.typeRoom) === parseInt(typeRoom)
        );
        setLoading(false);
        setData(resultf);
        return;
      } else {
        setData(result?.data);
      }
      setLoading(false);
      setData(result?.data);
      // Xử lý logic khi button được click
    } catch (error) {
      setLoading(false);
    } finally {
      setLoading(false);
    }
  };

  const getprovince = async (code) => {
    const response = await apiGetProvince(code);
    if (response.status === 200) setProvinceDetail(response.data);
  };

  const getdistrict = async (code) => {
    const response = await apiGetProvince(code);
    setListDictrict(response?.results);
  };

  const getlistward = async (code) => {
    const response1 = await apiGetWard(code);
    setListWard(response1?.results);
  };

  const resetFilterFunction= async ()=> {
    try {
      setLoading(true);
      const result = await getProductByCategory({
        categoryId,
        subCategoryId: id,
        type: t
      });
      setLoading(false);
      if (result?.success === true) {
        setData(result?.results);
      }
    } catch (error) {
      setLoading(false);
    } finally {
      setLoading(false);
      setResetFilter(false)

    }
  }

  useEffect(() => {
    if (province) getprovince(province);
    if (province) getdistrict(province);
    if (district) getlistward(district);
  }, [province, district]);

  useEffect(() => {
    (async () => {
      const result = await getListProvince();
      setListProvince(result);
    })();
  }, []);

  useEffect(() => {
    setTypeBooking(props?.type);
  }, [props?.type]);

  const [rangePrice, setRangePrice] = useState([
    MIN_PRICE_FILTER,
    MAX_PRICE_FILTER,
  ]);
  const handleChangePrice = (event, newValue) => {
    setRangePrice(newValue);
  };

  useEffect(() => {
    if (parseInt(t) === 1) {
      setRangePrice([MIN_PRICE_FILTER_HOTEL, MAX_PRICE_FILTER]);
    }
    if (parseInt(t) === 2) {
      setRangePrice([MIN_PRICE_FILTER_HOTEL, MAX_PRICE_FILTER_HOTEL]);
    }
    if (parseInt(t) === 3) {
      setRangePrice([MIN_PRICE_FILTER_HOTEL, MAX_PRICE_FILTER_HOTEL]);
    }
  }, [t]);

  useEffect(() => {
    (async () => {
      try {
        setLoading(true);
        const result = await getProductByCategory({
          categoryId,
          subCategoryId: id,
          type: t
        });
        setLoading(false);
        if (result?.success === true) {
          setData(result?.results);
        }
      } catch (error) {
        setLoading(false);
      } finally {
        setLoading(false);
      }
    })();
  }, [id, t]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [id]);

  const renderMinValueFilter = (value) => {
    switch (value) {
      case 1:
        return MIN_PRICE_FILTER_HOTEL;
      case 2:
        return MIN_PRICE_FILTER;
      case 3:
        return MIN_PRICE_FILTER;
      default:
        return MIN_PRICE_FILTER
    }
  };
  
  const renderMaxValueFilter= (value)=> {
    switch (value) {
      case 1:
        return MAX_PRICE_FILTER;
      case 2:
        return MAX_PRICE_FILTER_HOTEL;
      case 3:
        return MAX_PRICE_FILTER_HOTEL;
      default:
        return MAX_PRICE_FILTER
    }
  }

  return (
    <div
      style={{
        width: "100%",
      }}
    >
      <Helmet>
        <title>{props?.title || "Khách sạn"}</title>
        <link rel="canonical" href={window.location.origin + window.location.pathname} />
        <meta
          data-react-helmet="true"
          property="og:url"
          content={window.location.origin + window.location.pathname}
        ></meta>
      </Helmet>
      <ScrollToTop />
      <div
        className="banner-hotel"
        style={{ width: "100%", position: "relative" }}
      >
        {props?.t === 1 && (
          <Swiper {...setting2}>
            <Image src={B1Image2} alt="image1" />
            <Image src={B2Image2} alt="image2" />
            <Image src={B3Image2} alt="image3" />
          </Swiper>
        )}
        {props?.t === 2 && (
          <Swiper {...setting2}>
            <Image src={B1Image3} alt="image1" />
            <Image src={B2Image3} alt="image2" />
            <Image src={B3Image3} alt="image3" />
          </Swiper>
        )}
        {/* homestay   */}
        {props?.t === 3 && (
          <Swiper {...setting2}>
            <Image src={B1Image} alt="image1" />
            <Image src={B2Image} alt="image2" />
            <Image src={B3Image} alt="image3" />
          </Swiper>
        )}
        <div
          style={{
            position: "absolute",
            left: "50%",
            top: "25%",
            transform: "translateX(-50%)",
            textAlign: "center",
          }}
        ></div>
      </div>
      <MediaQuery minWidth={625}>
        <div style={{ height: 30 }}></div>
      </MediaQuery>
      <div style={{ width: "100%" }} className="c-flex-center m-mt-0">
        <div style={{ width: "100%", maxWidth: 1248 }}>
          <div></div>
          <div
            style={{ width: "100%", display: "flex" }}
            className="m-flex-column p-10"
          >
            <div
              className="apartment-section-1 m-w-100 m-p-0"
              style={{ width: 320, paddingRight: 20 }}
            >
              <MediaQuery minWidth={625}>
                <div
                  style={{
                    fontSize: 20,
                    fontWeight: 700,
                    color: "#E27B07",
                    textDecoration: "none",
                    marginBottom: 12,
                    textTransform: "uppercase",
                  }}
                >
                  Bộ lọc tìm kiếm
                </div>
              </MediaQuery>
              <div
                style={{
                  width: "100%",
                  borderRadius: 10,
                  overflow: "hidden",
                  marginBottom: 12,
                }}
              >
                <div
                  style={{
                    width: "100%",
                    background:
                      "linear-gradient(180deg, rgba(208, 54, 1, 0.74) 0%, rgba(226, 123, 7, 0.67) 100%)",
                    padding: "10px 20px",
                  }}
                >
                  <p
                    style={{
                      fontWeight: 700,
                      fontSize: 14,
                      color: "#fff",
                      textTransform: "uppercase",
                      textAlign: "center",
                    }}
                  >
                    Địa điểm, vị trí
                  </p>
                </div>
                <div
                  style={{
                    width: "100%",
                    backgroundColor: "#F0F0F0",
                    padding: 10,
                  }}
                >
                  <div
                    style={{
                      width: "100%",
                      borderRadius: 10,
                      border: "1px solid #e7e7e7",
                      background: "#fff",
                      padding: 10,
                      marginBottom: 12,
                    }}
                  >
                    <SelectBox3
                      size={"small"}
                      value={province}
                      setValue={setProvince}
                      label={"Tỉnh / Thành phố"}
                      list={[{value: -1, label: "Tất cả"}].concat(listProvince?.map((item) => ({
                        ...item,
                        value: item.province_id,
                        label: item.province_name,
                      })))}
                    />
                  </div>
                  <div
                    style={{
                      width: "100%",
                      borderRadius: 10,
                      border: "1px solid #e7e7e7",
                      background: "#fff",
                      padding: 10,
                      marginBottom: 12,
                    }}
                  >
                    <p style={{ fontSize: 12, color: "#989898" }}>
                      <SelectBox3
                        size={"small"}
                        value={district}
                        setValue={setDistrict}
                        label={"Quận / Huyện"}
                        list={listDistrict?.map((el) => ({
                          ...el,
                          value: el.district_id,
                          label: el.district_name,
                        }))}
                      />
                    </p>
                  </div>
                  <div
                    style={{
                      width: "100%",
                      borderRadius: 10,
                      border: "1px solid #e7e7e7",
                      background: "#fff",
                      padding: 10,
                      marginBottom: 12,
                    }}
                  >
                    <SelectBox3
                      size={"small"}
                      value={ward}
                      setValue={setWard}
                      label={"Xã / Phường"}
                      list={listWard?.map((el) => ({
                        ...el,
                        value: el.ward_id,
                        label: el.ward_name,
                      }))}
                    />
                  </div>
                  <MediaQuery maxWidth={625}>
                    <div style={{ width: "100%", display: "flex", gap: 10 }}>
                      <div
                        style={{
                          width: "100%",
                          borderRadius: 10,
                          border: "1px solid #e7e7e7",
                          background: "#fff",
                          padding: 10,
                          marginBottom: 12,
                        }}
                      >
                        <DatePickerValue
                          className={"res-select-1"}
                          value={departureDay}
                          setValue={setDepartureDay}
                          label={"Nhận phòng"}
                        />
                      </div>
                      <div
                        style={{
                          width: "100%",
                          borderRadius: 10,
                          border: "1px solid #e7e7e7",
                          background: "#fff",
                          padding: 10,
                          marginBottom: 12,
                        }}
                      >
                        <SelectBox2
                          className={"res-select-1"}
                          value={seat}
                          setValue={setSeat}
                          label={"Số phòng"}
                          list={listRoom}
                        />
                      </div>
                    </div>
                    {/*  */}
                    <div style={{ width: "100%", display: "flex", gap: 10 }}>
                      <div
                        style={{
                          width: "100%",
                          borderRadius: 10,
                          border: "1px solid #e7e7e7",
                          background: "#fff",
                          padding: 10,
                          marginBottom: 12,
                        }}
                      >
                        <DatePickerValue
                          className={"res-select-1"}
                          value={destinationDay}
                          setValue={setDestinationDay}
                          label={"Trả phòng"}
                        />
                      </div>
                      <div
                        style={{
                          width: "100%",
                          borderRadius: 10,
                          border: "1px solid #e7e7e7",
                          background: "#fff",
                          padding: 10,
                          marginBottom: 12,
                        }}
                      >
                        <SelectBox2
                          className={"res-select-1 m-mt-2"}
                          value={star}
                          setValue={setBudget}
                          label={"Số sao"}
                          list={listStar}
                        />
                      </div>
                    </div>
                    {/*  */}
                    <div
                      style={{
                        width: "100%",
                        backgroundColor: "#fff",
                        padding: 10,
                        borderRadius: 10,
                      }}
                    >
                      <div
                        style={{ color: "rgb(137, 137, 137)", fontWeight: 600 }}
                      >
                        Ngân sách
                      </div>
                      <Slider
                        getAriaLabel={() => "Ngân sách"}
                        value={rangePrice}
                        onChange={handleChangePrice}
                        valueLabelDisplay="auto"
                        getAriaValueText={valuetext}
                        valueLabelFormat={valuetext}
                        min={MIN_PRICE_FILTER}
                        step={STEP_FILTER_HOTEL}
                        max={MAX_PRICE_FILTER_HOTEL}
                        color="warning"
                      />
                      <div
                        style={{
                          width: "100%",
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                          marginTop: 10,
                        }}
                      >
                        <div
                          style={{
                            background: "#E5E5E5",
                            borderRadius: 80,
                            fontSize: 14,
                            padding: 5,
                            minWidth: 20,
                          }}
                          className="c-flex-center"
                        >
                          <p>{MIN_PRICE_FILTER}</p>
                        </div>
                        <div
                          style={{
                            background: "#E5E5E5",
                            borderRadius: 80,
                            fontSize: 14,
                            padding: 5,
                            minWidth: 20,
                          }}
                          className="c-flex-center"
                        >
                          <p>{numberWithDot(MAX_PRICE_FILTER_HOTEL)}</p>
                        </div>
                      </div>
                    </div>
                  </MediaQuery>
                </div>
              </div>
              {/*  */}
              <MediaQuery minWidth={625}>
                <div
                  style={{
                    width: "100%",
                    borderRadius: 10,
                    overflow: "hidden",
                    marginBottom: 12,
                  }}
                >
                  <div
                    style={{
                      width: "100%",
                      background:
                        "linear-gradient(180deg, rgba(208, 54, 1, 0.74) 0%, rgba(226, 123, 7, 0.67) 100%)",
                      padding: "10px 20px",
                    }}
                  >
                    <p
                      style={{
                        fontWeight: 700,
                        fontSize: 14,
                        color: "#fff",
                        textTransform: "uppercase",
                        textAlign: "center",
                      }}
                    >
                      Thông tin chung
                    </p>
                  </div>
                  <div
                    style={{
                      width: "100%",
                      backgroundColor: "#F0F0F0",
                      padding: 10,
                    }}
                  >
                    <div
                      style={{
                        width: "100%",
                        borderRadius: 10,
                        border: "1px solid #e7e7e7",
                        background: "#fff",
                        padding: 10,
                        marginBottom: 12,
                      }}
                    >
                      <SelectBox3
                        size={"small"}
                        value={star}
                        setValue={setStar}
                        label={"Số sao"}
                        list={listStar}
                      />
                    </div>
                    <div
                      style={{
                        width: "100%",
                        borderRadius: 10,
                        border: "1px solid #e7e7e7",
                        background: "#fff",
                        padding: 10,
                        marginBottom: 12,
                      }}
                    >
                      <SelectBox3
                        size={"small"}
                        value={typeRoom}
                        setValue={setTypeRoom}
                        label={"Hạng phòng"}
                        list={listBedRoom?.filter(
                          (item) => parseInt(item.value) > 11
                        )}
                      />
                    </div>
                    <div
                      style={{
                        width: "100%",
                        borderRadius: 10,
                        border: "1px solid #e7e7e7",
                        background: "#fff",
                        padding: 10,
                        marginBottom: 12,
                      }}
                    >
                      <DatePickerValue
                        disableTitle={true}
                        w={"100%"}
                        value={departureDay}
                        setValue={setDepartureDay}
                        label={"Nhận phòng"}
                      />
                    </div>
                    <div
                      style={{
                        width: "100%",
                        borderRadius: 10,
                        border: "1px solid #e7e7e7",
                        background: "#fff",
                        padding: 10,
                        marginBottom: 12,
                      }}
                    >
                      <DatePickerValue
                        disableTitle={true}
                        w={"100%"}
                        className={"res-select-1"}
                        value={destinationDay}
                        setValue={setDestinationDay}
                        label={"Trả phòng"}
                      />
                    </div>
                    <div
                      style={{
                        width: "100%",
                        borderRadius: 10,
                        border: "1px solid #e7e7e7",
                        background: "#fff",
                        padding: 10,
                        marginBottom: 12,
                      }}
                    >
                      <SelectBox3
                        size={"small"}
                        value={room}
                        setValue={setRoom}
                        label={"Số phòng"}
                        list={listRoom}
                      />
                    </div>
                  </div>
                </div>
                {/*  */}
                <div
                  className="container-qjsa-1"
                  style={{
                    width: "100%",
                    borderRadius: 10,
                    marginBottom: 12,
                  }}
                >
                  <div
                    style={{
                      width: "100%",
                      background:
                        "linear-gradient(180deg, rgba(208, 54, 1, 0.74) 0%, rgba(226, 123, 7, 0.67) 100%)",
                      padding: "10px 20px",
                    }}
                  >
                    <p
                      style={{
                        fontWeight: 700,
                        fontSize: 14,
                        color: "#fff",
                        textTransform: "uppercase",
                        textAlign: "center",
                      }}
                    >
                      Ngân sách ( VND )
                    </p>
                  </div>
                  <div
                    style={{
                      width: "100%",
                      backgroundColor: "#F0F0F0",
                      padding: 10,
                    }}
                  >
                    <Slider
                      getAriaLabel={() => "Ngân sách"}
                      value={rangePrice}
                      onChange={handleChangePrice}
                      valueLabelDisplay="auto"
                      getAriaValueText={valuetext}
                      valueLabelFormat={valuetext}
                      min={renderMinValueFilter(t)}
                      step={STEP_FILTER_HOTEL}
                      max={renderMaxValueFilter(t)}
                      color="warning"
                    />
                    <div
                      style={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        marginTop: 10,
                      }}
                    >
                      <div
                        style={{
                          background: "#E5E5E5",
                          borderRadius: 80,
                          fontSize: 14,
                          padding: 5,
                          minWidth: 20,
                        }}
                        className="c-flex-center"
                      >
                        <p>{numberWithDot(renderMinValueFilter(t))}</p>
                      </div>
                      <div
                        style={{
                          background: "#E5E5E5",
                          borderRadius: 80,
                          fontSize: 14,
                          padding: 5,
                          minWidth: 20,
                        }}
                        className="c-flex-center"
                      >
                        <p>{numberWithDot(renderMaxValueFilter(t))}</p>
                      </div>
                    </div>
                  </div>
                </div>
                {/* <div
                  style={{
                    width: "100%",
                    borderRadius: 10,
                    overflow: "hidden",
                    marginBottom: 12,
                  }}
                >
                  <div
                    style={{
                      width: "100%",
                      background:
                        "linear-gradient(180deg, rgba(208, 54, 1, 0.74) 0%, rgba(226, 123, 7, 0.67) 100%)",
                      padding: "10px 20px",
                    }}
                  >
                    <p
                      style={{
                        fontWeight: 700,
                        fontSize: 14,
                        color: "#fff",
                        textTransform: "uppercase",
                      }}
                    >
                      Loại phòng
                    </p>
                  </div>
                  <div
                    style={{
                      width: "100%",
                      borderRadius: 10,
                      border: "1px solid #e7e7e7",
                      background: "#fff",
                      padding: 10,
                      marginBottom: 12,
                    }}
                  >
                    <SelectBox3
                      size={"small"}
                      value={typeRoom}
                      setValue={setTypeRoom}
                      label={"Hạng phòng"}
                      list={listBedRoom}
                    />
                  </div>
                  <div
                    style={{
                      width: "100%",
                      borderRadius: 10,
                      border: "1px solid #e7e7e7",
                      background: "#fff",
                      padding: 10,
                      marginBottom: 12,
                    }}
                  >
                    <SelectBox3
                      size={"small"}
                      value={room}
                      setValue={setRoom}
                      label={"Số phòng"}
                      list={listRoom}
                    />
                  </div>
                </div> */}
              </MediaQuery>
              <div
                style={{
                  width: "100%",
                  borderRadius: 10,
                  overflow: "hidden",
                  marginBottom: 12,
                }}
              >
                <div
                  style={{
                    width: "100%",
                    background:
                      "linear-gradient(180deg, rgba(208, 54, 1, 0.74) 0%, rgba(226, 123, 7, 0.67) 100%)",
                    padding: "10px 20px",
                  }}
                  onClick={handleClick}
                >
                  <p
                    style={{
                      fontWeight: 700,
                      fontSize: 14,
                      color: "#fff",
                      textTransform: "uppercase",
                      textAlign: "center",
                      cursor: "pointer",
                    }}
                  >
                    Tìm kiếm
                  </p>
                </div>
              </div>
              {/*  */}
              {resetFilter &&
                <div
                  style={{
                    width: "100%",
                    borderRadius: 10,
                    overflow: "hidden",
                    marginBottom: 12,
                  }}
                >
                  <div
                    style={{
                      width: "100%",
                      background:
                        "linear-gradient(180deg, rgba(208, 54, 1, 0.74) 0%, rgba(226, 123, 7, 0.67) 100%)",
                      padding: "10px 20px",
                    }}
                    onClick={resetFilterFunction}
                  >
                    <p
                      style={{
                        fontWeight: 700,
                        fontSize: 14,
                        color: "#fff",
                        textTransform: "uppercase",
                        textAlign: "center",
                        cursor: "pointer",
                      }}
                    >
                      Đặt lại bộ lọc
                    </p>
                  </div>
                </div>
              }
            </div>
            <div
              className="m-w-100"
              style={{ flex: 1, maxWidth: "calc(100% - 320px)" }}
            >
              <Grid container spacing={2} mb={3}>
                {loading === false &&
                  data?.length > 0 &&
                  data?.map((item, key) => (
                    <Grid key={key} item xs={6} sm={4}>
                      <Item
                        dataHotel={{ departureDay, destinationDay }}
                        hotel={true}
                        w={"w-100"}
                        {...item}
                      />
                    </Grid>
                  ))}
                {loading === false && data?.length <= 0 && (
                  <Grid item xs={12} mt={2}>
                    <div style={{ textAlign: "center", fontSize: 20 }}>
                      Không tìm thấy kết quả trùng khớp, Bạn hãy thử lại
                    </div>
                  </Grid>
                )}
                {loading === true && <LoadingHotel />}
              </Grid>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Hotel;

import React, { useEffect, useState } from "react";
import { apiGetBlogType, apiGetSuggestProduct } from "../../api";
import { Grid } from "@mui/material";
import ItemDealHot from "./ItemDealHot";
import LoadingDealHot from "../Loading/LoadingDealHot";

const DealHot = () => {
  const [type, setType] = useState(3);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState();

  useEffect(() => {
    (async () => {
      try {
        setLoading(true);
        const result = await apiGetSuggestProduct();
        setLoading(false);
        
        if (result?.ok === true) {
          setData(result?.data);
        }
      } catch (error) {
        setLoading(false);
        console.log(error);
      } finally {
        setLoading(false);
      }
    })();
  }, [type]);

  return (
    <div
      style={{
        width: "100%",
        borderRadius: 10,
        boxShadow:
          "rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px",
      }}
    >
      {
        loading=== false &&
        <div style={{ width: "100%" }}>
          <div
            style={{
              width: "100%",
              padding: "20px 0",
              background: "#F5F4F4",
            }}
            className="c-flex-center"
          >
            <p
              className="blink"
              style={{
                textAlign: "center",
                textTransform: "uppercase",
                color: "#E27B07",
                fontSize: 18,
                fontWeight: 700,
              }}
            >
              Đề xuất cho bạn
            </p>
          </div>
          <div style={{ width: "100%", padding: 20 }}>
            <Grid container spacing={2}>
              {data?.slice(0, 4)?.map((item, key) => (
                <Grid key={key} xs={12} item mb={1}>
                  <ItemDealHot {...item} />
                </Grid>
              ))}
            </Grid>
          </div>
        </div>

      }
      {
        loading=== true && 
        <LoadingDealHot />
      }
    </div>
  );
};

export default DealHot;

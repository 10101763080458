import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { apiGetTourDetail, apiGetTourDetailClient } from "../api";
import Template4 from "../assets/tour/template4.png";
import PopularTour from "../components/PopularTour/PopularTour";
import DealHot from "../components/DealHot/DealHot";
import ScrollToTop from "../utils/ScrollToTop";
import LoadingSkeletonBlog, {
  LoadingSkeletonBlog1,
  LoadingSkeletonBlogContent,
} from "../components/Loading/LoadingSkeletonBlog";
import DiscoverList from "../components/DiscoverList/DiscoverList";
import numberWithCommas from "../utils/numberWithComma";
import { FaPen } from "react-icons/fa6";
import moment from "moment";
import { FaCalendarAlt } from "react-icons/fa";
import swal from "sweetalert";
import RenderDangerHtml from "../components/RenderDangerHtml/RenderDangerHtml";
import { Helmet } from "react-helmet";

const DetailTour = () => {
  const [loading, setLoading] = useState();
  const [data, setData] = useState();
  const navigate = useNavigate();
  const [price, setPrice] = useState(data?.price);
  const [childrenPrice, setChildrenPrice] = useState(data?.children_price);
  const [adult, setAdult] = useState(1);
  const [children, setChildren] = useState(1);
  const [timeStart, setTimeStart] = useState();
  const { id } = useParams();

  useEffect(() => {
    (async () => {
      try {
        setLoading(true);
        const result = await apiGetTourDetailClient({
          id: id?.split("-").at(-1),
        });
        setLoading(false);
        if (result?.ok === true) {
          setData(result?.data?.[0]);
        }
      } catch (error) {
        setLoading(false);
      } finally {
        setLoading(false);
      }
    })();
  }, [id]);
  const handleClick = () => {
    if (!children || !adult || !timeStart) {
      return swal("Thông báo", "Bạn cần điền đầy đủ thông tin để tiếp tục");
    }
    navigate("/tour/payment/" + id, {
      state: { timeStart, adult, children, discountPer: data.discountPer },
    });
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [id]); // Empty dependency array to run only once after mounting

  return (
    <div className="container-tour">
      <Helmet>
        <title>{data?.name}</title>
        <link rel="canonical" href={window.location.origin + window.location.pathname} />
        <meta name="description" content={data?.meta_description}></meta>
        <meta property="og:description" content={data?.meta_description}></meta>
        <meta
          data-react-helmet="true"
          property="og:title"
          content={data?.name}
        ></meta>
        <meta
          data-react-helmet="true"
          property="og:url"
          content={window.location.origin + window.location.pathname}
        ></meta>
        <meta data-react-helmet="true" property="og:image" content={data?.photo}></meta>
      </Helmet>
      <ScrollToTop />
      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div style={{ width: "100%", maxWidth: 1248, marginBottom: 36 }}>
          <div style={{ textAlign: "center", margin: "24px 0" }}>
            {/* {loading=== false && 
              <div
                style={{
                  background: "linear-gradient(180deg, #D03601 0%, #E27B07 100%)",
                  backgroundClip: "text",
                  color: "transparent",
                  fontSize: 36,
                  fontWeight: 700,
                  textTransform: "uppercase",
                }}
              >
                {data?.name}
              </div>
            }
            {loading === true && <LoadingSkeletonBlog />} */}
          </div>
          <div
            style={{ width: "100%", display: "flex" }}
            className="m-flex-column"
          >
            <div className="sar-arfs-aw-fsaw" style={{ flex: 1 }}>
              <div
                className="wr-a-ras-s"
                style={{
                  boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
                  borderTopLeftRadius: 10,
                  borderTopRightRadius: 10,
                  padding: 10,
                }}
              >
                <div
                  style={{
                    fontSize: 24,
                    textTransform: "uppercase",
                    fontWeight: 600,
                    marginTop: 12,
                    marginBottom: 12,
                  }}
                  className="text-gra"
                >
                  {data?.name}
                </div>
                {loading === false && (
                  <div
                    style={{
                      width: "100%",
                      display: "flex",
                      gap: 20,
                    }}
                  >
                    <div
                      className="c-flex-center"
                      style={{ width: "max-content", gap: 10 }}
                    >
                      <FaCalendarAlt />{" "}
                      {moment(data?.time_created).format("DD/MM/YYYY")}
                    </div>
                    <div>-</div>
                    <div
                      className="c-flex-center"
                      style={{ width: "max-content", gap: 10 }}
                    >
                      <FaPen />{" "}
                      {data?.author
                        ? data?.author?.replace("null", "")
                        : "Unknown"}
                    </div>
                  </div>
                )}
                {loading === true && <LoadingSkeletonBlog1 />}
              </div>
              {/*  */}
              {loading === false && (
                <div
                  style={{
                    width: "100%",
                    background: "#F8F8F8",
                    padding: 30,
                    boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
                    borderBottomLeftRadius: 10,
                    borderBottomRightRadius: 10,
                  }}
                >
                  <RenderDangerHtml data={data?.content} is_tableofcontent={true} />
                  {/* <div
                    dangerouslySetInnerHTML={{ __html: data?.content }}
                  ></div> */}
                </div>
              )}
              {loading === true && <LoadingSkeletonBlogContent />}
            </div>
            <div
              className="wrap-dh-blog"
              style={{ width: 300, paddingLeft: 20 }}
            >
              {/* <DealHot /> */}
              <div
                className="as-aswa-v-da"
                style={{
                  width: "100%",
                  padding: 10,
                  borderRadius: 10,
                  background: "#F4F4F4",
                }}
              >
                <div
                  style={{
                    width: "100%",
                    padding: "4px",
                    borderBottom: "1px solid #A9A9A9",
                    fontSize: 18,
                    fontWeight: 600,
                  }}
                >
                  Thông tin tour
                </div>
                <div
                  className="jkdgaws-rssa-sas"
                  style={{ color: "#D03601", fontWeight: 600, marginTop: 8 }}
                >
                  {data?.price &&
                    numberWithCommas(
                      (data?.price * (100 - data?.discountPer)) / 100
                    )}{" "}
                  VND
                </div>
                <div
                  className="dsdasg-sas-raeas"
                  style={{ display: "flex", alignItems: "center" }}
                >
                  <p
                    style={{ fontSize: 12, fontWeight: 600, color: "#5D5C5C" }}
                  >
                    Mã vé:
                  </p>
                  <p
                    style={{
                      fontSize: 14,
                      color: "#fff",
                      background: "#E27B07",
                      borderRadius: 5,
                      marginLeft: 8,
                      fontWeight: 600,
                      padding: 2,
                    }}
                  >
                    {data?.tour_id}
                  </p>
                </div>
                <br />
                <div
                  className="asdsf-sa--aaaws"
                  style={{
                    width: "100%",
                    padding: "4px 12px",
                    borderRadius: 10,
                    borderLeft: "2px solid #E27B07",
                    background: "white",
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    marginBottom: 8,
                  }}
                >
                  <div style={{ fontSize: 13 }}>Thời gian:</div>
                  <div style={{ fontWeight: 600, fontSize: 14 }}>
                    {data?.timeText}
                  </div>
                </div>
                <div
                  className="asdsf-sa--aaaws"
                  style={{
                    width: "100%",
                    padding: "4px 12px",
                    borderRadius: 10,
                    borderLeft: "2px solid #E27B07",
                    background: "white",
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    marginBottom: 8,
                  }}
                >
                  <div style={{ fontSize: 13 }}>Khởi hành từ:</div>
                  <div style={{ fontWeight: 600, fontSize: 14 }}>
                    {data?.departureText}
                  </div>
                </div>
                <div
                  className="asdsf-sa--aaaws"
                  style={{
                    width: "100%",
                    padding: "4px 12px",
                    borderRadius: 10,
                    borderLeft: "2px solid #E27B07",
                    background: "white",
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    marginBottom: 8,
                  }}
                >
                  <div style={{ fontSize: 13 }}>Phương tiện:</div>
                  <div style={{ fontWeight: 600, fontSize: 14 }}>
                    {data?.car}
                  </div>
                </div>
              </div>
              <br />
              <br />
              <div
                className="as-aswa-v-da"
                style={{
                  width: "100%",
                  padding: 10,
                  borderRadius: 10,
                  background: "#F4F4F4",
                }}
              >
                <div
                  style={{
                    width: "100%",
                    padding: "4px",
                    borderBottom: "1px solid #A9A9A9",
                    fontSize: 18,
                    fontWeight: 600,
                  }}
                >
                  Đặt tour
                </div>
                <div
                  className="jkdgaws-rssa-sas"
                  style={{ color: "#D03601", fontWeight: 600, marginTop: 8 }}
                >
                  {numberWithCommas(
                    (data?.price * (100 - data?.discountPer)) / 100
                  )}{" "}
                  VND
                </div>
                <div style={{ fontSize: 12, fontWeight: 600, marginBottom: 2 }}>
                  Ngày đi
                </div>
                <div
                  className="asdsf-sa--aaaws"
                  style={{
                    width: "100%",
                    padding: "4px 12px",
                    borderRadius: 10,
                    borderLeft: "2px solid #E27B07",
                    background: "white",
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    marginBottom: 8,
                  }}
                >
                  <input
                    value={timeStart}
                    onChange={(e) => setTimeStart(e.target.value)}
                    type="date"
                    style={{
                      width: "100%",
                      height: 29,
                      border: "none",
                      outline: "none",
                    }}
                  />
                </div>
                <div style={{ fontSize: 12, fontWeight: 600, marginBottom: 2 }}>
                  Người lớn
                </div>
                <div
                  className="asdsf-sa--aaaws"
                  style={{
                    width: "100%",
                    padding: "4px 12px",
                    borderRadius: 10,
                    borderLeft: "2px solid #E27B07",
                    background: "white",
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    marginBottom: 8,
                  }}
                >
                  <input
                    value={adult}
                    onChange={(e) => setAdult(e.target.value)}
                    type="text"
                    style={{
                      width: "100%",
                      height: 29,
                      border: "none",
                      outline: "none",
                    }}
                  />
                </div>
                <div style={{ fontSize: 12, fontWeight: 600, marginBottom: 2 }}>
                  Trẻ em
                </div>
                <div
                  className="asdsf-sa--aaaws"
                  style={{
                    width: "100%",
                    padding: "4px 12px",
                    borderRadius: 10,
                    borderLeft: "2px solid #E27B07",
                    background: "white",
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    marginBottom: 8,
                  }}
                >
                  <input
                    value={children}
                    onChange={(e) => setChildren(e.target.value)}
                    type="text"
                    style={{
                      width: "100%",
                      height: 29,
                      border: "none",
                      outline: "none",
                    }}
                  />
                </div>
                <div
                  onClick={handleClick}
                  style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    borderRadius: 10,
                    backgroundColor: "#E27B07",
                    textTransform: "uppercase",
                    color: "white",
                    fontSize: 13,
                    fontWeight: 600,
                    padding: "4px",
                    cursor: "pointer",
                  }}
                >
                  Đặt vé
                </div>
                <div
                  style={{
                    marginTop: 8,
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <p
                    style={{ color: "#5D5C5C", fontSize: 12, fontWeight: 600 }}
                  >
                    Tổng tiền thanh toán:&nbsp;
                  </p>
                  <p
                    style={{ color: "#D03601", fontWeight: 600, fontSize: 14 }}
                  >
                    {numberWithCommas(
                      ((data?.price * (100 - data?.discountPer)) / 100) *
                        (!isNaN(parseInt(adult)) ? parseInt(adult) : 0) +
                        parseInt(
                          (data?.children_price * (100 - data?.discountPer)) /
                            100
                        ) *
                          (!isNaN(parseInt(children)) ? parseInt(adult) : 0)
                    )}{" "}
                    VND
                  </p>
                </div>
              </div>
              <div></div>
            </div>
          </div>
          {/*  */}
          {/* <DiscoverList /> */}
        </div>
      </div>
      <PopularTour />
    </div>
  );
};

export default DetailTour;

import React from "react";
import MediaQuery from "react-responsive";

const Section4 = (props) => {
  const { Img3, mw } = props;
  return (
    <div style={{ width: "100%" }} className="c-flex-center">
      <div style={{ width: "100%", maxWidth: mw }}>
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
          className="m-flex-column"
        >
          <div
            data-aos="fade-right"
            className="m-pr-0"
            style={{ flex: 1, paddingRight: 20 }}
          >
            <MediaQuery minWidth={625}>
              <div
                style={{
                  padding: "10px 50px 10px 10px",
                  borderBottom: "3px solid #E27B07",
                  width: "max-content",
                  // marginBottom: 24,
                }}
              >
                <p
                  style={{
                    fontSize: 28,
                    fontWeight: 700,
                    color: "#E27B07",
                    textTransform: "uppercase",
                  }}
                >
                  Giá trị cốt lõi
                </p>
              </div>
            </MediaQuery>
            <div
              className="p-10 ashjashjakjaklsas"
              style={{ paddingTop: 10, paddingLeft: 0 }}
            >
              <MediaQuery maxWidth={625}>
                <div
                  style={{
                    padding: "10px 8px 0 0",
                    borderBottom: "3px solid #E27B07",
                    width: "max-content",
                    marginBottom: 24,
                  }}
                >
                  <p
                    style={{
                      fontSize: 18,
                      fontWeight: 700,
                      color: "#E27B07",
                      textTransform: "uppercase",
                    }}
                  >
                    Giá trị cốt lõi
                  </p>
                </div>
              </MediaQuery>
              {/* <div className="tx-18 fw-600">
                GIÁ TRỊ CỐT LÕI ĐỐI VỚI CÔNG TY
              </div> */}
              <div className="tx-18 fw-600" style={{ fontStyle: "italic" }}>
                “Uy tín – Tận Tâm – Chuyên Nghiệp”
              </div>
              {/* <div className="tx-18 fw-600">I. Sự uy tín</div> */}
              <li className="tx-18 tx-jt tx-jt-non" style={{ textIndent: 20 }}>
                <strong>Minh Khang Group</strong> đặt chữ <strong>Tín</strong>{" "}
                lên vị trí hàng đầu. Lấy chữ tín làm kim chỉ nam trong mọi hành
                động.
              </li>
              {/* <div className="tx-18 fw-600">II. Tính chuyên nghiệp</div> */}
              <li className="tx-18 tx-jt tx-jt-non" style={{ textIndent: 20 }}>
                Chữ <strong>Tâm</strong> là nguồn cội của việc thành bại trong
                kinh doanh. Chúng tôi luôn lấy khách hàng làm trung tâm. Đặt lợi
                ích và mong muốn của khách hàng lên hàng đầu; Nổ lực mang đến
                cho khách hàng những trải nghiệm dịch vụ hoàn hảo nhất. Coi sự
                hài lòng của khách hàng là thước đo thành công.
              </li>
              {/* <div className="tx-18 fw-600">III. Tận tâm với khách hàng</div> */}
              <li className="tx-18 tx-jt tx-jt-non" style={{ textIndent: 20 }}>
                Với đội ngũ nhân viên có <strong>chuyên</strong> môn cao, năng
                động, sáng tạo và được đào tạo chuyên nghiệp. Chúng tôi tự tin
                cung cấp đến khách hàng những dịch vụ, những trải nghiệm hoàn
                hảo nhất.
              </li>
              {/* <div className="tx-18 tx-jt">
                Lấy khách hàng làm trọng tâm, động lực để phát triển mỗi ngày. Ý
                thức rõ sứ mệnh phục vụ và thỏa mãn đam mê của khách hàng vì vậy
                luôn tiếp nhận và thực hiện mọi công việc với năng lực và trạng
                thái tốt nhất.
              </div> */}
              <div
                className="tx-18"
                style={{
                  fontSize: 20,
                  marginBottom: 24,
                  textAlign: "justify",
                }}
              ></div>
            </div>
          </div>
          <MediaQuery minWidth={625}>
            <div
              data-aos="fade-left"
              className="ajskahjawisasj djfksdjaklsjkas"
            >
              <img
                className="m-w-100 dajsaksjkswawa"
                src={Img3}
                style={{ width: 450, aspectRatio: 1, objectFit: "cover" }}
                alt=""
              />
            </div>
          </MediaQuery>
          <MediaQuery maxWidth={625}>
            <div className="">
              <img
                className="m-w-100 dajsaksjkswawa skasjakswqeaw"
                src={Img3}
                style={{ width: 400, aspectRatio: 1 }}
                alt=""
              />
            </div>
          </MediaQuery>
        </div>
      </div>
    </div>
  );
};

export default Section4;

import React from "react";
import LogoApp from "../../assets/logo/logo.png";
import LogoAppMobile from "../../assets/logo/logo-mobile.png";
import { Link } from "react-router-dom";
import MediaQuery from "react-responsive";

const Logo = () => {
  return (
    <div className="wrap-header-logo">
      <Link to={"/"}>
        <div className="contain-header-logo">
          <MediaQuery minWidth={625}>
            <img src={LogoApp} style={{ width: "100%" }} alt="Can't open" />
          </MediaQuery>
          <MediaQuery maxWidth={625}>
            <img
              src={LogoAppMobile}
              style={{ width: "100%" }}
              alt="Can't open"
            />
          </MediaQuery>
        </div>
      </Link>
    </div>
  );
};

export default Logo;

import * as React from 'react';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { Menu } from '@mui/material';

export default function SelectBox2(props) {

  const handleChange = (event) => {
    props?.setValue(event.target.value);
  };

  return (
    <Box className={props?.className} sx={{ width: "60%" }}>
      <FormControl fullWidth>
        <label style={{ marginBottom: 8, color: "#898989", fontWeight: 500 }}>{props?.label}</label>
        <Select
          size={"small"}
          value={props?.value}
          onChange={handleChange}
          placeholder={props?.label}
          MenuProps={{disableScrollLock: true}}
          sx={{
              '& .MuiOutlinedInput-notchedOutline': {
                  borderColor: '#e27b07'
              },
              '& .MuiSvgIcon-root': {
                  color: '#e27b07'
              }
          }}
        >
          {props?.list?.map((item, key)=> 
            <MenuItem  key={key} value={item.value}>{item.label}</MenuItem>
          )}
        </Select>
      </FormControl>
    </Box>
  );
}
import React from 'react';
import { Link } from 'react-router-dom';
import { Button, Typography, Container } from '@mui/material';
import { Helmet } from 'react-helmet';

const NotFoundPage = () => {
  return (
    <Container maxWidth="sm" style={{ textAlign: 'center', marginTop: '100px', marginBottom: 200 }}>
      <Helmet>
        <title>Không tìm thấy</title>
        <link rel="canonical" href={window.location.origin + window.location.pathname} />
        <meta
          data-react-helmet="true"
          property="og:url"
          content={window.location.origin + window.location.pathname}
        ></meta>
      </Helmet>
      <Typography variant="h1" gutterBottom>
        404
      </Typography>
      <Typography variant="h4" gutterBottom>
        Oops! Trang bạn đang tìm kiếm không tồn tại.
      </Typography>
      <Button variant="contained" component={Link} to="/" style={{ marginTop: '20px' }}>
        Quay về trang chủ
      </Button>
    </Container>
  );
};

export default NotFoundPage;

import React, { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import {
  apiGetBlogDetail,
  apiGetTicketDetail,
  apiGetTourDetail,
  apiSubmitContact,
} from "../api";
// import Template4 from "../assets/tour/template4.png";
import CreditCard from "../assets/ticket/credit_card.png";
import { animateScroll } from "react-scroll";
import ScrollToTop from "../utils/ScrollToTop";
import { Grid } from "@mui/material";
import numberWithCommas from "../utils/numberWithComma";
import DialogContact from "../components/Dialog/DialogContact";
import swal from "sweetalert";

const PaymentTour = () => {
  const [data, setData] = useState();
  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [address, setAddress] = useState("");
  const [open, setOpen] = useState(false);
  const { id } = useParams();
  const [loading, setLoading] = useState(true);
  const state = useLocation().state;

  useEffect(() => {
    (async () => {
      try {
        setLoading(true);
        const result = await apiGetTourDetail({ id });
        setLoading(false);
        if (result?.ok === true) {
          setData(result?.data?.[0]);
        }
      } catch (error) {
        setLoading(false);
      } finally {
        setLoading(false);
      }
    })();
  }, [id]);

  useEffect(() => {
    animateScroll.scrollToTop({ smooth: false });
  }, [id]);

  const handleClick = async () => {
    if (
      name.length <= 0 ||
      phone.length <= 0 ||
      email.length <= 0 ||
      address.length <= 0
    ) {
      return swal("Thông báo", "Bạn hãy điền đẩy đủ thông tin", "error");
    }
    setOpen(true);
    const result = await apiSubmitContact({
      product: data.name,
      date_send: new Date(),
      status: "waiting for reply",
      phone,
      email,
      address,
      name,
      type: 2,
      kindof: data?.type
      // phone,
      // type: props?.type ? props?.type : "",
      // product: props?.product ? props?.product : ""
    });
    setName("");
    setPhone("");
    setEmail("");
    setAddress("");
  };

  return (
    <div className="container-tour">
      <ScrollToTop />
      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div style={{ width: "100%", maxWidth: 1248, marginBottom: 36 }}>
          <div style={{ textAlign: "center", margin: "24px 0" }}></div>

          <div
            style={{ width: "100%", display: "flex" }}
            className="m-flex-column"
          >
            <Grid container spacing={2}>
              <Grid item xs={4}>
                <div
                  className="djasa-sds-ea-wg-d-sa"
                  style={{
                    width: "100%",
                    border: "1px solid #E27B07",
                    padding: "12px 20px",
                    height: "100%",
                  }}
                >
                  <img
                    className="dsd-sasna-awegd-s"
                    src={data?.photo}
                    style={{ width: "100%", aspectRatio: "4 / 3" }}
                    alt=""
                  />
                  <div style={{ textAlign: "center", marginTop: 12 }}>
                    <p
                      className="text-gra"
                      style={{
                        fontSize: 24,
                        fontWeight: 600,
                        textTransform: "uppercase",
                      }}
                    >
                      {data?.name}
                    </p>
                  </div>
                  <div
                    style={{
                      width: "100%",
                      borderBottom: "1px solid black",
                      marginTop: 16,
                      marginBottom: 16,
                    }}
                  ></div>
                  <br />
                  <div
                    style={{
                      width: "100%",
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <div
                      style={{
                        fontSize: 20,
                        fontWeight: 600,
                        color: "#414040",
                      }}
                    >
                      Ngày đi:
                    </div>
                    <div
                      style={{
                        fontSize: 16,
                        fontWeight: 600,
                        color: "#414040",
                      }}
                    >
                      {state?.timeStart}
                    </div>
                  </div>
                  <div
                    style={{
                      width: "100%",
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <div
                      style={{
                        fontSize: 20,
                        fontWeight: 600,
                        color: "#414040",
                      }}
                    >
                      Người lớn:
                    </div>
                    <div
                      className="text-gra"
                      style={{ fontSize: 16, fontWeight: 600 }}
                    >
                      {numberWithCommas(data?.price * (100 - data?.discountPer) / 100)} x {state?.adult}
                    </div>
                  </div>
                  <div
                    style={{
                      width: "100%",
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <div
                      style={{
                        fontSize: 20,
                        fontWeight: 600,
                        color: "#414040",
                      }}
                    >
                      Trẻ em:
                    </div>
                    <div
                      className="text-gra"
                      style={{ fontSize: 16, fontWeight: 600 }}
                    >
                      {numberWithCommas(data?.children_price)} x{" "}
                      {state?.children}
                    </div>
                  </div>
                  <div
                    style={{
                      width: "100%",
                      borderBottom: "1px solid black",
                      marginTop: 16,
                      marginBottom: 16,
                    }}
                  ></div>
                  <br />
                  <div
                    style={{
                      width: "100%",
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <div
                      style={{
                        fontSize: 20,
                        fontWeight: 600,
                        color: "#414040",
                      }}
                    >
                      Số lượng:
                    </div>
                    <div
                      className="text-gra"
                      style={{ fontSize: 16, fontWeight: 600 }}
                    >
                      {parseInt(state?.children) + parseInt(state?.adult)}
                    </div>
                  </div>
                  <div
                    style={{
                      width: "100%",
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <div
                      style={{
                        fontSize: 20,
                        fontWeight: 600,
                        color: "#414040",
                      }}
                    >
                      Tổng tiền:
                    </div>
                    <div
                      className="text-gra"
                      style={{ fontSize: 16, fontWeight: 600 }}
                    >
                      {numberWithCommas(
                        parseInt(state?.children) *
                          parseInt(data?.children_price) * (100 - data?.discountPer) / 100 +
                          parseInt(state?.adult) * parseInt(data?.price) * (100 - data?.discountPer) / 100
                      )}
                    </div>
                  </div>
                </div>
              </Grid>
              <Grid item xs={8}>
                <div
                  style={{
                    width: "100%",
                    border: "1px solid #E27B07",
                    padding: "12px 20px",
                  }}
                >
                  <div
                    className="sjksdaf-d-as-sa text-gra"
                    style={{
                      fontSize: 20,
                      fontWeight: 600,
                      textTransform: "uppercase",
                    }}
                  >
                    Thông tin cá nhân
                  </div>
                  <br />
                  <br />
                  <Grid container spacing={4}>
                    <Grid item xs={6}>
                      <div
                        style={{
                          fontSize: 20,
                          fontWeight: 600,
                          color: "#414040",
                        }}
                      >
                        Họ và tên:
                      </div>
                      <input
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        type="text"
                        style={{
                          width: "100%",
                          height: 32,
                          border: "1px solid #e7e7e7",
                          padding: 10,
                        }}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <div
                        style={{
                          fontSize: 20,
                          fontWeight: 600,
                          color: "#414040",
                        }}
                      >
                        Điện thoại:
                      </div>
                      <input
                        value={phone}
                        onChange={(e) => setPhone(e.target.value)}
                        type="text"
                        style={{
                          width: "100%",
                          height: 32,
                          border: "1px solid #e7e7e7",
                          padding: 10,
                        }}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <div
                        style={{
                          fontSize: 20,
                          fontWeight: 600,
                          color: "#414040",
                        }}
                      >
                        Email:
                      </div>
                      <input
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        type="text"
                        style={{
                          width: "100%",
                          height: 32,
                          border: "1px solid #e7e7e7",
                          padding: 10,
                        }}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <div
                        style={{
                          fontSize: 20,
                          fontWeight: 600,
                          color: "#414040",
                        }}
                      >
                        Địa chỉ:
                      </div>
                      <input
                        value={address}
                        onChange={(e) => setAddress(e.target.value)}
                        type="text"
                        style={{
                          width: "100%",
                          height: 32,
                          border: "1px solid #e7e7e7",
                          padding: 10,
                        }}
                      />
                    </Grid>
                  </Grid>
                  <br />
                  <br />
                  <div
                    className="sjksdaf-d-as-sa text-gra"
                    style={{
                      fontSize: 20,
                      fontWeight: 600,
                      textTransform: "uppercase",
                    }}
                  >
                    Phương thức thanh toán
                  </div>
                  <Grid container>
                    <Grid item xs={6}>
                      <div
                        style={{
                          width: "100%",
                          borderWidth: "0 1px 1px 1px",
                          borderStyle: "solid",
                          borderColor: "#BAB9B9",
                        }}
                      >
                        <div
                          style={{
                            background:
                              "linear-gradient(90deg, #D03601 0%, #E27B07 100%)",
                            padding: "4px 8px",
                          }}
                        >
                          <div
                            className="djksaj-das-aw-awaw"
                            style={{ display: "flex", alignItems: "center" }}
                          >
                            <img src={CreditCard} alt="" />
                            <div
                              style={{
                                color: "white",
                                marginLeft: 32,
                                fontSize: 14,
                                fontWeight: 500,
                              }}
                            >
                              Thanh toán tại văn phòng
                            </div>
                          </div>
                        </div>
                        <div style={{ padding: "12px 8px" }}>
                          <strong>Địa chỉ:</strong>
                          <span style={{ marginLeft: 12 }}>
                            56-58 Nại Nam, quận Hải Châu, TP Đà Nẵng
                          </span>
                        </div>
                      </div>
                    </Grid>
                  </Grid>
                  <br />
                  <Grid container>
                    <Grid item xs={6}>
                      <div
                        style={{
                          width: "100%",
                          borderWidth: "0 1px 1px 1px",
                          borderStyle: "solid",
                          borderColor: "#BAB9B9",
                        }}
                      >
                        <div
                          style={{
                            background:
                              "linear-gradient(90deg, #D03601 0%, #E27B07 100%)",
                            padding: "4px 8px",
                          }}
                        >
                          <div
                            className="djksaj-das-aw-awaw"
                            style={{ display: "flex", alignItems: "center" }}
                          >
                            <img src={CreditCard} alt="" />
                            <div
                              style={{
                                color: "white",
                                marginLeft: 32,
                                fontSize: 14,
                                fontWeight: 500,
                              }}
                            >
                              Chuyển khoản ngân hàng
                            </div>
                          </div>
                        </div>
                        <div style={{ padding: "12px 8px" }}>
                          <span>Vui lòng liên hệ nhân viên</span>
                        </div>
                      </div>
                    </Grid>
                  </Grid>
                  <br />
                  <br />
                  <div
                    style={{
                      width: "100%",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <div
                      onClick={handleClick}
                      style={{
                        padding: "12px 42px",
                        borderRadius: 10,
                        backgroundColor: "#E27B07",
                        textTransform: "uppercase",
                        fontSize: 20,
                        fontWeight: 600,
                        color: "white",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        cursor: "pointer",
                      }}
                    >
                      Đặt vé
                    </div>
                  </div>
                  <DialogContact open={open} setOpen={setOpen} />
                </div>
              </Grid>
            </Grid>
          </div>
          {/*  */}
          {/* <DiscoverList /> */}
        </div>
      </div>
      {/* <PopularTour /> */}
    </div>
  );
};

export default PaymentTour;

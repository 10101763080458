import React from "react";
import Intro4 from "../../../assets/intro/Intro4.png";
import Intro5 from "../../../assets/intro/intro5.png";
import Img1 from "../../../assets/intro/section2/img1.png";
import Img2 from "../../../assets/intro/section2/img2.png";
import Img3 from "../../../assets/intro/section2/img3.png";
import ScrollToTop from "../../../utils/ScrollToTop";
import B1Image from "./image2/b1.png";
import B2Image from "./image2/b2.png";
import B3Image from "./image2/b3.png";
import B4Image from "./image2/b4.png";
import B5Image from "./image2/b5.png";
import { Image, Swiper, useSwiper } from "react-nivo-slider";
import { setting2 } from "../home/Banner";

import A1Image from "./image5/a1.png";
import A2Image from "./image5/a2.png";
import A3Image from "./image5/a3.png";
import A4Image from "./image5/a4.png";
import A5Image from "./image5/a5.png";

import A11Image from "./image5/a11.png";
import A12Image from "./image5/a12.png";
import A13Image from "./image5/a13.png";

import A21Image from "./image5/a21.png";
import A22Image from "./image5/a22.png";
import A23Image from "./image5/a23.png";
import A24Image from "./image5/a24.png";
import A25Image from "./image5/a25.png";
import A26Image from "./image5/a26.png";
import { GrFormNext, GrFormPrevious } from "react-icons/gr";
import { Helmet } from "react-helmet";

function CustomControl(props) {
  const swiper = useSwiper();

  return (
    <div className="navigation-control">
      <div
        onClick={() => {
          swiper.slidePrev();
        }}
        className="prev-btn-nivo c-flex-center nivo-btn-2"
        style={{
          position: "absolute",
          left: -50,
          top: "50%",
          transform: "translate(0, -50%)",
          zIndex: 99,
        }}
      >
        <GrFormPrevious color={"#e27b07"} size={24} />
      </div>
      <div
        onClick={() => swiper.slideNext()}
        className="next-btn-nivo c-flex-center nivo-btn-2"
        style={{
          position: "absolute",
          right: -50,
          top: "50%",
          transform: "translate(0, -50%)",
          zIndex: 99,
        }}
      >
        <GrFormNext color={"#e27b07"} size={24} />
      </div>
    </div>
  );
}

const Intro2 = () => {
  return (
    <div style={{ width: "100%" }}>
      <Helmet>
        <title>Lĩnh vực hoạt động</title>
        <link rel="canonical" href={window.location.origin + window.location.pathname} />
        <meta data-react-helmet="true" property="og:url" content={window.location.origin + window.location.pathname}></meta>
      </Helmet>
      <ScrollToTop />
      <div
        style={{ width: "100%", position: "relative" }}
        className="banner-intro c-flex-center"
      >
        <Swiper {...setting2}>
          <Image src={B1Image} alt="image1" />
          <Image src={B2Image} alt="image2" />
          <Image src={B3Image} alt="image3" />
          <Image src={B4Image} alt="image4" />
          <Image src={B5Image} alt="image5" />
        </Swiper>
      </div>
      <div
        style={{ width: "100%", margin: "24px 0" }}
        className="c-flex-center p-10"
      >
        <div style={{ width: "100%", maxWidth: 1248 }}>
          <div className="tx-gr tx-20 tt-uc">I. Quản Lý - Vận Hành Tòa Nhà</div>
          {/*  */}
          <div className="tx-18  tx-jt" style={{ marginTop: 12 }}>
            Với kinh nghiệp 5 năm trong lĩnh vực vận hành tòa nhà chúng tôi cam
            kết đưa ra các giải pháp hỗ trợ giúp các chủ đầu tư tăn khả năng
            sinh lợi nhuận và thu hồi vốn nhanh nhất. Minh Khang Home luôn lắng
            nghe và chia sẻ cùng với các Chủ Đầu Tư để đem lợi ích tối đa và xây
            dựng mối quan hệ lâu dài
          </div>
          <div
            className="jasasklkslaasd"
            style={{
              marginTop: 12,
              width: "100%",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <div
              style={{
                width: "100%",
                maxWidth: 700,
                position: "relative",
                zIndex: 10,
              }}
            >
              <Swiper {...setting2}>
                <Image src={A11Image} alt="image1" />
                <Image src={A12Image} alt="image2" />
                <Image src={A13Image} alt="image3" />
                <CustomControl />
              </Swiper>
            </div>
          </div>
          <br />
          <br />
          <div className="tx-gr tx-20 tt-uc">
            II. Đại Lý vé, khách sạn, tour du lịch ... Trên toàn quốc
          </div>
          {/*  */}
          <div className="tx-18 tx-jt" style={{ marginTop: 12 }}>
            Minh Khang Group là đại lý uy tín, chuyên cung cấp vé máy bay, đặt
            phòng và các dịch vụ du lịch trên toàn quốc. Đảm bảo mang đến cho
            khách hàng trải nghiệm dịch vụ hoàn hảo nhất với sự chuyên nghiệp và
            tận tâm.
          </div>
          <div className="tx-18 tx-jt">
            Chúng tôi nhận thức được chất lượng của sản phẩm và dịch vụ quyết
            định đến sự tồn tại của Doanh nghiệp. Chính vì vậy chúng tôi luôn
            chú trọng vào cải thiện chất lượng dịch vụ của mình, duy trì chữ tín
            như là nền tảng cốt lõi của sự phát triển bền vững.
          </div>
          <div className="tx-18 tx-jt">
            Với tinh thần dám nghĩ, dám làm chúng tôi không chỉ đáp ứng nhu cầu
            của khách hàng mà còn vượt qua các thách thức đồng thời tạo ra sự
            đổi mới, tiến bộ liên tục trong hoạt động kinh doanh của chúng tôi.
          </div>
          {/*  */}
          <div
            className="jasasklkslaasd"
            style={{
              marginTop: 12,
              width: "100%",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <div
              style={{
                width: "100%",
                maxWidth: 700,
                position: "relative",
                zIndex: 10,
              }}
            >
              <Swiper {...setting2}>
                <Image src={A1Image} alt="image1" />
                <Image src={A2Image} alt="image2" />
                <Image src={A3Image} alt="image3" />
                <Image src={A4Image} alt="image4" />
                <Image src={A5Image} alt="image5" />
                <CustomControl />
              </Swiper>
            </div>
          </div>
          <div className="tx-gr tx-20 tt-uc">
            III. Kinh doanh BĐS Cho Thuê - Môi Giới BĐS Cho Thuê
          </div>
          {/*  */}
          <div className="tx-18 tx-jt" style={{ marginTop: 12 }}>
            Thành phố Đà Nẵng được mệnh danh là "Thành phố Đáng Sống", chúng tôi
            cung cấp các sản phẩm Bất Động Sản cho thuê chất lượng cao , đáp ứng
            đủ nhu cầu tiện ích , an ninh và tiện nghi của khách hàng.
          </div>
          <div className="tx-18  tx-jt" style={{ marginTop: 12 }}>
            Với các chủ đầu tư, chúng tôi cam kết bảo đảm chất lượng, tăng cường
            chuyên môn để mang cho khách hàng trải nghiệm tốt nhất d và xây dựng
            niềm tin tuyệt đối từ phía nhà đầu tư.
          </div>
          <div
            className="jasasklkslaasd"
            style={{
              marginTop: 12,
              width: "100%",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <div
              style={{
                width: "100%",
                maxWidth: 700,
                position: "relative",
                zIndex: 10,
              }}
            >
              <Swiper {...setting2}>
                <Image src={A21Image} alt="image1" />
                <Image src={A22Image} alt="image2" />
                <Image src={A23Image} alt="image3" />
                <Image src={A24Image} alt="image4" />
                <Image src={A25Image} alt="image5" />
                <Image src={A26Image} alt="image6" />
                <CustomControl />
              </Swiper>
            </div>
          </div>
          <div></div>
          <br />
          <br />
        </div>
        <br />
        <br />
      </div>
    </div>
  );
};

export default Intro2;

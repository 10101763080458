import React from "react";
import MediaQuery from "react-responsive";

const Section6 = (props) => {
  const { Img23, mw } = props;

  return (
    <div
      style={{ width: "100%" }}
      className="jdkasjkasujeiaas"
      data-aos="fade-up"
    >
      <div style={{ width: "100%", marginTop: 24 }} className="c-flex-center">
        <div style={{ width: "100%", maxWidth: mw }}>
          <div
            style={{ width: "100%", borderBottom: "1px solid #e7e7e7" }}
            className="c-flex-center"
          >
            <div
              style={{
                padfding: 10,
                borderBottom: "3px solid #D03601",
                marginBottom: -2,
              }}
              className="c-flex-center"
            >
              <MediaQuery minWidth={625}>
                <p
                  style={{
                    fontWeight: 700,
                    textTransform: "uppercase",
                    fontSize: 28,
                    background:
                      "linear-gradient(180deg, #D03601 0%, #E27B07 100%)",
                    backgroundClip: "text",
                    color: "transparent",
                  }}
                  className="tx-18"
                >
                  Thế mạnh của công ty
                </p>
              </MediaQuery>
              <MediaQuery maxWidth={625}>
                <p
                  style={{
                    fontSize: 18,
                    fontWeight: 700,
                    color: "#E27B07",
                    textTransform: "uppercase",
                  }}
                >
                  Thế mạnh của công ty
                </p>
              </MediaQuery>
            </div>
          </div>
          <div style={{ width: "100%", paddingTop: 32 }}>
            <div style={{ width: "100%" }} className="c-flex-center">
              <div style={{ width: "100%", maxWidth: mw }}>
                <div style={{ width: "100%", paddingLeft: 0, paddingRight: 0 }} className="c-flex-center p-10">
                  <div style={{ marginBottom: 40 }}>
                    <p className="tx-18 tx-jt tx-jt-non">
                      Với đội ngũ cán bộ Lãnh đạo có trình độ chuyên môn cao, có
                      quyết tâm phát triển nghề nghiệp, 5 năm kinh nghiệm công
                      tác, khả năng tư duy logic, phán đoán nhanh nhạy, phân
                      tích và giải quyết vấn đề hiệu quả. Những thành viên đó đã
                      hợp nhất cùng chung mục tiêu là sự phát triển chung của
                      công ty.
                    </p>
                    <li className="tx-18 tx-jt tx-jt-non" style={{textIndent: 40}}>
                      Ngôi nhà Group với một mội tường làm việc năng động, sáng
                      tạo, đoàn kết, chuyên nghiệp, chúng tôi tự tin mang đến
                      cho khách hàng những trải nghiệm ấn tượng tuyệt vời nhất
                      góp phần tạo nên sự uy tín, đẳng cấp của <strong>Minh Khang Group </strong>
                      trên thị trường quốc tế.
                    </li>
                    <li className="tx-18 tx-jt tx-jt-non" style={{textIndent: 40}}>
                      <strong>Minh Khang Group</strong> chuyên cung cấp giải pháp nhằm tiết kiệm
                      thời gian, nâng cao hiệu quả, mang lại giá trị tối đa cho
                      khách hàng và đối tác. Hoạt động chính ở lĩnh vực kinh
                      doanh bất động sản cho thuê, các gói vận hành toà nhà, toà
                      khách sạn, đại lý vé và các tour du lịch trong và ngoài
                      nước. Chúng tôi không chỉ là nơi mà nhà đầu tư và khách
                      hàng đặt niềm tin trọn vẹn mà còn là điểm mang đến trải
                      nghiệm dịch vụ toàn diện nhất góp phần xây dựng và phát
                      triển xã hội.
                    </li>
                  </div>
                </div>
                <div style={{ width: "100%" }} className="c-flex-center"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        style={{ width: "100%", marginTop: 24, marginBottom: 24 }}
        className="c-flex-center"
      >
        <div style={{ width: "100%", maxWidth: 1248 }}>
          <img
            src={Img23}
            style={{ width: "100%", objectFit: "cover" }}
            alt=""
          />
        </div>
      </div>
    </div>
  );
};

export default Section6;

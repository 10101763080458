import React from "react";
import MediaQuery from "react-responsive";
import { NavLink } from "react-router-dom";

const NavigationBanner = (props) => {
  const navigationMenu = [
    { link: "/can-ho-phong-cho-thue", text: "Cho thuê căn hộ" },
    { link: "/dat-phong-khach-san", text: "Đặt phòng khách sạn" },
    { link: "/tour-du-lich", text: "Tour du lịch" },
  ];
  return (
    <>
      <MediaQuery minWidth={625}>
        <div className="wrap-navigation-banner">
          {navigationMenu?.map((item, key) => (
            <div
              key={key}
              className={`container-navigation-banner c-flex-center bh-ho ${parseInt(props?.activeSlideIndex)=== parseInt(key) ? `bh-ho-active bh-ho-active-${key}` : `bh-ho-${key}`}`}
            >
              <NavLink
                className="container-link-navigation-banner c-flex-center"
                to={item.link}
              >
                <p className={`text-trad fw-700 tt-uc ${parseInt(props?.activeSlideIndex)=== parseInt(key) && "bh-ho-mb-p"}`}>{item.text}</p>
              </NavLink>
            </div>
          ))}
        </div>
      </MediaQuery>
      <MediaQuery maxWidth={625}>
        <div className="wrap-navigation-banner-mb c-flex-center p-10 m-g-10">
          {navigationMenu?.map((item, key) => (
            <div
              key={key}
              className={`container-navigation-banner-mb c-flex-center bh-ho m-mr-0 ${parseInt(props?.activeSlideIndex)=== parseInt(key) && "bh-ho-mb"}`}
            >
              <NavLink
                className="container-link-navigation-banner c-flex-center"
                to={item.link}
              >
                <p className={`text-trad fw-700 m-fw-w500 tt-uc m-fs-12 ${parseInt(props?.activeSlideIndex)=== parseInt(key) && "bh-ho-mb-p"}`}>
                  {item.text}
                </p>
              </NavLink>
            </div>
          ))}
        </div>
      </MediaQuery>
    </>
  );
};

export default NavigationBanner;

import React from "react";
// import ItemImage from "../../../assets/apartment/item.png";
import { Link, useNavigate } from "react-router-dom";
import moment from "moment"
import createSlug from "../../utils/createSlug";

const ItemTravelGuide = (props) => {
  // const navigate= useNavigate()

  // const handleClick= ()=> {
  //   navigate("/blog/detail/"+ createSlug(props?.name)?.split(" ")?.join("-") + "-" + props?.id)
  // }

  return (
    <div style={{ width: "100%" }} className="m-pb-12">
      <Link style={{color: "unset", textDecoration: "none"}} to={"/blog/detail/"+ createSlug(props?.name)?.split(" ")?.join("-") + "-" + props?.id}>
        <div
          style={{
            width: "100%",
            boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
            borderRadius: 10,
            cursor: "pointer",
            background: "#fff",
            overflow: "hidden"
          }}
          // onClick={handleClick}
        >
          <div style={{ width: "100%", overflow: "hidden", marginBottom: 4 }}>
            <img
              loading={"lazy"}
              src={props?.photo}
              alt=""
              style={{ width: "100%", aspectRatio: props?.ratio, objectFit: "cover" }}
            />
          </div>
          <div style={{ padding: 10, width: "100%" }}>
            <div
              className="text-trad tr-2l"
              style={{
                fontSize: props?.fs,
                fontWeight: 800,
                height: props?.h,
                marginBottom: 4,
              }}
            >
              {props?.name || "Unknown"}
            </div>
            <div title={props?.desc} style={{color: "#000"}} className="tr-2l h-48p">{props?.desc}</div>
            <div className="tr-1l" title={moment(props?.time_created).format("DD-MM-YYYY")} style={{color: "#000", marginTop: 8, fontSize: 12}}>{moment(props?.time_created).format("DD-MM-YYYY")}</div>
          </div>
        </div>
      </Link>
    </div>
  );
};

export default ItemTravelGuide;

import moment from "moment";
import React from "react";
import { Link, useNavigate } from "react-router-dom";
import createSlug from "../../../utils/createSlug";

const ItemArticle = (props) => {
  const navigate= useNavigate()
  const handleClick= ()=> {
    navigate("/blog/detail/"+ createSlug(props?.name)?.split(" ")?.join("-") + "-"  + props?.id)
  }
  return (
    <div className="container-article-item" style={{ width: "100%", borderRadius: 20, padding: 30 }}>
      <Link style={{color: "unset", textDecoration: "none"}} to={"/blog/detail/"+ createSlug(props?.name)?.split(" ")?.join("-") + "-"  + props?.id}>
        <div
          className="wrap-article-item"
          style={{
            width: "100%",
            borderRadius: 20,
            background: "#fff",
            padding: 16,
            border: "1px solid #E27B07",
            cursor: "pointer"
          }}
          onClick={handleClick}
        >
          <div style={{ width: "100%", display: "flex" }}>
            <div style={{ flex: 1, paddingRight: 10 }}>
              <div
                className="tr-1l"
                style={{
                  fontSize: 16,
                  fontWeight: 700,
                  marginBottom: 18,
                  height: 24
                }}
              >
                {props?.name}
              </div>
              <div className="tr-1l" style={{ fontSize: 13, marginBottom: 8, height: 20 }}>
                {props?.desc}
              </div>
              <div style={{ fontSize: 10, color: "#A7A7A7" }}>{moment(props?.time_created).format("DD-MM-YYYY")}</div>
            </div>
            <div style={{ width: 70 }}>
              <img
                src={props?.photo}
                style={{ width: "100%", aspectRatio: 3 / 4, borderRadius: 10 }}
                alt=""
              />
            </div>
          </div>
        </div>
      </Link>
    </div>
  );
};

export default ItemArticle;

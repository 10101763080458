import React from "react";
import MediaQuery from "react-responsive";

const Section3 = (props) => {
  const { Img21, mw } = props;
  return (
    <div style={{ width: "100%" }} className="c-flex-center jaklsjkalsjajas">
      <div style={{ width: "100%", maxWidth: mw }}>
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginTop: 20,
          }}
          className="m-flex-column dnjashjasjksl"
        >
          <MediaQuery minWidth={625}>
            <div
              data-aos="fade-right"
              className="ahsjahsjaslsew hasdsjhklasjsa"
            >
              <img
                className="dajsaksjkswawa ajkslajsilkasas"
                src={Img21}
                style={{ width: 400, aspectRatio: 1, objectFit: "cover" }}
                alt=""
              />
            </div>
          </MediaQuery>
          <div
            data-aos="fade-left"
            className="wrap-mission-main-2 m-pl-0"
            style={{ flex: 1, paddingLeft: 100 }}
          >
            <MediaQuery minWidth={625}>
              <div
                style={{
                  padding: "10px 50px 10px 10px",
                  borderBottom: "3px solid #E27B07",
                  width: "max-content",
                  marginBottom: 24,
                }}
              >
                <p
                  style={{
                    fontSize: 28,
                    fontWeight: 700,
                    color: "#E27B07",
                    textTransform: "uppercase",
                  }}
                >
                  Sứ mệnh
                </p>
              </div>
            </MediaQuery>
            <div
              className="p-10 sa-ssa-fdssda0sdega-assa"
              style={{ paddingRight: 0, paddingTop: 10, paddingLeft: 5 }}
            >
              <MediaQuery maxWidth={625}>
                <div
                  style={{
                    padding: "10px 8px 0 0",
                    borderBottom: "3px solid #E27B07",
                    width: "max-content",
                    marginBottom: 24,
                  }}
                >
                  <p
                    style={{
                      fontSize: 18,
                      fontWeight: 700,
                      color: "#E27B07",
                      textTransform: "uppercase",
                    }}
                  >
                    Sứ mệnh
                  </p>
                </div>
              </MediaQuery>
              <li className="tx-18 tx-jt tx-jt-non">
                <strong>
                  “Là đối tác Uy Tín hàng đầu tại Việt Nam cũng như là Người Bạn
                  đồng hành cùng du khách trong mọi nẻo đường”
                </strong>{" "}
              </li>
              <li className="tx-18 tx-jt tx-jt-non" style={{ textIndent: 40 }}>
                Với biểu tượng là chú chim bồ câu trắng đang tung cánh bay về
                phía trước mạnh mẽ trên bầu trời đầy nắng – Là hình tượng cho sự
                hoà bình, tự do, bình đẳng và là đặc trưng cho trí thức con
                người, là biểu tượng của sự kết nối, sự thống nhất. Bố trí xen
                kẽ với chú chim là màu vàng của ánh nắng ban mai thể hiện sự nhẹ
                nhàng, ấm áp, chăm chỉ, chu đáo và tươm tất mà dịch vụ{" "}
                <strong>Minh Khang Group</strong> mang đến cho khách hàng.
              </li>
              <li className="tx-18 tx-jt tx-jt-non" style={{ textIndent: 40 }}>
                Chúng tôi không chỉ mang đến cho khách hàng những trải nghiệm
                hoàn hảo nhất thông qua dịch vụ vận hành bất động sản dòng tiền
                – du lịch lữ hành mà còn là một sứ giả hoà bình lan toả tình
                yêu, hạnh phúc, khát vọng mang thương hiệu Việt vươn tầm ra khu
                vực và trên thế giới.
              </li>
            </div>
            <MediaQuery maxWidth={625}>
              <div className="">
                <img
                  className="m-w-100 dajsaksjkswawa skasjakswqeaw"
                  src={Img21}
                  style={{ width: 400, aspectRatio: 1 }}
                  alt=""
                />
              </div>
            </MediaQuery>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Section3;

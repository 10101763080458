import React from "react";
// import FooterImage from "../../assets/footer/footer.png"
import LogoFooter from "../../assets/footer/logo.png";
import PhoneIcon from "../../assets/footer/phone.png";
import WebIcon from "../../assets/footer/web.png";
import MailIcon from "../../assets/footer/mail.png";
import FbIcon from "../../assets/footer/fb.png";
import MediaQuery from "react-responsive";

const Footer = () => {
  const handleClick = (type) => {
    switch (type) {
      case 1:
        window.location.href = "tel:+0905490266";
        break;
      case 2:
        window.location.href = "mailto:info.minhkhanggroup@gmail.com";
        break;
      case 3:
        window.location.href = "https://www.facebook.com/minhkhanggroup";
        break;
      case 4:
        window.location.href = "https://minhkhanggroup.vn";
        break;
      case 5:
        window.location.href = "tel:+0968068138";
        break;
      default:
        break;
    }
  };
  return (
    <footer
      style={{
        width: "100%",
      }}
      className="c-flex-center container-footer"
    >
      <div
        style={{ width: "100%", marginTop: 30 }}
        className="c-flex-center wrap-footer"
      >
        <div style={{ width: "100%", maxWidth: 1200 }}>
          <div style={{ width: "100%", padding: "30px 0" }}>
            <div
              className="main-footer"
              style={{ width: "100%", display: "flex", alignItems: "center" }}
            >
              <MediaQuery minWidth={625}>
                <div
                  className="map-web"
                  style={{
                    width: 200,
                    aspectRatio: 4 / 3,
                    borderRadius: 10,
                    overflow: "hidden",
                  }}
                >
                  <iframe
                    title="map"
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3834.5873170975397!2d108.22903537557997!3d16.034984284639652!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x31421973f093875f%3A0xc2bf489bfbf82e96!2zNTYgTuG6oWkgTmFtLCBIb8OgIEPGsOG7nW5nIELhuq9jLCBI4bqjaSBDaMOidSwgxJDDoCBO4bq1bmcsIFZp4buHdCBOYW0!5e0!3m2!1svi!2s!4v1706475131346!5m2!1svi!2s"
                    width={200}
                    style={{ border: 0 }}
                    allowFullScreen
                    loading="lazy"
                    referrerPolicy="no-referrer-when-downgrade"
                  />
                </div>
                <div style={{ marginLeft: 50, opacity: 0 }}>
                  <div
                    className="logo-footer-web"
                    style={{ width: 100, aspectRatio: 1 }}
                  >
                    <img
                      src={LogoFooter}
                      style={{ width: "100%", aspectRatio: 1 }}
                      alt="Can't open"
                    />
                  </div>
                </div>
              </MediaQuery>
              <div className="wrap-main-footer" style={{ marginLeft: 50 }}>
                <div className="wrap-text-footer" style={{ display: "flex" }}>
                  <div>
                    <div
                      style={{
                        fontSize: 20,
                        fontWeight: 900,
                        color: "#fff",
                        marginBottom: 12,
                      }}
                      className="tt-uc"
                    >
                      Thông tin liên hệ
                    </div>
                    <div>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          marginBottom: 8,
                          cursor: "pointer",
                        }}
                        onClick={() => handleClick(5)}
                      >
                        <img src={PhoneIcon} alt="" />
                        <p
                          style={{ fontSize: 16, color: "#fff", marginLeft: 6 }}
                        >
                          0968.068.138
                        </p>
                      </div>
                      {/*  */}
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          marginBottom: 8,
                          cursor: "pointer",
                        }}
                        onClick={() => handleClick(2)}
                      >
                        <img src={MailIcon} alt="" />
                        <p
                          style={{ fontSize: 16, color: "#fff", marginLeft: 6 }}
                        >
                          info.minhkhanggroup@gmail.com
                        </p>
                      </div>
                      {/*  */}
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          marginBottom: 8,
                        }}
                      >
                        <img src={FbIcon} alt="" />
                        <p
                          style={{
                            fontSize: 16,
                            color: "#fff",
                            marginLeft: 6,
                            // textDecoration: "underline",
                            cursor: "pointer",
                          }}
                          onClick={() => handleClick(3)}
                        >
                          Minh Khang Group
                        </p>
                      </div>
                      {/*  */}
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          marginBottom: 8,
                          cursor: "pointer",
                        }}
                        onClick={() => handleClick(4)}
                      >
                        <img src={WebIcon} alt="" />
                        <p
                          style={{ fontSize: 16, color: "#fff", marginLeft: 6 }}
                        >
                          minhkhanggroup.vn
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="text-headquarter" style={{ marginLeft: 50 }}>
                    <div
                      style={{
                        fontSize: 20,
                        fontWeight: 900,
                        color: "#fff",
                        marginBottom: 12,
                      }}
                      className="tt-uc"
                    >
                      Trụ sở & Chi nhánh
                    </div>
                    <div>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          marginBottom: 8,
                        }}
                      >
                        <p
                          className="m-ml-0"
                          style={{ fontSize: 16, color: "#fff", marginLeft: 6 }}
                        >
                          Địa chỉ: 04 Phước Tường 6, quận Cẩm Lệ, TP Đà Nẵng
                        </p>
                      </div>
                      {/*  */}
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          marginBottom: 8,
                        }}
                      >
                        <p
                          className="m-ml-0"
                          style={{ fontSize: 16, color: "#fff", marginLeft: 6 }}
                        >
                          Văn phòng đại diện: 56-58 Nại Nam, quận Hải Châu, TP Đà Nẵng
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;

import axios from "axios"
import { API_URL } from "./config"

const getListProvince = async () => {
  try {
    const res = await axios({
      url: "https://province.minhkhanggroup.vn/api/v1/province",
      method: "get"
    })
    const result = await res.data?.results
    return result

  } catch (error) {
    console.log(error)
  }
}

// get list district

export const apiGetProvince = async (provinceCode) => {
  try {
    const res = await axios({
      url: `https://province.minhkhanggroup.vn/api/v1/province/district/${provinceCode}`,
      method: "get",
    })
    const result = await res.data
    return result
  } catch (error) {
    console.log(error)
  }
}

export const apiGetWard = async (districtCode) => {
  try {
    const res = await axios({
      url: `https://province.minhkhanggroup.vn/api/v1/province/ward/${districtCode}`
    })
    const result = await res.data
    return result
  } catch (error) {
    console.log(error)
  }
}

export const apiSearchResult = async (data) => {
  try {
    const res = await axios({
      url: API_URL + "/api/v1/search",
      method: "get",
      params: {
        ...data
      }
    })
    const result = await res.data
    return result
  } catch (error) {
    console.log(error)
  }
}

export const apiSearchFilter = async (data) => {
  try {
    const res = await axios({
      url: API_URL + "/api/v1/product/filter",
      method: "get",
      params: {
        ...data
      }
    })
    const result = await res.data
    return result
  } catch (error) {
    console.log(error)
  }
}

export const apiGetCategory = async (data) => {
  try {
    const res = await axios({
      url: API_URL + "/api/v1/category",
      method: "get",
      params: {
        ...data
      }
    })
    const result = await res.data
    return result

  } catch (error) {
    console.log(error)

  }
}

export const getDetailProduct = async (data) => {
  try {
    const res = await axios({
      url: API_URL + "/api/v1/product/d",
      method: "get",
      params: {
        ...data
      }
    })
    const result = await res.data
    return result

  } catch (error) {
    console.log(error)
  }
}

export const getProductByCategory = async (data) => {
  try {
    const res = await axios({
      url: API_URL + "/api/v1/product/c/c",
      method: "get",
      params: {
        ...data
      }
    })
    const result = await res.data
    return result
  } catch (error) {
    console.log(error)
  }
}

export const getProductSuggestListHotel = async (data) => {
  try {
    const res = await axios({
      url: API_URL + "/api/v1/product/s/h",
      method: "get",
    })
    const result = await res.data
    return result
  } catch (error) {
    console.log(error)
  }
}

export const getProductSuggestListTour = async (data) => {
  try {
    const res = await axios({
      url: API_URL + "/api/v1/tour/s/t",
      method: "get",
    })
    const result = await res.data
    return result
  } catch (error) {
    console.log(error)
  }
}

export const getProductSuggestListApartment = async (data) => {
  try {
    const res = await axios({
      url: API_URL + "/api/v1/product/s/a",
      method: "get",
    })
    const result = await res.data
    return result
  } catch (error) {
    console.log(error)
  }
}

export const apiSubmitContact = async (data) => {
  const res = await axios({
    url: API_URL + "/api/contact",
    method: "post",
    data: {
      ...data
    }
  })
  const result = await res.data
  return result
}

export const apiGetListTour = async (data) => {
  try {
    const res = await axios({
      url: API_URL + "/api/v1/tour",
      params: {
        isFrom: "client",
      },
      method: "get",
    })
    const result = await res.data
    return result
    
  } catch (error) {
    console.log(error)
  }
}

export const apiGetTourCategory = async (data) => {
  try {
    const res = await axios({
      url: API_URL + "/api/v1/tour/c",
      method: "get",
      params: {
        ...data,
        isFrom: "client"
      }
    })
    const result = await res.data
    return result
    
  } catch (error) {
    console.log(error)
  }
}

export const apiGetTourDetail = async (data) => {
  try {
    const res = await axios({
      url: API_URL + "/api/v1/tour/d",
      method: "get",
      params: {
        ...data
      }
    })
    const result = await res.data
    return result 
    
  } catch (error) {
    console.log(error)
  }
}

export const apiGetTourDetailClient = async (data) => {
  try {
    const res = await axios({
      url: API_URL + "/api/v1/tour/d/c",
      method: "get",
      params: {
        ...data
      }
    })
    const result = await res.data
    return result 
    
  } catch (error) {
    console.log(error)
  }
}

export const apiGetBlogDetail = async (data) => {
  try {
    const res = await axios({
      url: API_URL + "/api/v1/blog/d",
      method: "get",
      params: {
        ...data
      }
    })
    const result = await res.data
    return result 
    
  } catch (error) {
    console.log(error)
  }
}

export const apiGetTicketDetail = async (data) => {
  try {
    const res = await axios({
      url: API_URL + "/api/v1/ticket/d",
      method: "get",
      params: {
        ...data
      }
    })
    const result = await res.data
    return result 
    
  } catch (error) {
    console.log(error)
  }
}

export const apiGetBlogType= async (data)=> {
  try {
    const res= await axios({
      url: API_URL + "/api/v1/blog/t",
      method: "get",
      params: {
        ...data,
        isFrom: "client"
      }
    })
    const result= await res.data
    return result
  } catch (error) {
    console.log(error)
  }
}

export const apiGetDetailBlogType= async (data)=> {
  try {
    const res= await axios({
      url: API_URL + "/api/v1/blog/t/d",
      method: "get",
      params: {
        ...data
      }
    })
    const result= await res.data
    return result
  } catch (error) {
    console.log(error)
  }
}

export const apiGetSuggestProduct= async (data)=> {
  try {
    const res= await axios({
      url: API_URL + "/api/v1/product/sg",
      method: "get",
      params: {
        ...data,
        isFrom: "client"
      }
    })
    const result= await res.data
    return result
  } catch (error) {
    console.log(error)
  }
}

export const apiGetTicket= async (data)=> {
  try {
    const res= await axios({
      url: API_URL + "/api/v1/ticket",
      method: "get",
      params: {
        ...data
      }
    })
    const result= await res.data
    return result
  } catch (error) {
    console.log(error)
    
  }
}

export { getListProvince }
import React, { useEffect, useState } from "react";
// import Intro5 from "../../../assets/intro/intro5.png";
import Intro7 from "../../../assets/intro/intro7.png";
import { Grid } from "@mui/material";
// import Img1 from "../../../assets/intro/section3/img1.png";
// import Img2 from "../../../assets/intro/section3/img2.png";
import { apiGetBlogType } from "../../../api";
// import ItemTravelGuide from "../../TravelGuide/ItemTravelGuide";
import ItemInternal from "../../Internal/ItemInternal";
import LoadingGuideLine from "../../Loading/LoadingGuideLine";
import { setting2 } from "../home/Banner";
import { Image, Swiper } from "react-nivo-slider";
import B1Image from "./image4/b1.png"
import B2Image from "./image4/b2.png"
import B3Image from "./image4/b3.png"   
import { Helmet } from "react-helmet";


const TinTucNoiBat = () => {
  const [type, setType] = useState(14);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState();

  useEffect(() => {
    (async () => {
      try {
        setLoading(true);
        const result = await apiGetBlogType({ type });
        setLoading(false);
        if (result?.ok === true) {
          setData(result?.data);
        }
      } catch (error) {
        setLoading(false);
        console.log(error);
      } finally {
        setLoading(false);
      }
    })();
  }, [type]);

  return (
    <div style={{ width: "100%" }}>
      <Helmet>
        <title>Tin tức nổi bật</title>
        <link rel="canonical" href={window.location.origin + window.location.pathname} />
        <meta
          data-react-helmet="true"
          property="og:url"
          content={window.location.origin + window.location.pathname}
        ></meta>
      </Helmet>
      <div
        style={{ width: "100%", position: "relative" }}
        className="banner-intro c-flex-center"
      >
        <Swiper {...setting2}>
            <Image src={B1Image} alt="image1" />
            <Image src={B2Image} alt="image2" />
            <Image src={B3Image} alt="image3" />
          </Swiper>
      </div>
      <div
        style={{ width: "100%", margin: "24px 0" }}
        className="c-flex-center"
      >
        <div style={{ width: "100%", maxWidth: 1248 }}>
          <div
            style={{
              flex: 1,
              paddingRight: 20,
              // maxWidth: "calc(100% - 300px)",
            }}
            className="m-pr-0 p-10"
          >
            {loading === false && (
              <div style={{ width: "100%" }}>
                <Grid container spacing={2} mb={3}>
                  {data?.slice(0, 1)?.map((item, key) => (
                    <Grid key={key} item xs={12} sm={12}>
                      <ItemInternal fs={24} h={40} ratio={2.25} {...item} />
                    </Grid>
                  ))}
                  {data?.slice(1)?.map((item, key) => (
                    <Grid key={key} item xs={6} sm={4}>
                      <ItemInternal fs={18} h={30} ratio={4 / 3} {...item} />
                    </Grid>
                  ))}
                </Grid>
                {loading === false && data?.length <= 0 && (
                  <Grid item xs={12} mt={2}>
                    <div style={{ textAlign: "center", fontSize: 20 }}>
                      Không tìm thấy kết quả trùng khớp, Bạn hãy thử lại
                    </div>
                  </Grid>
                )}
              </div>
            )}
            {loading === true && <LoadingGuideLine />}
          </div>
        </div>
      </div>
    </div>
  );
};

export default TinTucNoiBat;

import React, { useEffect, useState } from "react";
// import ItemImage from "../../assets/apartment/item.png"
import { Grid } from "@mui/material";
import { getProductSuggestListTour } from "../../api";
import ItemTour from "../page/Tour/ItemTour";
import MediaQuery from "react-responsive";

const PopularTour = () => {
  const [data, setData] = useState([]);
  useEffect(() => {
    (async () => {
      try {
        const result = await getProductSuggestListTour();
        if (result?.success === true) {
          setData(result?.data);
        }
      } catch (error) {
        console.log(error);
      }
    })();
  }, []);
  return (
    <div style={{ width: "100%", marginTop: 24 }} className="c-flex-center">
      <div style={{ width: "100%" }}>
        <div
          style={{ width: "100%", borderBottom: "1px solid #e7e7e7" }}
          className="c-flex-center"
        >
          <div
            style={{
              padding: 10,
              borderBottom: "3px solid #D03601",
              marginBottom: -2,
            }}
            className="c-flex-center"
          >
            <MediaQuery minWidth={625}>
              <p
                style={{
                  fontWeight: 700,
                  textTransform: "uppercase",
                  fontSize: 32,
                }}
                className="text-gra"
              >
                Dịch vụ nổi bật
              </p>
            </MediaQuery>
            <MediaQuery maxWidth={625}>
              <p
                style={{
                  fontWeight: 700,
                  color: "#E27B07",
                  textTransform: "uppercase",
                  fontSize: 18,
                }}
              >
                Dịch vụ nổi bật
              </p>
            </MediaQuery>
          </div>
        </div>
        <div
          className="p-10"
          style={{
            width: "100%",
            paddingTop: 32,
            marginBottom: 32,
            background:
              "linear-gradient(180deg, rgba(208, 54, 1, 0) 0%, rgba(208, 54, 1, 0.08) 26%, rgba(222, 106, 6, 0.19) 75%, rgba(226, 123, 7, 0) 100%)",
          }}
        >
          <div style={{ width: "100%" }} className="c-flex-center">
            <div style={{ width: "100%", maxWidth: 1248 }}>
              <Grid spacing={2} container>
                {data?.map((item, key) => (
                  <Grid key={key} item xs={6} sm={3}>
                    <ItemTour {...item} />
                  </Grid>
                ))}
              </Grid>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PopularTour;

import { Grid, Skeleton } from "@mui/material";
import React from "react";

const LoadingHotel = () => {
  return (
    <div style={{ width: "100%" }}>
      <Grid container spacing={2}>
        {Array.from(Array(9).keys()).map((item, key) => (
          <Grid key={key} xs={4} item>
            <Skeleton
              sx={{ bgcolor: "grey.400" }}
              width={"100%"}
              variant="rounded"
              height={350}
            />
          </Grid>
        ))}
      </Grid>
    </div>
  );
};

export default LoadingHotel;

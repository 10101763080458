import React, { useEffect, useState } from "react";
import ScrollToTop from "../utils/ScrollToTop";
import Banner11 from "../assets/tour/banner11.png";
import {
  FormControl,
  FormControlLabel,
  Grid,
  MenuItem,
  Radio,
  RadioGroup,
  TextField,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import DiscoverList from "../components/DiscoverList/DiscoverList";
import { createSearchParams, useNavigate } from "react-router-dom";
import { apiGetBlogType, apiSubmitContact, getListProvince } from "../api";
import { listAdult, listChildren, listInfants } from "../data/data";
import swal from "sweetalert";
import AdvisePopup2 from "../components/Advise/AdvisePopup2";
import { Image, Swiper } from "react-nivo-slider";
import { setting2 } from "../components/page/home/Banner";
import B1Image from "./image5/b1.png";
import B2Image from "./image5/b2.png";
import B3Image from "./image5/b3.png";
import AirPlaneRule from "../components/AirPlane/AirPlane";
import { Helmet } from "react-helmet";

const Booking = () => {
  const navigate = useNavigate();
  const typeBlog = 12;
  const [data, setData] = useState();
  useEffect(() => {
    (async () => {
      const result = await apiGetBlogType({ type: typeBlog });
      setData(result?.data);
    })();
  }, []);
  const [open, setOpen] = useState(false);
  const [departure, setDeparture] = useState();
  const [departureText, setDepartureText] = useState();
  const [destination, setDestination] = useState();
  const [destinationText, setDestinationText] = useState();
  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [adult, setAdult] = useState();
  const [children, setChildren] = useState();
  const [baby, setBaby] = useState();
  const [type, setType] = useState();

  const useStyles = makeStyles({
    horizontalRadioGroup: {
      display: "flex",
      flexDirection: "row !important",
      gap: 20,
    },
  });
  const classes = useStyles();
  const [value, setValue] = useState();
  const [listProvince, setListProvince] = useState([]);

  useEffect(() => {
    (async () => {
      const result = await getListProvince();
      setListProvince(result);
    })();
  }, []);
  const handleChange = (event) => {
    setValue(event.target.value);
  };

  const handleClick = async () => {
    const data = {
      typeBooking: 4,
    };
    if (phone?.length <= 0 || name?.length <= 0) {
      swal("Thông báo", "Bạn hãy điền đầy đủ thông tin để tìm kiếm");
    } else {
      const result = await apiSubmitContact({
        name,
        date_send: new Date(),
        status: "waiting for reply",
        phone,
        type: 4,
        product: `Vé máy bay từ ${departureText} đến ${destinationText} (gồm ${adult} người lớn, ${children} trẻ em, ${baby} em bé)`,
        departureText,
        destinationText,
        guest: `${adult} người lớn, ${children} trẻ em, ${baby} em bé`,
        trip: value,
      });
      if (result.ok === true) {
        swal("Thông báo", "Đã gửi thành công", "success").then(() => {
          setName("");
          setPhone("");
        });
      } else {
        swal("Thông báo", "Gửi thất bại", "error");
      }
    }
  };

  return (
    <div
      style={{
        width: "100%",
      }}
    >
      <Helmet>
        <title>Vé máy bay</title>
        <link rel="canonical" href={window.location.origin + window.location.pathname} />
        <meta
          data-react-helmet="true"
          property="og:url"
          content={window.location.origin + window.location.pathname}
        ></meta>
      </Helmet>
      <ScrollToTop />
      <div
        className="banner-hotel"
        style={{ width: "100%", position: "relative" }}
      >
        <Swiper {...setting2}>
          <Image src={B1Image} alt="image1" />
          <Image src={B2Image} alt="image2" />
          <Image src={B3Image} alt="image3" />
        </Swiper>
        <div
          style={{
            position: "absolute",
            left: "50%",
            top: "49%",
            transform: "translate(-50%, -50%)",
          }}
        >
          {/* <p
            style={{
              fontSize: 64,
              fontWeight: 700,
              color: "#fff",
              textTransform: "uppercase",
              whiteSpace: "nowrap",
            }}
            className="tour-title-font-w m-fz-32"
          >
            Săn vé máy bay
          </p> */}
        </div>
      </div>
      <div style={{ width: "100%" }}>
        <div
          style={{
            width: "100%",
            marginTop: 50,
            background: `linear-gradient(180deg, rgba(208, 54, 1, 0) 0%, rgba(208, 54, 1, 0.08) 26%, rgba(222, 106, 6, 0.19) 75%, rgba(226, 123, 7, 0) 100%)`,
          }}
          className="c-flex-center"
        >
          <div style={{ width: 1024 }}>
            <div
              style={{
                width: "100%",
                boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
                position: "relative",
              }}
            >
              <div
                style={{
                  bottom: "98%",
                  left: 0,
                  zIndex: 2,
                  position: "absolute",
                  background: "#F4F4F4",
                  fontWeight: 600,
                  padding: "10px 50px",
                }}
                className=" c-flex-center tt-uc bs-li bo-5"
              >
                <p className="tx-or tt-uc">Thông tin hành trình</p>
              </div>
              <Grid
                style={{ borderBottom: "1px solid #000", padding: "20px 50px" }}
                rowSpacing={4}
                columnSpacing={2}
              >
                <FormControl>
                  <RadioGroup
                    aria-labelledby="demo-controlled-radio-buttons-group"
                    name="controlled-radio-buttons-group"
                    value={value}
                    onChange={handleChange}
                    className={classes.horizontalRadioGroup}
                  >
                    <FormControlLabel
                      value="1"
                      control={<Radio />}
                      label="Một chiều"
                    />
                    <FormControlLabel
                      value="2"
                      control={<Radio />}
                      label="Khứ hồi"
                    />
                    <FormControlLabel
                      value="3"
                      control={<Radio />}
                      label="Nhiều chặng"
                    />
                  </RadioGroup>
                </FormControl>
              </Grid>
              <br />
              <Grid container spacing={2} style={{ padding: "0 50px" }}>
                <Grid
                  className="m-p-0"
                  item
                  xs={12}
                  sm={4}
                  style={{ paddingRight: 100 }}
                >
                  <Grid item xs={12} mb={2}>
                    <div style={{ fontWeight: 500, marginBottom: 4 }}>
                      Điểm đi
                    </div>
                    <TextField
                      id="outlined-basic"
                      variant="outlined"
                      select
                      SelectProps={{
                        IconComponent: () => null, // Bỏ đi icon arrow
                      }}
                      InputLabelProps={{ shrink: false }} // Đặt thuộc tính shrink thành false để ẩn label
                      value={departure}
                      onChange={(e) => setDeparture(e.target.value)}
                      fullWidth
                      size="small"
                      sx={{
                        "& .MuiOutlinedInput-root": {
                          "& fieldset": {
                            borderColor: "#000", // Thay đổi màu viền ở đây
                          },
                        },
                      }}
                    >
                      {listProvince
                        ?.map((item) => ({
                          ...item,
                          value: item.province_id,
                          label: item.province_name,
                        }))
                        ?.map((item, key) => (
                          <MenuItem
                            onClick={() => setDepartureText(item.label)}
                            value={item.value}
                            key={key}
                          >
                            {item?.label}
                          </MenuItem>
                        ))}
                    </TextField>
                  </Grid>
                  <Grid item xs={12} mb={2}>
                    <div style={{ fontWeight: 500, marginBottom: 4 }}>
                      Họ và tên
                    </div>
                    <TextField
                      id="outlined-basic"
                      variant="outlined"
                      InputLabelProps={{ shrink: false }} // Đặt thuộc tính shrink thành false để ẩn label
                      fullWidth
                      size="small"
                      sx={{
                        "& .MuiOutlinedInput-root": {
                          "& fieldset": {
                            borderColor: "#000", // Thay đổi màu viền ở đây
                          },
                        },
                      }}
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                    />
                  </Grid>
                  <Grid className="m-h-1" item xs={12} mb={2}>
                    <br />
                    <div
                      style={{
                        padding: "7px 30px",
                        background: "#E27B07",
                        fontSize: 20,
                        cursor: "pointer",
                        fontWeight: 700,
                        color: "#fff",
                      }}
                      className="c-flex-center bo-5"
                      onClick={handleClick}
                    >
                      Tìm kiếm
                    </div>
                  </Grid>
                </Grid>
                <Grid
                  className="m-p-0"
                  item
                  xs={12}
                  sm={4}
                  style={{ paddingRight: 100 }}
                >
                  <Grid item xs={12} mb={2}>
                    <div style={{ fontWeight: 500, marginBottom: 4 }}>
                      Điểm đến
                    </div>
                    <TextField
                      select
                      SelectProps={{
                        IconComponent: () => null, // Bỏ đi icon arrow
                      }}
                      id="outlined-basic"
                      variant="outlined"
                      InputLabelProps={{ shrink: false }} // Đặt thuộc tính shrink thành false để ẩn label
                      fullWidth
                      size="small"
                      sx={{
                        "& .MuiOutlinedInput-root": {
                          "& fieldset": {
                            borderColor: "#000", // Thay đổi màu viền ở đây
                          },
                        },
                      }}
                    >
                      {listProvince
                        ?.map((item) => ({
                          ...item,
                          value: item.province_id,
                          label: item.province_name,
                        }))
                        ?.map((item, key) => (
                          <MenuItem
                            onClick={() => setDestinationText(item.label)}
                            value={item.value}
                            key={key}
                          >
                            {item?.label}
                          </MenuItem>
                        ))}
                    </TextField>
                  </Grid>
                  <Grid item xs={12} mb={2}>
                    <div style={{ fontWeight: 500, marginBottom: 4 }}>
                      Số điện thoại
                    </div>
                    <TextField
                      id="outlined-basic"
                      variant="outlined"
                      InputLabelProps={{ shrink: false }} // Đặt thuộc tính shrink thành false để ẩn label
                      fullWidth
                      size="small"
                      sx={{
                        "& .MuiOutlinedInput-root": {
                          "& fieldset": {
                            borderColor: "#000", // Thay đổi màu viền ở đây
                          },
                        },
                      }}
                      value={phone}
                      onChange={(e) => setPhone(e.target.value)}
                    />
                  </Grid>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <Grid container mb={2}>
                    <Grid item xs={4}>
                      <div style={{ fontWeight: 500, marginBottom: 4 }}>
                        Người lớn
                      </div>
                      <TextField
                        select
                        SelectProps={{
                          IconComponent: () => null, // Bỏ đi icon arrow
                        }}
                        id="outlined-basic"
                        variant="outlined"
                        InputLabelProps={{ shrink: false }}
                        size="small"
                        value={adult}
                        onChange={(e) => setAdult(e.target.value)}
                        style={{ width: 50 }}
                        sx={{
                          "& .MuiOutlinedInput-root": {
                            "& fieldset": {
                              borderColor: "#000",
                            },
                          },
                        }}
                      >
                        {listAdult?.map((item, key) => (
                          <MenuItem value={item.value} key={key}>
                            {item?.label}
                          </MenuItem>
                        ))}
                      </TextField>
                    </Grid>
                    <Grid item xs={4}>
                      <div style={{ fontWeight: 500, marginBottom: 4 }}>
                        Trẻ em
                      </div>
                      <TextField
                        select
                        SelectProps={{
                          IconComponent: () => null, // Bỏ đi icon arrow
                        }}
                        id="outlined-basic"
                        variant="outlined"
                        InputLabelProps={{ shrink: false }} // Đặt thuộc tính shrink thành false để ẩn label
                        size="small"
                        style={{ width: 50 }}
                        sx={{
                          "& .MuiOutlinedInput-root": {
                            "& fieldset": {
                              borderColor: "#000", // Thay đổi màu viền ở đây
                            },
                          },
                        }}
                        value={children}
                        onChange={(e) => setChildren(e.target.value)}
                      >
                        {listChildren?.map((item, key) => (
                          <MenuItem value={item.value} key={key}>
                            {item?.label}
                          </MenuItem>
                        ))}
                      </TextField>
                    </Grid>
                    <Grid item xs={4}>
                      <div style={{ fontWeight: 500, marginBottom: 4 }}>
                        Em bé
                      </div>
                      <TextField
                        select
                        SelectProps={{
                          IconComponent: () => null, // Bỏ đi icon arrow
                        }}
                        id="outlined-basic"
                        variant="outlined"
                        InputLabelProps={{ shrink: false }} // Đặt thuộc tính shrink thành false để ẩn label
                        size="small"
                        style={{ width: 50 }}
                        sx={{
                          "& .MuiOutlinedInput-root": {
                            "& fieldset": {
                              borderColor: "#000", // Thay đổi màu viền ở đây
                            },
                          },
                        }}
                        value={baby}
                        onChange={(e) => setBaby(e.target.value)}
                      >
                        {listInfants?.map((item, key) => (
                          <MenuItem value={item.value} key={key}>
                            {item?.label}
                          </MenuItem>
                        ))}
                      </TextField>
                    </Grid>
                  </Grid>
                  <Grid item xs={12} height={68}>
                    <div style={{ fontSize: 14 }}>*Lưu ý:</div>
                    <div style={{ fontSize: 14 }}>Em bé dưới 2 tuổi</div>
                    <div style={{ fontSize: 14 }}>Trẻ em từ dưới 12 tuổi</div>
                  </Grid>
                </Grid>
                <Grid
                  className="m-s-1"
                  style={{ display: "none" }}
                  item
                  xs={12}
                  mb={2}
                >
                  <br />
                  <div
                    style={{
                      padding: "7px 30px",
                      background: "#E27B07",
                      fontSize: 20,
                      cursor: "pointer",
                      fontWeight: 700,
                      color: "#fff",
                    }}
                    className="c-flex-center bo-5"
                    onClick={handleClick}
                  >
                    Tìm kiếm
                  </div>
                </Grid>
              </Grid>
            </div>
          </div>
        </div>
        <br />
        <br />
        <br />
        <div style={{ width: "100%" }} className="c-flex-center">
          <div style={{ width: 1248 }}>
            <div
              style={{
                width: "100%",
                borderRadius: 10,
                border: "1px solid #A09797",
                overflow: "hidden",
              }}
            >
              <div
                style={{
                  width: "100%",
                  textAlign: "center",
                  padding: "8px 0",
                  backgroundColor: "#E27B07",
                }}
              >
                <div
                  style={{
                    fontSize: 20,
                    color: "white",
                    textTransform: "uppercase",
                    fontWeight: 600,
                  }}
                >
                  Quy định đổi, trả vé năm 2024
                </div>
              </div>
              <AirPlaneRule data={data} />
            </div>
          </div>
        </div>
      </div>
      {/* <DiscoverList /> */}
      <br />
      {/* <AdvisePopup2 open={open} setOpen={setOpen} type={"Đặt vé máy bay"} product={"Vé máy bay"} /> */}
    </div>
  );
};

export default Booking;

import React from "react";
import MissionImage from "../../../assets/home/mission.png";
import { useNavigate } from "react-router-dom";
import MediaQuery from "react-responsive";

const Mission = () => {
  const navigate = useNavigate();
  const handleClick = () => {
    navigate("/ve-chung-toi");
  };
  return (
    <div className="wrap-mission" style={{ flexDirection: "column" }}>
      <MediaQuery minWidth={625}>
        <div
          className="left-content-title-mission tt-uc"
          style={{ textAlign: "center" }}
        >
          Sứ mệnh Minh Khang Group
        </div>
        <br />
      </MediaQuery>
      <div className="container-mission">
        <div className="content-mission" style={{ gap: 40 }}>
          <div className="left-content-mission">
            <MediaQuery maxWidth={625}>
              <div
                className="left-content-title-mission tt-uc fw-800"
                style={{ fontSize: 24 }}
              >
                Sứ mệnh Minh Khang Group
              </div>
            </MediaQuery>
            {/* <div className="divider-margin"></div> */}
            <MediaQuery minWidth={625}>
              <li className="tx-18 tx-jt tx-jt-non">
                <strong>
                  “Là đối tác Uy Tín hàng đầu tại Việt Nam cũng như là Người Bạn
                  đồng hành cùng du khách trong mọi nẻo đường”
                </strong>{" "}
              </li>
              <li className="tx-18 tx-jt tx-jt-non" style={{ textIndent: 20 }}>
                Với biểu tượng là chú chim bồ câu trắng đang tung cánh bay về
                phía trước mạnh mẽ trên bầu trời đầy nắng – Là hình tượng cho sự
                hoà bình, tự do, bình đẳng và là đặc trưng cho trí thức con
                người, là biểu tượng của sự kết nối, sự thống nhất. Bố trí xen
                kẽ với chú chim là màu vàng của ánh nắng ban mai thể hiện sự nhẹ
                nhàng, ấm áp, chăm chỉ, chu đáo và tươm tất mà dịch vụ{" "}
                Minh Khang Group mang đến cho khách hàng.
              </li>
              <li className="tx-18 tx-jt tx-jt-non" style={{ textIndent: 20 }}>
                Chúng tôi không chỉ mang đến cho khách hàng những trải nghiệm
                hoàn hảo nhất thông qua dịch vụ vận hành bất động sản dòng tiền
                – du lịch lữ hành mà còn là một sứ giả hoà bình lan toả tình
                yêu, hạnh phúc, khát vọng mang thương hiệu Việt vươn tầm ra khu
                vực và trên thế giới.
              </li>
              <p className="more-left-content-mission">
                <div
                  onClick={handleClick}
                  className="button-more-left-content-mission"
                >
                  Xem thêm
                </div>
              </p>
            </MediaQuery>
            <MediaQuery maxWidth={625}>
              <p className="p-left-content-mission tx-jt">
                Trở thành đối tác đáng tin cậy và giải pháp hàng đầu trong lĩnh
                vực dịch vụ và vận hành tòa nhà bằng cách cung cấp các giải pháp
                tối ưu, giúp tiết kiệm thời gian và yên tâm trong công tác quản
                lý, với mục tiêu tối đa hóa dòng tiền, xây dựng giá trị thương
                hiệu vững mạnh. 
              </p>
              <p className="p-left-content-mission tx-jt">
                Mang lại trải nghiệm, hạnh phúc đến cho khách hàng, đối tác,
                người lao động, chủ sở hữu và cộng đồng thông qua các sản phẩm
                và dịch vụ du lịch và quản lý vận hành
              </p>
            </MediaQuery>
          </div>
          <MediaQuery minWidth={625}>
            <div className="right-content-mission">
              <img src={MissionImage} alt="Can't open" />
            </div>
          </MediaQuery>
        </div>
      </div>
    </div>
  );
};

export default Mission;

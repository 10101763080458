import React, { useState } from "react";
// import ItemImage from "../../../assets/apartment/item.png";
import { Link, useNavigate } from "react-router-dom";
import AdvisePopup2 from "../../Advise/AdvisePopup2";
import numberWithCommas from "../../../utils/numberWithComma";
import createSlug from "../../../utils/createSlug";

export class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false, error: null };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true, error };
  }

  componentDidCatch(error, errorInfo) {
    // console.error("Error boundary caught an error:", error, errorInfo);
    // window.location.reload()
  }

  render() {
    if (this.state.hasError) {
      return <div style={{width: "100%", display: "flex", justifyContent: "center", alignItems: "center"}}>
        <h1>Wait a minute...</h1>
      </div>;
    }

    return this.props.children; 
  }
}


const ItemTour = (props) => {
  const navigate = useNavigate();
  const [open, setOpen]= useState(false)

  const handleClickOpen = (e) => {
    e.stopPropagation()
    setOpen(true);
  };

  // const handleClick = () => {
  //   navigate("/tour/detail/"+ createSlug(props?.name)?.split(" ")?.join("-") + "-"  + props?.id);
  // };

  return (
    <ErrorBoundary>
      <Link to={"/tour/detail/"+ createSlug(props?.name)?.split(" ")?.join("-") + "-"  + props?.id} style={{ width: "100%", height: "100%" }}>
        <div
          style={{
            width: "100%",
            height: "100%",
            boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
            borderRadius: 10,
            cursor: "pointer",
            background: "#fff",
            overflow: "hidden",
          }}
          // onClick={handleClick}
        >
          <div style={{ width: "100%", overflow: "hidden", marginBottom: 4 }}>
            <img
              loading={"lazy"}
              src={props?.photo}
              alt=""
              style={{ width: "100%", aspectRatio: 4 / 3, objectFit: "cover" }}
            />
          </div>
          <div style={{ padding: 10, width: "100%" }}>
            {props?.desc ? (
              <div
                style={{
                  fontSize: 15,
                  fontWeight: 600,
                  marginBottom: 4,
                }}
                className="tr-2l h-48p"
                dangerouslySetInnerHTML={{ __html: props?.desc }}
              ></div>
            ) : (
              <div className="tr-2l h-48p">Unknown</div>
            )}
            <div
              className="tr-1l"
              style={{ fontSize: 12, marginBottom: 4, marginTop: 12 }}
            >
              Mã tour: {props?.tour_id}
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <div
                style={{
                  color: "#000",
                  fontSize: 14,
                  cursor: "pointer",
                }}
                // className="c-flex-center"
              >
                <div className="price-item-product" style={{ fontWeight: 600}}>{numberWithCommas(Math.round(parseInt(props?.price) * ((100 - parseInt(props?.discountPer)) / 100)))} VND</div>
                <del style={{color: "#625E5D", fontWeight: 600, height: 16, fontSize: 16}}>{parseInt(props?.discountPer) > 0 && numberWithCommas(props?.price) + " VND"}</del>
              </div>
              <div
                style={{
                  padding: "5px 10px",
                  borderRadius: 10,
                  color: "#fff",
                  fontSize: 12,
                  background: "#D03601",
                  cursor: "pointer",
                }}
                className="c-flex-center"
                // onClick={handleClick}
              >
                Đặt ngay
              </div>
            </div>
          </div>
        </div>
        <AdvisePopup2 open={open} setOpen={setOpen} type={"Đặt tour"} product={props?.name} />
      </Link>
    </ErrorBoundary>
  );
};

export default ItemTour;

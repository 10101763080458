import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { apiGetBlogDetail } from "../api";
import Template4 from "../assets/tour/template4.png";
import PopularTour from "../components/PopularTour/PopularTour";
import { animateScroll } from "react-scroll";
import DealHot from "../components/DealHot/DealHot";
import ScrollToTop from "../utils/ScrollToTop";
import LoadingSkeletonBlog, {
  LoadingSkeletonBlog1,
  LoadingSkeletonBlogContent,
} from "../components/Loading/LoadingSkeletonBlog";
// import DiscoverList from "../components/DiscoverList/DiscoverList";
import { FaCalendarAlt } from "react-icons/fa";
import moment from "moment";
import { FaPen } from "react-icons/fa";
import RenderDangerHtml from "../components/RenderDangerHtml/RenderDangerHtml";
import { Helmet } from "react-helmet";

const DetailBlog = () => {
  const [data, setData] = useState();
  const { id } = useParams();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    (async () => {
      try {
        setLoading(true);
        const result = await apiGetBlogDetail({ id: id?.split("-").at(-1) });
        setLoading(false);
        if (result?.ok === true) {
          setData(result?.data?.[0]);
        }
      } catch (error) {
        setLoading(false);
      } finally {
        setLoading(false);
      }
    })();
  }, [id]);

  useEffect(() => {
    animateScroll.scrollToTop({ smooth: false });
  }, [id]);

  return (
    <div className="container-tour">
      <Helmet>
        <title>{data?.name}</title>
        <link rel="canonical" href={window.location.origin + window.location.pathname} />
        <meta name="description" content={data?.meta_description}></meta>
        <meta property="og:description" content={data?.meta_description}></meta>
        <meta
          data-react-helmet="true"
          property="og:title"
          content={data?.name}
        ></meta>
        <meta
          data-react-helmet="true"
          property="og:url"
          content={window.location.origin + window.location.pathname}
        ></meta>
        <meta data-react-helmet="true" property="og:image" content={data?.photo}></meta>
      </Helmet>
      <ScrollToTop />
      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div style={{ width: "100%", maxWidth: 1248, marginBottom: 36 }}>
          <div style={{ textAlign: "center", margin: "24px 0" }}>
            {loading === false && (
              <div
                style={{
                  background:
                    "linear-gradient(180deg, #D03601 0%, #E27B07 100%)",
                  backgroundClip: "text",
                  color: "transparent",
                  fontSize: 36,
                  fontWeight: 700,
                  textTransform: "uppercase",
                }}
              >
                {data?.name}
              </div>
            )}
            {loading === true && <LoadingSkeletonBlog />}
          </div>
          {loading === false && (
            <div
              style={{
                width: "100%",
                display: "flex",
                gap: 20,
                marginBottom: 12,
              }}
            >
              <div
                className="c-flex-center"
                style={{ width: "max-content", gap: 10 }}
              >
                <FaCalendarAlt />{" "}
                {moment(data?.time_created).format("DD/MM/YYYY")}
              </div>
              <div>-</div>
              <div
                className="c-flex-center"
                style={{ width: "max-content", gap: 10 }}
              >
                <FaPen />{" "}
                {data?.user
                  ? data?.user?.firstName?.replace("null", "")
                  : "Unknown"}
              </div>
            </div>
          )}
          {loading === true && <LoadingSkeletonBlog1 />}
          {/*  */}
          <div
            style={{ width: "100%", display: "flex" }}
            className="m-flex-column"
          >
            <div style={{ flex: 1 }}>
              {loading === false && (
                <div
                  style={{
                    width: "100%",
                    background: "#F8F8F8",
                    padding: 30,
                    boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
                    borderRadius: 10,
                  }}
                >
                  <RenderDangerHtml data={data?.content} is_tableofcontent={true} />
                  {/* <div
                    dangerouslySetInnerHTML={{ __html: data?.content }}
                  ></div> */}
                </div>
              )}
              {loading === true && <LoadingSkeletonBlogContent />}
            </div>

            <div
              className="wrap-dh-blog"
              style={{ width: 300, paddingLeft: 20 }}
            >
              <DealHot />
            </div>
          </div>
          {/*  */}
          {/* <DiscoverList /> */}
        </div>
      </div>
      <PopularTour />
    </div>
  );
};

export default DetailBlog;

import { Menu, MenuItem } from "@mui/material";
import React, { useEffect, useState } from "react";
import MediaQuery from "react-responsive";
import { Link, NavLink, useLocation, useNavigate } from "react-router-dom";

const Navigation = (props) => {
  const navigate = useNavigate();
  const location= useLocation()
  const [currentNav, setCurrentNav] = useState(0);
  const [currentIndex, setCurrentIndex]= useState()
  const [subMenu1, setSubMenu1] = useState(null);
  const [subMenu2, setSubMenu2] = useState(null);
  const [subMenu3, setSubMenu3] = useState(null);
  const [subMenu4, setSubMenu4] = useState(null);
  const [subMenu5, setSubMenu5] = useState(null);
  const open1 = Boolean(subMenu1);
  const open2 = Boolean(subMenu2);
  const open3 = Boolean(subMenu3);
  const open4 = Boolean(subMenu4);
  const open5 = Boolean(subMenu5);
  const [class1, setClass1]= useState("com-hov-1")
  const [class2, setClass2]= useState("com-hov-2")
  const [class3, setClass3]= useState("com-hov-3")
  const [class4, setClass4]= useState("com-hov-4")
  const [class5, setClass5]= useState("com-hov-5")

  const handleClick = (event, value) => {
    if (value) {
      event.preventDefault();
    }
    setCurrentNav(value);
    if (value === 0) {
      if (props?.onClose) {
        props?.onClose();
      }
    }
    if (value === 1) {
      setSubMenu1(event.currentTarget);
    }
    if (value === 2) {
      setSubMenu2(event.currentTarget);
    }
    if (value === 3) {
      setSubMenu3(event.currentTarget);
    }
    if (value === 4) {
      setSubMenu4(event.currentTarget);
    }
    if (value === 5) {
      setSubMenu5(event.currentTarget);
    }
  };
  const handleClose = (value) => {
    navigate(value);

    setSubMenu1(null);
    setSubMenu2(null);
    setSubMenu3(null);
    setSubMenu4(null);
    setSubMenu5(null);
    if (props?.onClose) {
      props?.onClose();
    }
  };
  const handleCloseMenu = () => {
    if (props?.onClose) {
      props?.onClose();
    }
    setSubMenu1(null);
    setSubMenu2(null);
    setSubMenu3(null);
    setSubMenu4(null);
    setSubMenu5(null);
  };

  const handleClickNavigate= (link)=> {
    navigate(link)
    // window.location.href= window.location.origin + (link)
  }

  const listNavigation = [
    { link: "/", text: "Trang chủ", submenu: 0 },
    { link: "/", text: "Giới thiệu", submenu: 1 },
    { text: "Quản lý vận hành", submenu: 2 },
    { text: "Cho thuê căn hộ", submenu: 3 },
    { text: "Đặt phòng khách sạn", submenu: 4 },
    { text: "Tour / Vé tham quan", submenu: 5 },
  ];

  useEffect(()=> {
    if(location.pathname=== "/") {
      setCurrentIndex(0)
    }
    else if(location.pathname=== "/ve-chung-toi" || location.pathname=== "/linh-vuc-hoat-dong" || location.pathname=== "/hoat-dong-noi-bo" ||location.pathname===  "/tuyen-dung") {
      setCurrentIndex(1)
    }
    else if(location.pathname=== "/quan-ly-van-hanh-can-ho" || location.pathname=== "/quan-ly-van-hanh-khach-san" || location.pathname=== "/quan-ly-van-hanh-van-phong" ||location.pathname===  "/tu-van-dau-tu") {
      setCurrentIndex(2)
    }
    else if(location.pathname=== "/nha-nguyen-can" || location.pathname=== "/can-ho-phong-cho-thue" || location.pathname=== "/mat-bang" ||location.pathname===  "/cho-thue-van-phong") {
      setCurrentIndex(3)
    }
    else if(location.pathname=== "/dat-phong-khach-san" || location.pathname=== "/dat-phong-villa" || location.pathname=== "/dat-phong-homestay") {
      setCurrentIndex(4)
    }
    else if(location.pathname=== "/ve-tham-quan" || location.pathname=== "/ve-tau-lua" || location.pathname=== "/tour-daily" || location.pathname=== "/tour-du-lich" || location.pathname=== "/cam-nang-du-lich") {
      setCurrentIndex(5)
    }
    
    
  }, [location])

  return (
    <div className="header-navigation">
      <div className="header-contain-navigation">
        <MediaQuery maxWidth={625}>
          {listNavigation?.map((item, key) => (
            <div
              key={key}
              className={`wrap-link-navigation ${
                parseInt(key) === parseInt(currentNav) &&
                "wrap-link-navigation-active"
              }`}
            >
              <NavLink
                onClick={(e) => handleClick(e, item.submenu)}
                className="header-link-navigation"
                to={item.link}
              >
                <p>{item.text}</p>
              </NavLink>
              {item.submenu === 1 && (
                <Menu
                  id="basic-menu"
                  anchorEl={subMenu1}
                  open={open1}
                  onClose={handleCloseMenu}
                  MenuListProps={{
                    "aria-labelledby": "basic-button",
                  }}
                  disableScrollLock={true}
                  slotProps={{
                    className: "custom-menu-paper",
                  }}
                  PaperProps={{
                    className: "custom-menu-paper",
                  }}
                  style={{ marginTop: 4, marginLeft: -10, borderRadius: 20 }}
                >
                  <MenuItem
                    onClick={() => {
                      handleClose("/ve-chung-toi");
                    }}
                    style={{ textTransform: "uppercase" }}
                  >
                    <p className="text-trad">Minh Khang Group</p>
                  </MenuItem>
                  <MenuItem
                    onClick={() => {
                      handleClose("/linh-vuc-hoat-dong");
                    }}
                    style={{ textTransform: "uppercase" }}
                  >
                    <p className="text-trad">Lĩnh vực hoạt động</p>
                  </MenuItem>
                  <MenuItem
                    onClick={() => {
                      handleClose("/nhan-su");
                    }}
                    style={{ textTransform: "uppercase" }}
                  >
                    <p className="text-trad">Đội ngũ nhân sự</p>
                  </MenuItem>
                  <MenuItem
                    onClick={() => {
                      handleClose("/hoat-dong-noi-bo");
                    }}
                    style={{ textTransform: "uppercase" }}
                  >
                    <p className="text-trad">Hoạt động nội bộ</p>
                  </MenuItem>
                  <MenuItem
                    onClick={() => {
                      handleClose("/tuyen-dung");
                    }}
                    style={{ textTransform: "uppercase" }}
                  >
                    <p className="text-trad">Tuyển dụng</p>
                  </MenuItem>
                </Menu>
              )}
              {item.submenu === 2 && (
                <Menu
                  id="basic-menu-1"
                  anchorEl={subMenu2}
                  open={open2}
                  onClose={handleCloseMenu}
                  MenuListProps={{
                    "aria-labelledby": "basic-button",
                  }}
                  disableScrollLock={true}
                  slotProps={{
                    className: "custom-menu-paper",
                  }}
                  PaperProps={{
                    className: "custom-menu-paper",
                  }}
                  style={{ marginTop: 4, marginLeft: -10, borderRadius: 20 }}
                >
                  <MenuItem
                    onClick={() => {
                      handleClose("/quan-ly-van-hanh-can-ho");
                    }}
                    style={{ textTransform: "uppercase" }}
                  >
                    <p className="text-trad">Quản lý vận hành căn hộ</p>
                  </MenuItem>
                  <MenuItem
                    onClick={() => {
                      handleClose("/quan-ly-van-hanh-khach-san");
                    }}
                    style={{ textTransform: "uppercase" }}
                  >
                    <p className="text-trad">Quản lý vận hành khách sạn</p>
                  </MenuItem>
                  <MenuItem
                    onClick={() => {
                      handleClose("/quan-ly-van-hanh-van-phong");
                    }}
                    style={{ textTransform: "uppercase" }}
                  >
                    <p className="text-trad">Quản lý vận hành văn phòng</p>
                  </MenuItem>
                  <MenuItem
                    onClick={() => {
                      handleClose("/tu-van-dau-tu");
                    }}
                    style={{ textTransform: "uppercase" }}
                  >
                    <p className="text-trad">Tư vấn đầu tư</p>
                  </MenuItem>
                  <MenuItem
                    onClick={() => {
                      handleClose("/tin-tuc-noi-bat");
                    }}
                    style={{ textTransform: "uppercase" }}
                  >
                    <p className="text-trad">Tin tức nổi bật</p>
                  </MenuItem>
                </Menu>
              )}
              {/*  */}
              {item.submenu === 3 && (
                <Menu
                  id="basic-menu-1"
                  anchorEl={subMenu3}
                  open={open3}
                  onClose={handleCloseMenu}
                  MenuListProps={{
                    "aria-labelledby": "basic-button",
                  }}
                  disableScrollLock={true}
                  slotProps={{
                    className: "custom-menu-paper",
                  }}
                  PaperProps={{
                    className: "custom-menu-paper",
                  }}
                  style={{ marginTop: 4, marginLeft: -10, borderRadius: 20 }}
                >
                  <MenuItem
                    onClick={() => {
                      handleClose("/nha-nguyen-can");
                    }}
                    style={{ textTransform: "uppercase" }}
                  >
                    <p className="text-trad">Nhà nguyên căn</p>
                  </MenuItem>
                  <MenuItem
                    onClick={() => {
                      handleClose("/can-ho-phong-cho-thue");
                    }}
                    style={{ textTransform: "uppercase" }}
                  >
                    <p className="text-trad">Căn hộ - Phòng cho thuê</p>
                  </MenuItem>
                  <MenuItem
                    onClick={() => {
                      handleClose("/mat-bang");
                    }}
                    style={{ textTransform: "uppercase" }}
                  >
                    <p className="text-trad">Mặt bằng</p>
                  </MenuItem>
                  <MenuItem
                    onClick={() => {
                      handleClose("/cho-thue-van-phong");
                    }}
                    style={{ textTransform: "uppercase" }}
                  >
                    <p className="text-trad">Cho thuê văn phòng</p>
                  </MenuItem>
                </Menu>
              )}
              {/*  */}
              {item.submenu === 4 && (
                <Menu
                  id="basic-menu-1"
                  anchorEl={subMenu4}
                  open={open4}
                  onClose={handleCloseMenu}
                  MenuListProps={{
                    "aria-labelledby": "basic-button",
                    className: "bo-ra-10",
                  }}
                  disableScrollLock={true}
                  slotProps={{
                    className: "custom-menu-paper",
                  }}
                  PaperProps={{
                    className: "custom-menu-paper",
                  }}
                  style={{ marginTop: 4, marginLeft: -10, borderRadius: 20 }}
                  className="menu-wrap-1"
                >
                  <MenuItem
                    onClick={() => {
                      handleClose("/dat-phong-khach-san");
                    }}
                    style={{ textTransform: "uppercase" }}
                  >
                    <p className="text-trad">Đặt phòng khách sạn</p>
                  </MenuItem>
                  <MenuItem
                    onClick={() => {
                      handleClose("/dat-phong-villa");
                    }}
                    style={{ textTransform: "uppercase" }}
                  >
                    <p className="text-trad">Đặt phòng villa</p>
                  </MenuItem>
                  <MenuItem
                    onClick={() => {
                      handleClose("/dat-phong-homestay");
                    }}
                    style={{ textTransform: "uppercase" }}
                  >
                    <p className="text-trad">Đặt phòng homestay</p>
                  </MenuItem>
                </Menu>
              )}
              {/*  */}
              {item.submenu === 5 && (
                <Menu
                  id="basic-menu-1"
                  anchorEl={subMenu5}
                  open={open5}
                  onClose={handleCloseMenu}
                  MenuListProps={{
                    "aria-labelledby": "basic-button",
                  }}
                  disableScrollLock={true}
                  slotProps={{
                    className: "custom-menu-paper",
                  }}
                  PaperProps={{
                    className: "custom-menu-paper",
                  }}
                  style={{ marginTop: 4, marginLeft: -10, borderRadius: 20 }}
                >
                  <MenuItem
                    onClick={() => {
                      handleClose("/ve-tham-quan");
                    }}
                    style={{ textTransform: "uppercase" }}
                  >
                    <p className="text-trad">Vé tham quan</p>
                  </MenuItem>
                  <MenuItem
                    onClick={() => {
                      handleClose("/ve-may-bay");
                    }}
                    style={{ textTransform: "uppercase" }}
                  >
                    <p className="text-trad">Vé máy bay</p>
                  </MenuItem>
                  <MenuItem
                    onClick={() => {
                      handleClose("/ve-tau-lua");
                    }}
                    style={{ textTransform: "uppercase" }}
                  >
                    <p className="text-trad">Vé tàu lửa</p>
                  </MenuItem>
                  <MenuItem
                    onClick={() => {
                      handleClose("/tour-daily");
                    }}
                    style={{ textTransform: "uppercase" }}
                  >
                    <p className="text-trad">Tour daily</p>
                  </MenuItem>
                  <MenuItem
                    onClick={() => {
                      handleClose("/tour-du-lich");
                    }}
                    style={{ textTransform: "uppercase" }}
                  >
                    <p className="text-trad">Tour trong nước</p>
                  </MenuItem>
                  <MenuItem
                    onClick={() => {
                      handleClose("/cam-nang-du-lich");
                    }}
                    style={{ textTransform: "uppercase" }}
                  >
                    <p className="text-trad">Cẩm nang du lịch</p>
                  </MenuItem>
                </Menu>
              )}
            </div>
          ))}
        </MediaQuery>
        <MediaQuery minWidth={625}>
          {listNavigation?.map((item, key) => (
            <div
              key={key}
              className={`wrap-link-navigation open-link-menu-${key} ${
                parseInt(key) === parseInt(currentIndex) &&
                "wrap-link-navigation-active"
              }`}
              style={{position: "relative", zIndex: 10}}
              onMouseEnter={()=> {
                setClass1("com-hov-1")
                setClass2("com-hov-2")
                setClass3("com-hov-3")
                setClass4("com-hov-4")
                setClass5("com-hov-5")
              }}
            >
              <NavLink
                onClick={(e) => handleClick(e, item.submenu)}
                className="header-link-navigation"
                to={item.link}
              >
                <p>{item.text}</p>
              </NavLink>
              <div className={`bs-li ${class1}`} style={{display: "none", position: "absolute", top: "100%", left: 0, padding: 10,paddingRight: 30, borderRadius: 10, width: "auto", zIndex: 10, background: "white"}}>
                <Link to={"/ve-chung-toi"} style={{color: "unset", textDecoration: "none"}}>
                  <div className="tt-uc text-trad " onClick={()=> {handleClickNavigate("/ve-chung-toi");setClass1("")}} style={{whiteSpace: "nowrap", padding: "10px 0", cursor: "pointer"}}>Minh Khang Group</div>
                </Link>
                <Link to="/linh-vuc-hoat-dong" style={{ color: "unset", textDecoration: "none" }}>
  <div className="tt-uc text-trad" style={{ whiteSpace: "nowrap", padding: "10px 0", cursor: "pointer" }}>
    Lĩnh vực hoạt động
  </div>
</Link>
<Link to="/nhan-su" style={{ color: "unset", textDecoration: "none" }}>
  <div className="tt-uc text-trad" style={{ whiteSpace: "nowrap", padding: "10px 0", cursor: "pointer" }}>
    Đội ngũ nhân sự
  </div>
</Link>
<Link to="/hoat-dong-noi-bo" style={{ color: "unset", textDecoration: "none" }}>
  <div className="tt-uc text-trad" style={{ whiteSpace: "nowrap", padding: "10px 0", cursor: "pointer" }}>
    Hoạt động nội bộ
  </div>
</Link>
<Link to="/tuyen-dung" style={{ color: "unset", textDecoration: "none" }}>
  <div className="tt-uc text-trad" style={{ whiteSpace: "nowrap", padding: "10px 0", cursor: "pointer" }}>
    Tuyển dụng
  </div>
</Link>
              </div>
              <div className={`bs-li ${class2}`} style={{display: "none", position: "absolute", top: "100%", left: 0, padding: 10,paddingRight: 30, borderRadius: 10, width: "auto", zIndex: 10, background: "white"}}>
              <Link to="/quan-ly-van-hanh-can-ho">
      <div className="tt-uc text-trad" onClick={() => {handleClickNavigate("/quan-ly-van-hanh-can-ho"); setClass2("");}} style={{whiteSpace: "nowrap", padding: "10px 0", cursor: "pointer"}}>
        Quản lý căn hộ
      </div>
    </Link>
    <Link to="/quan-ly-van-hanh-khach-san">
      <div className="tt-uc text-trad" onClick={() => {handleClickNavigate("/quan-ly-van-hanh-khach-san"); setClass2("");}} style={{whiteSpace: "nowrap", padding: "10px 0", cursor: "pointer"}}>
        Quản lý khách sạn
      </div>
    </Link>
    <Link to="/quan-ly-van-hanh-van-phong">
      <div className="tt-uc text-trad" onClick={() => {handleClickNavigate("/quan-ly-van-hanh-van-phong"); setClass2("");}} style={{whiteSpace: "nowrap", padding: "10px 0", cursor: "pointer"}}>
        Quản lý văn phòng
      </div>
    </Link>
    <Link to="/tin-tuc-noi-bat">
      <div className="tt-uc text-trad" onClick={() => {handleClickNavigate("/tin-tuc-noi-bat"); setClass2("");}} style={{whiteSpace: "nowrap", padding: "10px 0", cursor: "pointer"}}>
        Tin tức nổi bật
      </div>
    </Link>
    <Link to="/tu-van-dau-tu">
      <div className="tt-uc text-trad" onClick={() => {handleClickNavigate("/tu-van-dau-tu"); setClass2("");}} style={{whiteSpace: "nowrap", padding: "10px 0", cursor: "pointer"}}>
        Tư vấn đầu tư
      </div>
    </Link>
                {/* <div className="tt-uc text-trad " onClick={()=> handleClickNavigate("/nang-luc-minh-khang")} style={{whiteSpace: "nowrap", padding: "10px 0", cursor: "pointer"}}>Năng lực minh khang</div> */}
              </div>
              <div className={`bs-li ${class3}`} style={{display: "none", position: "absolute", top: "100%", left: 0, padding: 10,paddingRight: 30, borderRadius: 10, width: "auto", zIndex: 10, background: "white"}}>
              <Link to="/nha-nguyen-can">
                <div className="tt-uc text-trad" onClick={() => {handleClickNavigate("/nha-nguyen-can"); setClass3("");}} style={{whiteSpace: "nowrap", padding: "10px 0", cursor: "pointer"}}>
                  Nhà nguyên căn
                </div>
              </Link>
              <Link to="/can-ho-phong-cho-thue">
                <div className="tt-uc text-trad" onClick={() => {handleClickNavigate("/can-ho-phong-cho-thue"); setClass3("");}} style={{whiteSpace: "nowrap", padding: "10px 0", cursor: "pointer"}}>
                  Căn hộ - phòng cho thuê
                </div>
              </Link>
              <Link to="/mat-bang">
                <div className="tt-uc text-trad" onClick={() => {handleClickNavigate("/mat-bang"); setClass3("");}} style={{whiteSpace: "nowrap", padding: "10px 0", cursor: "pointer"}}>
                  Mặt bằng
                </div>
              </Link>
              <Link to="/cho-thue-van-phong">
                <div className="tt-uc text-trad" onClick={() => {handleClickNavigate("/cho-thue-van-phong"); setClass3("");}} style={{whiteSpace: "nowrap", padding: "10px 0", cursor: "pointer"}}>
                  Cho thuê văn phòng
                </div>
              </Link>
              </div>
              <div className={`bs-li ${class4}`} style={{display: "none", position: "absolute", top: "100%", left: 0, padding: 10,paddingRight: 30, borderRadius: 10, width: "auto", zIndex: 10, background: "white"}}>
              <Link to="/dat-phong-khach-san">
      <div className="tt-uc text-trad" onClick={() => {handleClickNavigate("/dat-phong-khach-san"); setClass4("");}} style={{whiteSpace: "nowrap", padding: "10px 0", cursor: "pointer"}}>
        Đặt phòng khách sạn
      </div>
    </Link>
    <Link to="/dat-phong-villa">
      <div className="tt-uc text-trad" onClick={() => {handleClickNavigate("/dat-phong-villa"); setClass4("");}} style={{whiteSpace: "nowrap", padding: "10px 0", cursor: "pointer"}}>
        Đặt phòng villa
      </div>
    </Link>
    <Link to="/dat-phong-homestay">
      <div className="tt-uc text-trad" onClick={() => {handleClickNavigate("/dat-phong-homestay"); setClass4("");}} style={{whiteSpace: "nowrap", padding: "10px 0", cursor: "pointer"}}>
        Đặt phòng homestay
      </div>
    </Link>
              </div>
              <div className={`bs-li ${class5}`} style={{display: "none", position: "absolute", top: "100%", left: 0, padding: 10,paddingRight: 30, borderRadius: 10, width: "auto", zIndex: 10, background: "white"}}>
                <Link to={"/ve-tham-quan"}>
                  <div className="tt-uc text-trad " onClick={()=> {handleClickNavigate("/ve-tham-quan");setClass5("")}} style={{whiteSpace: "nowrap", padding: "10px 0", cursor: "pointer"}}>Vé tham quan</div>
                </Link>
                <Link to="/ve-may-bay">
                  <div className="tt-uc text-trad" onClick={() => {handleClickNavigate("/ve-may-bay"); setClass5("");}} style={{whiteSpace: "nowrap", padding: "10px 0", cursor: "pointer"}}>
                    Vé máy bay
                  </div>
                </Link>
                <Link to="/ve-tau-lua">
                  <div className="tt-uc text-trad" onClick={() => {handleClickNavigate("/ve-tau-lua"); setClass5("");}} style={{whiteSpace: "nowrap", padding: "10px 0", cursor: "pointer"}}>
                    Vé tàu lửa
                  </div>
                </Link>
                <Link to="/tour-daily">
                  <div className="tt-uc text-trad" onClick={() => {handleClickNavigate("/tour-daily"); setClass5("");}} style={{whiteSpace: "nowrap", padding: "10px 0", cursor: "pointer"}}>
                    Tour daily
                  </div>
                </Link>
                <Link to="/tour-du-lich">
                  <div className="tt-uc text-trad" onClick={() => {handleClickNavigate("/tour-du-lich"); setClass5("");}} style={{whiteSpace: "nowrap", padding: "10px 0", cursor: "pointer"}}>
                    Tour trong nước &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  </div>
                </Link>
                <Link to="/tour-bien-dao">
                  <div className="tt-uc text-trad" onClick={() => {handleClickNavigate("/tour-bien-dao"); setClass5("");}} style={{whiteSpace: "nowrap", padding: "10px 0", cursor: "pointer"}}>
                    Tour biển đảo
                  </div>
                </Link>
                <Link to="/tour-tay-nguyen">
                  <div className="tt-uc text-trad" onClick={() => {handleClickNavigate("/tour-tay-nguyen"); setClass5("");}} style={{whiteSpace: "nowrap", padding: "10px 0", cursor: "pointer"}}>
                    Tour tây nguyên
                  </div>
                </Link>
                <Link to="/cam-nang-du-lich">
                  <div className="tt-uc text-trad" onClick={() => {handleClickNavigate("/cam-nang-du-lich"); setClass5("");}} style={{whiteSpace: "nowrap", padding: "10px 0", cursor: "pointer"}}>
                    Cẩm nang du lịch
                  </div>
                </Link>
              </div>
            </div>
          ))}
        </MediaQuery>
      </div>
    </div>
  );
};

export default Navigation;

import React, { useEffect, useState } from "react";
import BannerImage from "../../../assets/home/banner.png";
import BannerImage2 from "../../../assets/home/banner2.png";
import BannerImage3 from "../../../assets/home/banner3.png";
import BannerImage4 from "../../../assets/home/banner4.png";
import BannerImage5 from "../../../assets/home/banner5.png";
import BannerImage6 from "../../../assets/home/banner6.png";
import BannerImage7 from "../../../assets/home/banner7.png";
import BannerImageMb from "../../../assets/home/banner1-mb.png";
import BannerImageMb2 from "../../../assets/home/banner2-mb.png";
import BannerImageMb3 from "../../../assets/home/banner3-mb.png";
import { Image, Swiper, useSwiper } from "react-nivo-slider";
import NavigationBanner from "./NavigationBanner";
// import BookingMenu from "./BookingMenu";
import MediaQuery from "react-responsive";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "react-nivo-slider/es/style";
import "react-nivo-slider/es/style/default";
import { GrFormNext, GrFormPrevious } from "react-icons/gr";

function CustomControl(props) {
  const swiper = useSwiper();
  useEffect(()=> {
    props?.setActiveSlideIndexDes(swiper.activeIndex)
  }, [swiper.activeIndex, props?.setActiveSlideIndexDes])
  return (
    <div
      style={{
        position: 'absolute',
        bottom: 20,
        right: "20%",
        display: 'flex',
        zIndex: 10,
      }}
    >
      <div onClick={()=> {
        swiper.slidePrev()
      }} className="prev-btn-nivo c-flex-center" style={{marginRight: 20}}>
        <GrFormPrevious size={24} />
      </div>
      <div onClick={()=> swiper.slideNext()} className="next-btn-nivo c-flex-center">
        <GrFormNext size={24} />
      </div>
    </div>
  );
}

export const setting2 = {
  running: true,
  controlNavEl: false,
  directionNav: false,
  controlNav: false,
  controlNavThumbs: false,
  pauseOnHover: false,
  pauseTime: 6500,
  animSpeed: 2000,
};


const Banner = () => {
  const [activeSlideIndex, setActiveSlideIndex] = useState(0);
  const [activeSlideIndexDes, setActiveSlideIndexDes] = useState(0);
  const settings = {
    useTransform: true,
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 6500,
    fade: true,
    easing: "ease-out",
    pauseOnHover: false,
    afterChange: (index) => {
      setActiveSlideIndex(index) // Cập nhật index của slider đang active khi slider thay đổi
    },
  };

  return (
    <div className="wrap-home-banner">
      <div id="slider"></div>
      <div className="home-banner">
        <MediaQuery minWidth={625}>
          <Swiper {...setting2}>
            <Image src={BannerImage} alt="image1" />
            <Image src={BannerImage2} alt="image2" />
            <Image src={BannerImage3} alt="image3" />
            <Image src={BannerImage4} alt="image4" />
            <Image src={BannerImage5} alt="image5" />
            <Image src={BannerImage6} alt="image6" />
            <Image src={BannerImage7} alt="image7" />
            <CustomControl setActiveSlideIndexDes={setActiveSlideIndexDes} />
          </Swiper>
          <div style={{display: "none"}}>
            <Slider {...settings}>
              <div>
                <img src={BannerImage} alt="" className="home-image-banner" />
              </div>
              <div>
                <img src={BannerImage2} alt="" className="home-image-banner" />
              </div>
              <div>
                <img src={BannerImage3} alt="" className="home-image-banner" />
              </div>
              <div>
                <img src={BannerImage4} alt="" className="home-image-banner" />
              </div>
              <div>
                <img src={BannerImage5} alt="" className="home-image-banner" />
              </div>
              <div>
                <img src={BannerImage6} alt="" className="home-image-banner" />
              </div>
              <div>
                <img src={BannerImage7} alt="" className="home-image-banner" />
              </div>
            </Slider>
          </div>
        </MediaQuery>
        {/* mobile */}
        <MediaQuery maxWidth={625}>
          <Slider {...settings}>
            <div>
              <img src={BannerImageMb} alt="" className="home-image-banner" />
            </div>
            <div>
              <img src={BannerImageMb2} alt="" className="home-image-banner" />
            </div>
            <div>
              <img src={BannerImageMb3} alt="" className="home-image-banner" />
            </div>
          </Slider>
        </MediaQuery>
        {/* <div className="trapezoid-overlay"></div> */}
      </div>
      <MediaQuery minWidth={625}>
        <div className="home-title">
          {/* <div className="home-name-title1" style={{ opacity: 0 }}>
            Minh Khang
          </div>
          <div className="home-name-title2" style={{ opacity: 0 }}>
            Group
          </div> */}
          <NavigationBanner activeSlideIndex={activeSlideIndexDes} />
        </div>
      </MediaQuery>
      <MediaQuery maxWidth={625}>
        <NavigationBanner activeSlideIndex={activeSlideIndex} />
      </MediaQuery>
      {/* <MediaQuery minWidth={625}>
        <BookingMenu />
      </MediaQuery> */}
    </div>
  );
};

export default Banner;

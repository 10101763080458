// import moment from "moment";
import React from "react";
import { Link, useNavigate } from "react-router-dom";
import createSlug from "../../utils/createSlug";
// import RenderDangerHtml from "../RenderDangerHtml/RenderDangerHtml";

const ItemDealHot = (props) => {
  const navigate = useNavigate();
  const handleClick = () => {
    navigate(
      "/product/" +
        createSlug(props?.name)?.split(" ")?.join("-") +
        "-" +
        props?.id
    );
  };
  return (
    <Link
      to={
        "/product/" +
        createSlug(props?.name)?.split(" ")?.join("-") +
        "-" +
        props?.id
      }
    >
      <div
        style={{
          width: "100%",
          boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
          borderRadius: 10,
          overflow: "hidden",
          marginBottom: 12,
          cursor: "pointer",
        }}
        // onClick={handleClick}
      >
        <img
          loading={"lazy"}
          src={props?.photo}
          style={{ width: "100%", aspectRatio: 4 / 3 }}
          alt={props?.name}
          title={props?.name}
        />
        <div style={{ padding: 10 }}>
          <p
            style={{
              color: "#E27B07",
              fontSize: 14,
              fontWeight: 600,
              textTransform: "uppercase",
              marginBottom: 4,
            }}
            className="tr-2l h-42p"
          >
            {props?.name}
          </p>
          <p className="tr-3l" style={{ fontSize: 14, marginBottom: 8 }}>
            {props?.address?.length > 0 ? (
              <div>{props?.address}</div>
            ) : (
              "Chưa có mô tả"
            )}
          </p>
          <p
            style={{
              color: "#E27B07",
              fontSize: 15,
              fontWeight: 600,
              textTransform: "uppercase",
              marginBottom: 4,
            }}
            className="tr-2l"
          >
            {props?.price}
          </p>
        </div>
        {/* <p style={{ fontSize: 12, marginBottom: 8 }}>{moment(props?.time_created).format("DD-MM-YYYY")}</p> */}
      </div>
    </Link>
  );
};

export default ItemDealHot;

import { Skeleton } from '@mui/material'
import React from 'react'

const LoadingDealHot = () => {
  return (
    <div style={{ width: "100%" }}>
        <Skeleton variant="rounded" sx={{ bgcolor: "grey.400" }} height={500} width={"100%"} />
    </div>
  )
}

export default LoadingDealHot

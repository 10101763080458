import React, { useEffect } from "react";
import Intro1 from "../../../assets/intro/intro1.png";
// import Intro2 from "../../../assets/intro/intro2.png";
import Intro3 from "../../../assets/intro/intro3.png";
// import Logo1 from "../../../assets/intro/logo1.png";
import Logo2 from "../../../assets/intro/logo2.png";
import IntroGif from "../../../assets/intro/intro.gif";
import IntroVideo from "../../../assets/intro/intro.mp4";
import ScrollToTop from "../../../utils/ScrollToTop";
import Img1 from "../../../assets/intro/section1/img1.png";
import Img2 from "../../../assets/intro/section1/img2.png";
import Img21 from "../../../assets/intro/section1/img21.png";
import Img22 from "../../../assets/intro/section1/img22.png";
import Img23 from "../../../assets/intro/section1/img23.png";
import Img3 from "../../../assets/intro/section1/img3.png";
import Img4 from "../../../assets/intro/section1/img4.png";
// import MediaQuery from "react-responsive";
import { useNavigate } from "react-router-dom";
import { Image, Swiper } from "react-nivo-slider";
import { setting2 } from "../home/Banner";
import B1Image from "./image1/b1.png";
import B2Image from "./image1/b2.png";
import B3Image from "./image1/b3.png";

import A1Image from "./image1/a1.png";
import A2Image from "./image1/a2.png";
import A3Image from "./image1/a3.png";
import Section1 from "../../Intro/Section1";
import Section2 from "../../Intro/Section2";
import Section3 from "../../Intro/Section3";
import Section4 from "../../Intro/Section4";
// import Section5 from "../../Intro/Section5";
import Section6 from "../../Intro/Section6";
import { Helmet } from "react-helmet";

const Intro = () => {
  const navigate = useNavigate();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  
  const handleClick = () => {
    navigate("/linh-vuc-hoat-dong");
  };
  return (
    <div style={{ width: "100%" }}>
      <Helmet>
        <title>Về chúng tôi</title>
        <link rel="canonical" href={window.location.origin + window.location.pathname} />
        <meta
          data-react-helmet="true"
          property="og:url"
          content={window.location.origin + window.location.pathname}
        ></meta>
      </Helmet>
      <ScrollToTop />
      <div
        style={{ width: "100%", position: "relative" }}
        className="banner-intro c-flex-center"
      >
        <Swiper {...setting2}>
          <Image src={B1Image} alt="image1" />
          <Image src={B2Image} alt="image2" />
          <Image src={B3Image} alt="image3" />
        </Swiper>
        {/* <div
          style={{
            fontSize: 96,
            color: "#fff",
            textTransform: "uppercase",
            fontWeight: 700,
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            whiteSpace: "nowrap",
          }}
          className="m-fz-32"
        >
          Minh Khang Group
        </div> */}
      </div>
      {/*  */}
      <Section1
        IntroVideo={IntroVideo}
        Img4={Img4}
        handleClick={handleClick}
        mw={1140}
      />
      {/*  */}
      <Section2 Logo2={Logo2} Img2={Img2} Img1={Img1} mw={1140} />
      <Section3 Img21={Img21} mw={1140} />
      <Section4 Img3={Img3} mw={1140} />
      {/* <Section5 Img22={Img22} mw={1140} /> */}
      <Section6 Img23={Img23} mw={1140} />
    </div>
  );
};

export default Intro;

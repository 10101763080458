import * as React from "react";
// import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { Box } from "@mui/system";
import { FormControl, InputLabel } from "@mui/material";

export default function DatePickerValue(props) {
  return (
    <Box
      className={props?.className}
      sx={{ width: props?.w ? props?.w : "60%" }}
    >
      <FormControl fullWidth>
        {props?.disableTitle !== true && (
          <label style={{ marginBottom: 8, color: "#898989", fontWeight: 500 }}>
            {props?.label}
          </label>
        )}
        <InputLabel id="demo-simple-select-label">{}</InputLabel>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DatePicker
            DialogProps={{
              style: { zIndex: 1325 }, // it should be more than 1200
            }}
            slotProps={{
              textField: { size: "small" },
              popper: { sx: { zIndex: 10000 } },
            }}
            label={props?.label}
            value={props?.value}
            onChange={(newValue) => props?.setValue(newValue)}
            format="DD/MM/YYYY"
            disablePast={true}
            sx={{
              "& .MuiOutlinedInput-notchedOutline": {
                borderColor: "#e27b07",
              },
              "& .MuiSvgIcon-root": {
                color: "#e27b07",
              },
            }}
          />
        </LocalizationProvider>
      </FormControl>
    </Box>
  );
}

import * as React from 'react';
// import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
// import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { TextField } from '@mui/material';
import { apiSubmitContact } from '../../api';
import swal from 'sweetalert';
import { useNavigate } from 'react-router-dom';

export default function AdvisePopupTrigger(props) {

  const navigate= useNavigate()
  const [phone, setPhone]= React.useState("")
  const [name, setName]= React.useState("")


  const handleClose = () => {
    props?.setOpen(false);
    navigate(-1)
  };

  const handleClick= async ()=> {
    if(name?.length <= 0 || phone?.length <= 0) {
      swal("Thông báo", "Bạn hãy điền đầy đủ thông tin")
      return
    }
    const result = await apiSubmitContact({
      name,
      date_send: new Date(),
      status: "waiting for reply",
      phone,
    });
    if (result.ok === true) {
      swal("Thông báo", "Đã gửi thành công", "success").then(() => {
        setName("");
        setPhone("")
      }).then(()=> handleClose())
    } else {
      swal("Thông báo", "Gửi thất bại", "error");
    }
  }

  return (
    <React.Fragment>
      <Dialog
        open={props?.open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth={1000}
      >
        <DialogTitle style={{fontSize: 40, color: "#E27B07", fontWeight: 700}} id="alert-dialog-title">
          {"Liên hệ"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <div style={{width: 600}}>
                <div style={{width: "100%", display: "flex", gap: 30}}>
                    
                    <div style={{flex: 1}}>
                        <TextField value={name} onChange={(e)=> setName(e.target.value)} fullWidth placeholder="Họ và tên" />
                    </div>
                    <div style={{flex: 1}}>
                        <TextField value={phone} onChange={(e)=> setPhone(e.target.value)} fullWidth placeholder="Số điện thoại" />
                    </div>
                </div>
                <div>
                    <div onClick={handleClick} style={{width: "max-content", cursor: "pointer", padding: "6px 20px", borderRadius: 10, color: "#fff", fontSize: 24, fontWeight: 700, backgroundColor: "#D03601", marginTop: 40}}>
                        Tư vấn
                    </div>
                </div>
            </div>
          </DialogContentText>
        </DialogContent>
        
      </Dialog>
    </React.Fragment>
  );
}
import { TextField } from "@mui/material";
import React, { useState } from "react";
import { apiSubmitContact } from "../../../api";
import swal from "sweetalert";
import MediaQuery from "react-responsive";

const Contact = () => {
  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [content, setContent] = useState("");

  const handleClick = async () => {
    if(name?.length <= 0 || email?.length <= 0 || phone?.length <= 0 || content?.length <= 0) {
      swal("Thông báo", "Bạn hãy điền đầy đủ thông tin")
      return
    }
    const result = await apiSubmitContact({
      email,
      name,
      content,
      date_send: new Date(),
      status: "waiting for reply",
      phone,
    });
    if (result.ok === true) {
      swal("Thông báo", "Đã gửi thành công", "success").then(() => {
        setEmail("");
        setName("");
        setContent("");
        setPhone("")
      });
    } else {
      swal("Thông báo", "Gửi thất bại", "error");
    }
  };

  return (
    <div style={{ width: "100%" }} className="c-flex-center">
      <div style={{ maxWidth: 946, width: "100%" }}>
        <MediaQuery minWidth={625}>
          <div
            className="text-gra"
            style={{
              fontSize: 40,
              textTransform: "uppercase",
              fontWeight: 700,
              margin: "24px 0",
            }}
          >
            Liên hệ
          </div>
        </MediaQuery>
        <MediaQuery maxWidth={625}>
          <div
            style={{
              fontSize: 20,
              textTransform: "uppercase",
              fontWeight: 700,
              margin: "24px 0",
              textAlign: "center"
            }}
            className="text-trad"
          >
            Liên hệ
          </div>
        </MediaQuery>
        <MediaQuery minWidth={625}>
          <div style={{ width: "100%", marginBottom: 24 }}>
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <div style={{ width: "45%" }}>
                <TextField
                  sx={{
                    '& .MuiOutlinedInput-notchedOutline': {
                        borderColor: '#e27b07'
                    },
                    '& .MuiSvgIcon-root': {
                        color: '#e27b07'
                    }
                  }}
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  fullWidth
                  size={"small"}
                  placeholder={"Họ và tên"}
                  style={{borderColor: "#D03601"}}
                />
              </div>
              <div style={{ width: "45%" }}>
                <TextField
                sx={{
                    '& .MuiOutlinedInput-notchedOutline': {
                        borderColor: '#e27b07'
                    },
                    '& .MuiSvgIcon-root': {
                        color: '#e27b07'
                    }
                  }}
                  value={phone}
                  onChange={(e) => setPhone(e.target.value)}
                  fullWidth
                  size={"small"}
                  placeholder={"Số điện thoại"}
                />
              </div>
            </div>
          </div>
          <div style={{ width: "100%", marginBottom: 24 }}>
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <div style={{ width: "45%" }}>
                <TextField
                sx={{
                    '& .MuiOutlinedInput-notchedOutline': {
                        borderColor: '#e27b07'
                    },
                    '& .MuiSvgIcon-root': {
                        color: '#e27b07'
                    }
                  }}
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  fullWidth
                  size={"small"}
                  placeholder={"Email"}
                />
              </div>
              <div style={{ width: "45%" }}>
                <TextField
                sx={{
                    '& .MuiOutlinedInput-notchedOutline': {
                        borderColor: '#e27b07'
                    },
                    '& .MuiSvgIcon-root': {
                        color: '#e27b07'
                    }
                  }}
                  // value={content}
                  // onChange={(e) => setContent(e.target.value)}
                  fullWidth
                  size={"small"}
                  placeholder={"Chọn nhu cầu"}
                />
              </div>
            </div>
          </div>
          <div style={{ width: "100%", marginBottom: 24 }}>
            <div style={{ width: "100%" }}>
              <textarea
                value={content}
                onChange={(e) => setContent(e.target.value)}
                style={{
                  width: "100%",
                  resize: "none",
                  padding: 10,
                  fontSize: 16,
                  fontFamily: "sans-serif",
                  borderRadius: 10,
                  border: "1px solid #e27b07",
                }}
                cols={40}
                rows={6}
                placeholder="Ghi chú"
              ></textarea>
            </div>
          </div>
        </MediaQuery>
        {/*  */}
        <MediaQuery maxWidth={625}>
          <div style={{width: "100%", padding: 10}}>
            <div style={{ width: "100%", marginBottom: 24 }}>
              <div
                style={{
                  width: "100%",
                }}
              >
                <div style={{ width: "100%", marginBottom: 24 }}>
                  <TextField
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    fullWidth
                    size={"small"}
                    placeholder={"Họ và tên"}
                    sx={{
                          "& .MuiOutlinedInput-root": {
                            "& fieldset": {
                              borderColor: "#000", // Thay đổi màu viền ở đây
                            },
                          },
                        }}
                  />
                </div>
                <div style={{ width: "100%" }}>
                  <TextField
                    value={phone}
                    onChange={(e) => setPhone(e.target.value)}
                    fullWidth
                    size={"small"}
                    placeholder={"Số điện thoại"}
                    sx={{
                          "& .MuiOutlinedInput-root": {
                            "& fieldset": {
                              borderColor: "#000", // Thay đổi màu viền ở đây
                            },
                          },
                        }}
                  />
                </div>
              </div>
            </div>
            <div style={{ width: "100%", marginBottom: 24 }}>
              <div
                style={{
                  width: "100%",
                }}
              >
                <div style={{ width: "100%", marginBottom: 24 }}>
                  <TextField
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    fullWidth
                    size={"small"}
                    placeholder={"Email"}
                    sx={{
                          "& .MuiOutlinedInput-root": {
                            "& fieldset": {
                              borderColor: "#000", // Thay đổi màu viền ở đây
                            },
                          },
                        }}
                  />
                </div>
                <div style={{ width: "100%" }}>
                  <TextField
                    // value={content}
                    // onChange={(e) => setContent(e.target.value)}
                    fullWidth
                    size={"small"}
                    placeholder={"Chọn nhu cầu"}
                    sx={{
                          "& .MuiOutlinedInput-root": {
                            "& fieldset": {
                              borderColor: "#000", // Thay đổi màu viền ở đây
                            },
                          },
                        }}
                  />
                </div>
              </div>
            </div>
            <div style={{ width: "100%", marginBottom: 24 }}>
              <div style={{ width: "100%" }}>
                <textarea
                  value={content}
                  onChange={(e) => setContent(e.target.value)}
                  style={{
                    width: "100%",
                    resize: "none",
                    padding: 10,
                    fontSize: 16,
                    fontFamily: "sans-serif",
                    borderRadius: 10,
                    border: "1px solid #000",
                  }}
                  cols={40}
                  rows={6}
                  placeholder="Ghi chú"
                ></textarea>
              </div>
            </div>  
          </div>
        </MediaQuery>
        <div style={{ width: "100%", marginBottom: 24 }}>
          <div style={{ width: "100%" }} className="c-flex-center">
            <div
              className="c-flex-center"
              style={{
                padding: "20px 40px",
                borderRadius: 10,
                cursor: "pointer",
                background: "linear-gradient(180deg, #E27B07 0%, #D03601 100%)",
                color: "#fff",
                fontWeight: 600,
              }}
              onClick={handleClick}
            >
              Gửi
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contact;

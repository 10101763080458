import React from "react";
// import ItemImage from "../../../assets/apartment/item.png";
import { Link } from "react-router-dom";
import CarIcon from "../../assets/ticket/car.png"
import TicketIcon from "../../assets/ticket/ticket.png"
import BuffeIcon from "../../assets/ticket/buffe.png"
import numberWithCommas from "../../utils/numberWithComma";
import createSlug from "../../utils/createSlug";

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false, error: null };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true, error };
  }

  componentDidCatch(error, errorInfo) {
    console.error("Error boundary caught an error:", error, errorInfo);
    // Thực hiện xử lý lỗi tại đây, ví dụ gửi lỗi lên server, log lỗi, ...
  }

  render() {
    if (this.state.hasError) {
      return <h1>Something went wrong. Please try again later.</h1>;
    }

    return this.props.children; 
  }
}


const ItemTicket = (props) => {

  const handleClick = () => {
    // navigate("/ticket/detail/"+ createSlug(props?.name)?.split(" ")?.join("-") + "-"  + props?.id);
  };

  return (
    <ErrorBoundary>
      <Link to={"/ticket/detail/"+ createSlug(props?.name)?.split(" ")?.join("-") + "-"  + props?.id} style={{ width: "100%", height: "100%" }}>
        <div
          style={{
            width: "100%",
            boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
            borderRadius: 10,
            cursor: "pointer",
            background: "#fff",
            overflow: "hidden", height: "100%" 
          }}
          onClick={handleClick}
        >
          <div style={{ width: "100%", overflow: "hidden", marginBottom: 4 }}>
            <img
              src={props?.photo}
              alt=""
              style={{ width: "100%", aspectRatio: 4 / 3, objectFit: "cover" }}
            />
          </div>
          <div style={{ padding: 10, width: "100%" }}>
            <div
              style={{
                fontSize: 15,
                fontWeight: 600,
                height: 40,
                marginBottom: 4,
              }}
              className="tr-2l"
            >
              {props?.name || "Unknown"}
            </div>
            <div
              title={`${numberWithCommas(props?.price)} VND`}
              className="tr-11l"
              style={{ color: "#D03601", fontWeight: 600 }}
            >
              {numberWithCommas(Math.round(
                props?.price * ((100 - parseInt(props?.discountPer)) / 100)
              ))}{" "}
              VND&nbsp;
              {parseInt(props?.discountPer) > 0 && (
                <del style={{ color: "#625E5D" }}>{numberWithCommas(props?.price)} VND</del>
              )}
            </div>
            <div className="d-flex" style={{display: "flex", alignItems: "center"}}>
              <img src={CarIcon} alt={""} />
              <p style={{fontSize: 12, color: "#534D4B", marginLeft: 8}}>Xe đưa đón: {props?.car}</p>
            </div>
            <div className="d-flex" style={{display: "flex", alignItems: "center"}}>
              <img src={TicketIcon} alt={""} />
              <p style={{fontSize: 12, color: "#534D4B", marginLeft: 8}}>Vé trẻ em: {numberWithCommas(props?.children_price)}</p>
            </div>
            <div className="d-flex" style={{display: "flex", alignItems: "center"}}>
              <img src={BuffeIcon} alt={""} />
              <p style={{fontSize: 12, color: "#534D4B", marginLeft: 8}}>Buffe: {props?.buffe}</p>
            </div>
          </div>
        </div>
      </Link>
    </ErrorBoundary>
  );
};

export default ItemTicket;
